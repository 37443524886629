import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import LockIcon from "@mui/icons-material/LockOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "@/common/images/logo-horizontal.svg";
import {
  siteMinWidth,
} from "@/style/vars.module.scss";
import { CurrentUser } from "@/common/classes/UserModel";

function Mobile({
  currentUser,
  includingPrimaryMenu,
  togglePrimaryMenuExpandedAtMobileRes,
}: {
  currentUser: CurrentUser,
  includingPrimaryMenu?: boolean,
  togglePrimaryMenuExpandedAtMobileRes: Function,
}) {

  return (
    <>
      <Box className="only-print" sx={{ textAlign: "center" }}>
        <img alt="Healthier Generation" style={{ width: "180px" }} src={logo} />
      </Box>
      <Box
        className="no-print"
        sx={{
          left: 0,
          minWidth: siteMinWidth,
          position: {
            xs: "absolute",
            md: "fixed",
          },
          top: 0,
          width: "100%",
          zIndex: "appBar",
        }}
      >
        <AppBar
          position="sticky"
          color="inherit"
          role="banner"
          sx={{
            backgroundColor: "color.white",
            borderTop: "4px solid #E13F00",
            minWidth: siteMinWidth,
            padding: "2px 0 0 0",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              minHeight: headerHeightXs,
            }}
            variant="dense"
          >
            {includingPrimaryMenu && (
              <>
                {/* @ts-ignore */}
                <IconButton
                  id="mobile-header-expand-menu-button"
                  onClick={togglePrimaryMenuExpandedAtMobileRes}
                  color="primary"
                  aria-label="Menu"
                  size="large"
                >
                  <MenuIcon sx={{ fontSize: "2.2rem" }} />
                </IconButton>
              </>
            )}

            <Box
              id="mobile-header-home-link"
              component="a"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                lineHeight: 0,
                margin: "0 auto",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Box
                id="mobile-header-logo"
                component="img"
                alt="Healthier Generation"
                src={logo}
                sx={{
                  marginTop: "2px",
                  maxWidth: "90%",
                  width: "238px",
                }}
              />
            </Box>

            {includingPrimaryMenu && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    height: headerHeightXs,
                    marginLeft: "auto",
                  }}
                >
                  {!currentUser.isAuthenticated && !currentUser.loading ? (
                    <>
                      {/*  MOBILE LOGIN BUTTON */}
                      <IconButton
                        id="mobile-header-login-link"
                        aria-label="Log in"
                        color="primary"
                        component={Link}
                        to="/app/account/login"
                        size="large"
                        sx={{
                          marginLeft: "auto",
                        }}
                      >
                        <LockIcon sx={{ fontSize: "2rem" }} />
                      </IconButton>
                    </>
                  ) : (
                    !currentUser.loading && (
                      <>
                        {/*  MOBILE PROFILE BUTTON */}
                        <IconButton
                          id="mobile-header-account-link"
                          aria-label="My Account"
                          color="primary"
                          component={Link}
                          to="/app/account/profile"
                          size="large"
                          sx={{
                            marginLeft: "auto",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{
                              color: "primary.main",
                              fontSize: "2.3rem",
                            }}
                          />
                        </IconButton>
                      </>
                    )
                  )}
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

const headerHeightXs = "48px";

Mobile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  includingPrimaryMenu: PropTypes.bool,
  togglePrimaryMenuExpandedAtMobileRes: PropTypes.func.isRequired,
  userFirstName: PropTypes.string.isRequired,
};

export default Mobile;

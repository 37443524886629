import { makeRequest } from "@/common/classes/ApiUtils";
import { currentWebsocketId } from "@/common/classes/ApiUtils";
import { Module } from "../classes/ModuleModel";
import { Set } from "../classes/SetModel";
import { CriterionInstance } from "@/features/assessments/classes/CriterionInstanceModel";
import { isNil } from "lodash";

/**
 * Get first unanswered CI in an assessment (that is visible to the current user).
 *
 * @param   {number}  assessmentId
 * @param   {number}  organizationId
 * @return  {Promise}
 */
export const requestFirstUnansweredQuestion = (assessmentId: number, organizationId: number) =>
  makeRequest({
    // @see #5095
    url: `/api/v1/organizations/${organizationId}/criterion-instances`,
    body: {
      set_id: assessmentId,
      per_page: 1,
      weight_sort: "asc",
    },
    method: "GET",
  });

/**
 * Clone a set.
 *
 * Accepts typical payload to declare specific values.
 *
 * @param   {number}  setId
 * @param   {Object}  newVals
 * @return  {Promise}
 */
export const requestCloneSet = (setId: number, newVals: object) => {
  return makeRequest({
    url: `/api/v1/sets/${setId}/clone`,
    body: newVals,
    method: "POST",
  });
};

/**
 * Create a CriterionInstance record.
 *
 * @param   {Object}  criterionInstance
 * @return  {Promise}
 */
export const requestCreateCriterionInstance = (criterionInstance: CriterionInstance) => {
  return makeRequest({
    url: `/api/v1/criterion-instances`,
    body: criterionInstance,
    method: "POST",
  });
};

/**
 * Create an Organization Response to a question (CriterionInstance)
 *
 * @param   {Object}  payload
 * @return  {Promise}
 */
export const requestCreateResponse = (payload: any) => {
  // Add socketId to payload if websocket is available.
  payload.socket_id = currentWebsocketId() || null;
  return makeRequest({
    url: `/api/v1/responses`,
    body: payload,
    method: "POST",
  });
};

/**
 * Create a new Module record in a Set.
 *
 * @param   {Object}  moduleObj
 * @return  {Promise}
 */
export const requestCreateModule = (moduleObj: Module) => {
  return makeRequest({
    url: `/api/v1/modules`,
    body: moduleObj,
    method: "POST",
  });
};

/**
 * Create a new Set (assessment) record in a Program.
 *
 * @param  {Object} set
 * @return {Promise}
 */
export const requestCreateSet = (set: Set) => {
  return handleSetUpdateCreate(set)
};

/**
 * Behind the scenes method to handle shared logic for set
 * creation and updates.
 * 
 * If a logo file is to be included in the payload we use FormData
 * but otherwise the simple set object is sufficient, and creates
 * less unexpected behavior in the backend like null coming across
 * as a string "null" in FormData.
 * 
 * @param {Object} set 
 * @param {number} setId 
 * @returns 
 */
const handleSetUpdateCreate = (set: Set, setId?: number) => {
  let requestUrl;
  if (setId) {
    requestUrl = `/api/v1/sets/${setId}`;
  } else {
    requestUrl = `/api/v1/sets`;
  }

  if (set.logo) {
    let formData = new FormData();
    Object.keys(set).forEach(key => {
      if(key === "core_module_id" && isNil(set[key])) {
        // Do not include core_module_id if it's null
        // FormData makes everything, including null, a string
        // and the API does not like that for core_module_id
      } else {
        formData.append(key, set[key])
      }
    });
    return makeRequest({
      url: requestUrl,
      body: formData,
      /* Although this is an update api call we have to use POST instead 
      of PUT. Laravel has an oddity with multipart/form-data and this 
      file upload along with other data doesn't work as a PUT request */
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      contentType: "multipart/form-data",
    });
  } else {
    return makeRequest({
      url: requestUrl,
      body: set,
      method: "POST",
    });
  }
}

/**
 * Update a CriterionInstance record.
 *
 * @param   {number}  criterionInstanceId
 * @param   {Object}  criterionInstance
 * @return  {Promise}
 */
export const requestUpdateCriterionInstance = (criterionInstanceId: number, criterionInstance: CriterionInstance) => {
  return makeRequest({
    url: `/api/v1/criterion-instances/${criterionInstanceId}`,
    body: criterionInstance,
    method: "PUT",
  });
};

/**
 * Update a Module record.
 *
 * @param   {number}  moduleId
 * @param   {Object}  moduleObj
 * @return  {Promise}
 */
export const requestUpdateModule = (moduleId: number, moduleObj: Module) => {
  return makeRequest({
    url: `/api/v1/modules/${moduleId}`,
    body: moduleObj,
    method: "PUT",
  });
};

/**
 * Update a Set record.
 * 
 * @param   {number}  setId
 * @return  {Promise}
 */
export const requestUpdateSet = (setId: number, set: Set) => {
  return handleSetUpdateCreate(set, setId);
};

/**
 * Delete a CriterionInstance record.
 *
 * @param   {number}  criterionInstanceId
 * @return  {Promise}
 */
export const requestDeleteCriterionInstance = (criterionInstanceId: number) => {
  return makeRequest({
    url: `/api/v1/criterion-instances/${criterionInstanceId}`,
    method: "DELETE",
  });
};

/**
 * Delete a Module record.
 *
 * @param   {number}  moduleId
 * @return  {Promise}
 */
export const requestDeleteModule = (moduleId: number) => {
  return makeRequest({
    url: `/api/v1/modules/${moduleId}`,
    method: "DELETE",
  });
};

/**
 * Delete a Set record.
 *
 * @param   {number}  setId
 * @return  {Promise}
 */
export const requestDeleteSet = (setId: number) => {
  return makeRequest({
    url: `/api/v1/sets/${setId}`,
    method: "DELETE",
  });
};

/**
 * Get a single CriterionInstance record.
 *
 * @param   {number}  criterionInstanceId
 * @return  {Promise}                       [return description]
 */
export const requestCriterionInstance = (criterionInstanceId: number) =>
  makeRequest({
    url: `/api/v1/criterion-instances/${criterionInstanceId}`,
    method: "GET",
  });

/**
 * Get multiple CriterionInstance records.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestCriterionInstances = (params: object) =>
  makeRequest({
    url: `/api/v1/criterion-instances`,
    body: params,
    method: "GET",
  });

/**
 * Get multiple Resources records associated with a Module.
 *
 * @param   {number}  moduleId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestModuleResources = (moduleId: number, params: object) =>
  makeRequest({
    url: `/api/v1/modules/${moduleId}/resources`,
    body: params,
    method: "GET",
  });

/**
 * Get multiple Module records
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestModules = (params: object) =>
  makeRequest({
    url: `/api/v1/modules`,
    body: params,
    method: "GET",
  });

/**
 * Get multiple CriterionInstances that belong to a Module.
 *
 * @param   {number}  moduleId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestModuleCriterionInstances = (moduleId: number, params: object) =>
  makeRequest({
    url: `/api/v1/modules/${moduleId}/criterion-instances`,
    body: params,
    method: "GET",
  });

/**
 * Get userFunctions associated with criteria in a given Module.
 * @see #5111
 *
 * @param   {number}  moduleId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestModuleUserFunctions = (moduleId: number, params: object) =>
  makeRequest({
    url: `/api/v1/modules/${moduleId}/user-functions`,
    body: params,
    method: "GET",
  });

/**
 * Get Responses for an Organization.
 *
 * Note: Only the one most recent Response is returned per Criterion. We
 * don't update or delete Response records; each time a question is answered
 * we create a new Response record.
 *
 * @param   {number}  organizationId
 * @param   {Object}  params
 *  Notable params:
 *  - program_id (filter by program)
 *  - set_id (filter by set)
 *  - criterion_id (filter by criterion)
 *  - user_id (filter by responder)
 *  - created_at_sort
 * @return  {Promise}
 */
export const requestOrganizationResponses = (organizationId: number, params: object) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/responses`,
    body: params,
    method: "GET",
  });

/**
 * Get multiple Sets for an Organization, along with other
 *
 * Used for organization_sets redux.
 *
 * @param   {number}  organizationId  [organizationId description]
 * @param   {Object}  params          [params description]
 *  Notable params:
 *  - set_id to filter by set
 *  - program_id to filter by program
 * @return  {Promise}                  [return description]
 */
export const requestOrgSetsData = (organizationId: number, params: object) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/sets`,
    body: params,
    method: "GET",
  });

/**
 * Get a single Set visible to current user.
 *
 * @param   {number}  setId
 * @return  {Promise}
 */
export const requestSet = (setId: number) =>
  makeRequest({
    url: `/api/v1/sets/${setId}`,
    method: "GET",
  });

/**
 * Get multiple Set records visible to current user.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestSets = (params: object) =>
  makeRequest({
    url: `/api/v1/sets`,
    body: params,
    method: "GET",
  });

/**
 * Get multiple Module records in a Set visible to current user.
 *
 * @param   {number}  setId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestSetModules = (setId: number, params: object) =>
  makeRequest({
    url: `/api/v1/sets/${setId}/modules`,
    body: params,
    method: "GET",
  });

/**
 * Get a Module record.
 *
 * @param   {number}  moduleId
 * @return  {Promise}
 */
export const requestModule = (moduleId: number) => {
  return makeRequest({
    url: `/api/v1/modules/${moduleId}`,
    method: "GET",
  });
};

/**
 * Get multiple Response records for a Set.
 *
 * Only the single most recent Response for a given Organization/Criterion will
 * be reflected in the results. In practice, this endpoint will be callsed using
 * various URL parameters to filter the results.
 *
 * @param   {number}  setId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestSetResponses = (setId: number, params: object) =>
  makeRequest({
    url: `/api/v1/sets/${setId}/responses`,
    body: params,
    method: "GET",
  });

/**
 * Get CriterionInstances in a Set visible to the current user.
 *
 * @param   {number}  setId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestSetCriterionInstances = (setId: number, params: object) =>
  makeRequest({
    url: `/api/v1/sets/${setId}/criterion-instances`,
    body: params,
    method: "GET",
  });

/**
 * Get Organizations eligible for a Set.
 *
 * NOTE! If the set is restricted (the typical use case), the
 * results will be the orgs assigned to it in `organization_set`.
 * If the set is NOT restricted, the results will be all orgs
 * that are theoretically eligible (based on org type etc).
 *
 * @param   {number}  setId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestSetOrganizations = (setId: number, params: object) => {
  return makeRequest({
    url: `/api/v1/sets/${setId}/organizations`,
    body: params,
    method: "GET",
  });
};

/**
 * Associate an Organization with a Set
 *
 * @param   {number}  setId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestLinkOrganizationSet = (setId: number, params: object) => {
  return makeRequest({
    url: `/api/v1/sets/${setId}/organizations`,
    body: params,
    method: "POST",
  });
};

/**
 * Disassociate an Organization from a Set
 *
 * @param   {number}  setId
 * @param   {number}  organizationId
 * @return  {Promise}
 */
export const requestUnlinkOrganizationSet = (setId: number, organizationId: number) => {
  return makeRequest({
    url: `/api/v1/sets/${setId}/organizations/${organizationId}`,
    method: "DELETE",
  });
};

/**
 * Get Sets in a Program that are visible to the current user.
 *
 * @param   {number}  programId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestProgramSets = (programId: number, params: object) =>
  makeRequest({
    url: `/api/v1/programs/${programId}/sets`,
    body: params,
    method: "GET",
  });

import HgSkeleton from "@/common/components/ui/atoms/HgSkeleton";
import { Box } from "@mui/material";

//
// Generic skeleton screen.
//
// This is intended for places where it's not
// worth defining a custom skeleton arrangement
// for whatever reason.
//
function HgSkeletonPage() {
  return (
    <>
      <Box mb={2}>
        {/* breadcrumb skeleton */}
        <HgSkeleton variant="text" height={"1em"} width={`100%`} />
      </Box>
      <Box mb={2}>
        {/* h1 skeleton */}
        <HgSkeleton variant="text" height={"3em"} width={`90%`} />
      </Box>
      <Box mb={2}>
        {/* page body skeleton */}
        <HgSkeleton variant="text" height={"1em"} width={`100%`} />
        <HgSkeleton variant="text" height={"1em"} width={`100%`} />
        <HgSkeleton variant="text" height={"1em"} width={`100%`} />
        <HgSkeleton variant="text" height={"1em"} width={`50%`} />
      </Box>
    </>
  );
}

export default HgSkeletonPage;

import { useEffect, useState } from "react";
import { isArray, isEmpty, get, remove, values } from "lodash";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AssessmentOutlined from "@mui/icons-material/AssessmentOutlined";
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import Assignment from "@mui/icons-material/AssignmentOutlined";
import ExpandMore from "@mui/icons-material/ArrowRightOutlined";
import ExpandLess from "@mui/icons-material/ArrowDropDownOutlined";
import SupervisorAccount from "@mui/icons-material/SupervisorAccountOutlined";
import AssignmentTurnedIn from "@mui/icons-material/AssignmentTurnedInOutlined";
import SubmenuAuthOrganizationSets from "@/common/components/layout/PrimaryMenu/components/SubmenuAuthOrganizationSets";
import {
  styleLink,
  styleLinkActive,
  sxList,
  sxListItem,
  sxListItemText,
  sxListItemIcon,
} from "@/common/components/layout/PrimaryMenu/_common";
import { docbuildersForOrganization, menuItemIcon } from "@/features/docbuilders/classes/DocbuilderUtils";
import { orgTypeIsDistrictLike } from "@/common/classes/OrganizationTypeUtils";
import { sortSets } from "@/features/assessments/classes/SetUtils";
import { Organization } from "@/common/classes/OrganizationModel";
import { Set } from "@/features/assessments/classes/SetModel";
import { Docbuilder } from "@/features/docbuilders/classes/DocbuilderModel";
import { Store } from "@/store/Store";
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

//
// Primary menu: Organization-specific submenu used by AuthSubmenu.
//
function SubmenuAuthOrganization({
  activeOrg,
  activeSetId,
  showingOrgItems,
  showingOrgSets,
  toggleShowingOrgItems,
  toggleShowingOrgSets,
  userCanViewActionPlan,
  userCanViewAssessments,
  userCanViewDocbuilders,
  userCanViewPrioritizationCalculator,
  userCanViewTeam,
}: {
  activeOrg: Organization,
  activeSetId?: number,
  showingOrgItems?: boolean,
  showingOrgSets?: boolean,
  toggleShowingOrgItems: Function,
  toggleShowingOrgSets: Function,
  userCanViewActionPlan?: boolean,
  userCanViewAssessments?: boolean,
  userCanViewDocbuilders?: boolean,
  userCanViewPrioritizationCalculator?: boolean,
  userCanViewTeam?: boolean,
}) {
  const organizationSets = useSelector((state: Store) => get(state.organization_sets, `data.${activeOrg.id}`, null));
  const [organizationSetsArr, setOrganizationSetsArr] = useState<Set[]|null>(null);

  const [docbuildersForOrg, setDocbuildersForOrg] = useState<Docbuilder[]>([]);
  const [activeOrgIsDistrictLike, setActiveOrgIsDistrictLike] = useState<boolean>(false);
  const allDocbuilders = useSelector((state: Store) => state.app_meta.data.docbuilders);
  const activeOrgType = useSelector((state: Store) => state.app_meta.data.organizationTypes[activeOrg.organization_type_id!]);

  const [showingAhsItem, setShowingAhsItem] = useState(false);

  //
  // Populate org sets array
  //
  useEffect(() => {
    if (organizationSets) {
      let tmp: {
        failed: boolean,
        loading: boolean,
        set: Set|null,
      }[] = values(organizationSets);
      let newOrganizationSetsArr = [];
      for (let i = 0; i < tmp.length; i++) {
        if (!tmp[i].loading) {
          let s = get(tmp[i], "set", null);
          if (!isEmpty(s)) {
            newOrganizationSetsArr.push(s);
          }
        }
      }
      newOrganizationSetsArr = sortSets(newOrganizationSetsArr);
      setOrganizationSetsArr(newOrganizationSetsArr);
    }
  }, [organizationSets]);

  useEffect(() => {
    // Whether org is district-like
    let dl = orgTypeIsDistrictLike(activeOrgType);
    setActiveOrgIsDistrictLike(dl);

    // Whether to show AHS item
    let sai = dl || (activeOrgType && "school" === activeOrgType.machine_name);
    setShowingAhsItem(sai);
  }, [activeOrg, activeOrgType]);

  //
  // Populate docbuilders when props change.
  //
  useEffect(() => {
    let newDocbuildersForOrg: Docbuilder[] = [];
    if (activeOrg) {
      // Collect docbuilders available to active org.
      if (allDocbuilders) {
        newDocbuildersForOrg = docbuildersForOrganization(
          allDocbuilders,
          activeOrg,
          false // false to exclude closed docbuilders
        );

        // ------------------------
        // TEMP! @TODO
        // Below removes "recognition_2025" if present
        // @see Docbuilders.jsx where we do the same
        if (isArray(newDocbuildersForOrg)) {
          remove(newDocbuildersForOrg, (v) => {
            return v.machine_name === "recognition_2025";
          });
        }
        // ------------------------
      }
    }
    setDocbuildersForOrg(newDocbuildersForOrg);
  }, [activeOrg, allDocbuilders, userCanViewAssessments, userCanViewDocbuilders]);

  return (
    <List
      id="submenu-auth-organization"
      sx={{...sxList, pb: 2,}}
    >
      <ListItem sx={sxListItem}>
        <Box sx={{alignItems: "flex-start", display:"flex", mb: 1,}}>
          {/* Only show expansion if user has access to one or more links inside. */}
          {(userCanViewAssessments || userCanViewTeam) && (
            <IconButton onClick={() => toggleShowingOrgItems(!showingOrgItems)} sx={{alignSelf:"center"}} aria-label="expand">
              {showingOrgItems ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          )}
          <NavLink
            exact
            id="submenu-auth-organization-link"
            to={`/app/organizations/${activeOrg.id}`}
            style={(isActive) => (isActive ? styleLinkActive : styleLink )}
          >
            <ListItemText sx={{ ...sxListItemText }} primary={activeOrg.name} />
          </NavLink>
        </Box>

        <Collapse in={showingOrgItems} timeout="auto" unmountOnExit>
          <List sx={{...sxList, pb: 1, borderBottom: "none"}}>
            {organizationSetsArr && userCanViewAssessments && (
              <>

                <ListItem sx={{...sxListItem, pl: 0}}>
                  <Box sx={{alignItems: "flex-start", display: "flex", mb: 1.5}}>
                    <IconButton
                      aria-label="expand"
                      onClick={() => toggleShowingOrgSets(!showingOrgSets)}
                      sx={{alignSelf:"center"}}
                    >
                      {showingOrgSets ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                    <NavLink
                      exact
                      id="submenu-auth-organization-sets-link"
                      to={`/app/organizations/${activeOrg.id}/sets`}
                      style={(isActive) => (isActive ? styleLinkActive : styleLink )}
                    >
                      <ListItemIcon sx={{ ...sxListItemIcon }}>
                        <Assignment color="primary" />
                      </ListItemIcon>
                      <ListItemText sx={{ ...sxListItemText }} primary="Assessments" />
                    </NavLink>
                  </Box>

                  <Collapse in={showingOrgSets} timeout="auto" unmountOnExit>
                    <SubmenuAuthOrganizationSets
                      activeOrgId={activeOrg.id!}
                      activeSetId={activeSetId}
                      organizationSetsArr={organizationSetsArr}
                      userCanViewPrioritizationCalculator={userCanViewPrioritizationCalculator}
                    />
                  </Collapse>
                </ListItem>
              </>
            )}

            {userCanViewActionPlan && (
              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  id="submenu-auth-organization-plan-link"
                  to={`/app/organizations/${activeOrg.id}/plan`}
                  style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <AssignmentTurnedIn color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{ ...sxListItemText }} primary="Action Plan" />
                </NavLink>
              </ListItem>
            )}

            {userCanViewAssessments && (
              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  id="submenu-auth-organization-reports-link"
                  to={`/app/organizations/${activeOrg.id}/reports`}
                  style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <AssessmentOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{ ...sxListItemText }} primary="Reports" />
                </NavLink>
              </ListItem>
            )}

            {showingAhsItem && userCanViewAssessments && (
              <ListItem sx={{...sxListItem}}>
                <Box sx={{alignItems: "flex-start", display:"flex", pl: 4, mb: 1,}}>
                  <NavLink
                    id="submenu-auth-organization-ahs-results-link"
                    to={`/app/organizations/${activeOrg.id}/ahs`}
                    style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                  >
                    <ListItemIcon sx={{ ...sxListItemIcon }}>
                      <WorkspacePremiumOutlinedIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText sx={{ ...sxListItemText }} primary="America's Healthiest Schools" />
                  </NavLink>
                </Box>
              </ListItem>
            )}

            {/* DOCBUILDERS: STUFF TO SHOW IF **EXACTLY ONE** IS AVAILABLE TO THIS ORG */}
            {userCanViewDocbuilders && docbuildersForOrg.length === 1 && (
              <>
                {docbuildersForOrg.map((_d: Docbuilder) => {
                  let DocbuilderIcon = menuItemIcon(_d);
                  return (
                    <ListItem sx={{...sxListItem, pl: 4}} key={_d.slug}>
                      <NavLink
                        id={`submenu-auth-organization-docbuilder-${_d.slug}-link`}
                        to={`/app/organizations/${activeOrg.id}/builder/${_d.slug}`}
                        style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                      >
                        <ListItemIcon sx={{ ...sxListItemIcon }}>
                          {/* @ts-ignore */}
                          <DocbuilderIcon docbuilder={_d} color="primary" />
                        </ListItemIcon>
                        <ListItemText sx={{ ...sxListItemText }} primary={_d.name} />
                      </NavLink>
                    </ListItem>
                  );
                })}
              </>
            )}

            {/* DOCBUILDERS: STUFF TO SHOW IF **MORE THAN ONE** IS AVAILABLE TO THIS ORG */}
            {userCanViewDocbuilders && docbuildersForOrg.length > 1 && (
              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  id={`submenu-auth-organization-docbuilders-link`}
                  to={`/app/organizations/${activeOrg.id}/builder`}
                  style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <DocbuilderIcon docbuilder={null} color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ ...sxListItemText }}
                    primary={activeOrgIsDistrictLike ? "Policy Builders" : "Builders"}
                  />
                </NavLink>
              </ListItem>
            )}

            {/* {activeOrgIsDistrictLike && (
              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  id={`submenu-auth-organization-surveys-link`}
                  to={`/app/organizations/${activeOrg.id}/surveys/1`}
                  style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <HistoryEduOutlinedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{ ...sxListItemText }} primary="Surveys" />
                </NavLink>
              </ListItem>
            )} */}

            {userCanViewTeam && (
              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  id={`submenu-auth-organization-team-link`}
                  to={`/app/organizations/${activeOrg.id}/team`}
                  style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <SupervisorAccount color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{ ...sxListItemText }} primary="Team" />
                </NavLink>
              </ListItem>
            )}

          </List>
        </Collapse>

      </ListItem>

    </List>
  );
}

function DocbuilderIcon({ color, docbuilder }: { color: "primary"|"secondary", docbuilder: Docbuilder|null }) {
  let Icon = menuItemIcon(docbuilder);
  return <Icon color={color} />;
}

export default SubmenuAuthOrganization;

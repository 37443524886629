import { handleActions } from "redux-actions";
import { has, isArray, sortBy } from "lodash";
import {
  fetchOrganizationPlanBucketsStart,
  fetchOrganizationPlanBucketsSuccess,
  fetchOrganizationPlanBucketsFailure,
} from "@/store/actions";
import { nullBucket } from "@/features/plans/classes/PlanUtils";

// Top level:
// {
//    loading: 0, // qty of active requests (0 when none)
//    data: {}    // data object as described below.
// }
//
// The data object will be keyed by org ID, with each entry being an object
// of buckets, including the null bucket.
//
// {
//   10: {
//      loading: false,
//      failed: false,
//      buckets: [{id: 1111, name: "Mah bucket", ...}, {...}], // (buckets for org #10)
//   },
//   11: {
//      loading: false,
//      failed: false,
//      buckets: [{id: 1112, name: "Mah bucket", ...}, {...}], // (buckets for org #11)
//   },
// }
//
// Note that bucket IDs may be a string ("_null_") that represents the "null bucket".

const initialState = {
  data: {},
};

export default handleActions(
  {
    // Payload must be numeric org ID.
    [fetchOrganizationPlanBucketsStart]: (state, { payload }) => {
      let _state = { ...state };

      // Add org entry if not yet present.
      if (!has(_state.data, payload)) {
        _state.data[Number(payload)] = {
          loading: false,
          failed: false,
          buckets: [],
        };
      }

      // Add/increment loading flags.
      _state.data[Number(payload)].loading = true; // org-specific
      // top-level, not org-specific
      if (!has(_state, "loading")) {
        _state.loading = 1;
      } else {
        _state.loading++;
      }

      return _state;
    },
    [fetchOrganizationPlanBucketsSuccess]: (state, { payload }) => {
      if (!isArray(payload.data)) {
        console.error("Invalid payload.data in fetchOrganizationPlanBucketsSuccess.", payload.data);
        return { ...state };
      }

      // -----------------
      // @TODO
      // We need the org ID but don't currently have a reliable, structured
      // place to get it. For now, extract it from the meta.path string.
      // We'll eventually add `organization_id` as a meta property.
      let path = payload.meta.path;
      let matches = path.match(/\/api\/v1\/organizations\/(\d+)\/plans\/current\/buckets/);
      let orgId = Number(matches[1]);
      // -----------------

      // Sort em
      let sortedBuckets = sortBy(payload.data, ["weight"]);

      // Prepend the null bucket.
      sortedBuckets.unshift(nullBucket);

      return {
        ...state,
        loading: state.loading - 1,
        data: {
          ...state.data,
          [orgId]: {
            loading: false,
            failed: false,
            buckets: sortedBuckets,
          },
        },
      };
    },
    [fetchOrganizationPlanBucketsFailure]: (state, { payload }) => {
      // Not a lot we can do for a failed request, so log it to the
      // console with some debugging.
      console.error("fetchOrganizationResponsesFailure", state, payload);

      // Just return a mostly unmodified copy of state.
      return {
        ...state,
        loading: state.loading - 1,
      };
    },
  },
  initialState
);

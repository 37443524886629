import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import registerServiceWorker from "registerServiceWorker";
import { Router } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { appHistory } from "./common/other/appHistory";
import muiTheme from "./style/muiTheme";
import { CookiesProvider } from "react-cookie";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ErrorView from "@/common/components/views/ErrorView";
import { HelmetProvider } from "react-helmet-async";
import appStore from "./store/appStore";
import { version } from "./version";

Bugsnag.start({
  // @see also common/lib/errorHandler.js
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: import.meta.env.VITE_BUGSNAG_STAGE,
  appVersion: version,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ErrorBoundary FallbackComponent={ErrorView}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <HelmetProvider>
          <Provider store={appStore}>
            <CookiesProvider>
              <Router history={appHistory}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <App />
                </LocalizationProvider>
              </Router>
            </CookiesProvider>
          </Provider>
        </HelmetProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ErrorBoundary>
);
// registerServiceWorker();

/**
 * Static-only helper class for custom scroll-to-top functionality.
 *
 * Example usage:
 *
 * ```
 *  import React, { useRef } from "react";
 *  import { useLocation } from "react-router-dom";
 *  import {
 *    pathShouldScrollToTop,
 *    scrollToTop,
 *  } from "@/common/classes/ScrollToTopUtils";
 *
 *  // [...]
 *
 *  const currentLocation = useLocation();
 *  const mainWrapper = useRef(null);
 *
 *  // [...]
 *
 *  // Scroll main content wrapper to top when currentLocation changes
 *  // and the path is the list.
 *  useEffect(() => {
 *    if (currentLocation) {
 *      let p = currentLocation.pathname;
 *      if (mainWrapper.current && pathShouldScrollToTop(p)) {
 *        scrollToTop(mainWrapper.current);
 *      }
 *    }
 *  }, [currentLocation]);
 *
 * ```
 */
class ScrollToTopUtils {


  /**
   * Array of paths regexes that shall auto-scroll to top.
   *
   * @var {Array}
   */
  static scrollToTopList = [
    // Assessment questions
    /^\/app\/organizations\/(\d)+\/sets\/(\d)+\/questions(.)*$/gi,

    // Resources page
    // /^\/app\/resources(\/)?$/gi,
  ];

  /**
   * Determine if a location should auto-scroll to top.
   *
   * @param {string} p
   *  Pathname property for current location object. (obtain from useLocation() in
   *  "react-router-dom")
   * @returns {bool} `true` if auto scroll should be used, otherwise `false`.
   */
  static pathShouldScrollToTop(p: string): boolean {
    let shouldScroll = false;
    if (p) {
      // If location is in list, it should be auto scrolled.
      for (let i = 0; i < ScrollToTopUtils.scrollToTopList.length; i++) {
        if (p.match(ScrollToTopUtils.scrollToTopList[i])) {
          shouldScroll = true;
          break;
        }
      }
    }
    return shouldScroll;
  }

  /**
   * Execute scroll on ref of content area.
   *
   * Example:
   * ```
   *   const contentEl = useRef(null);
   *   // [...]
   *   scrollToTop(contentEl.current);
   * ```
   *
   * @param {object} elRef
   *  Spanish for "The Ref". Also ref of the element that shall be scrolled.
   * @returns {object}
   *  Returns elRef.
   */
  static scrollToTop(elRef: any) {
    if (elRef) {
      elRef.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    return elRef;
  }
}

export const pathShouldScrollToTop = ScrollToTopUtils.pathShouldScrollToTop;
export const scrollToTop = ScrollToTopUtils.scrollToTop;

import{ useCallback, useEffect, useState } from "react";
import { requestOrganization } from "@/common/api/requests";
import { Link, NavLink } from "react-router-dom";
import PrimaryMenuDrawer from "@/common/components/layout/PrimaryMenu/components/PrimaryMenuDrawer";
import { useLocation } from "react-router-dom";
import { userCan } from "@/common/classes/UserUtils";
import LmsSubmenu from "@/common/components/layout/PrimaryMenu/components/LmsSubmenu";
import SubmenuAnon from "@/common/components/layout/PrimaryMenu/components/SubmenuAnon";
import SubmenuAuth from "@/common/components/layout/PrimaryMenu/components/SubmenuAuth";
import AdministrationSubmenu from "@/common/components/layout/PrimaryMenu/components/AdministrationSubmenu";
import evaluatePath from "@/common/lib/evaluatePath";
import errorHandler from "@/common/lib/errorHandler";
import IconContactSupport from "@mui/icons-material/ContactSupportOutlined";
import IconExitToApp from "@mui/icons-material/ExitToApp";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { helpFromMestUrl } from "@/common/other/externalUrls";
import {
  styleLinkActive,
  styleLink,
  sxListItem,
  sxListItemText,
  sxListItemIcon,
} from "./_common";
import { CurrentUser } from "@/common/classes/UserModel";
import { Organization } from "@/common/classes/OrganizationModel";
import { colorLightGray } from "@/style/vars.module.scss";

//
// Primary menu for the Action Center.
//
function PrimaryMenu({
  currentUser,
  expandedAtMobileRes,
  toggleDrawer
}: {
  currentUser: CurrentUser,
  expandedAtMobileRes?: boolean,
  toggleDrawer: Function
}) {

  const currentLocation = useLocation();

  // For organization-specific submenu.
  const [activeOrgId, setActiveOrgId] = useState<number|null>(null); // from path
  const [activeOrg, setActiveOrg] = useState<Organization|null>(null);
  const [activeSetId, setActiveSetId] = useState<number|null>(null); // from path
  const [showingSubmenuAuth, setShowingSubmenuAuth] = useState<boolean>(false);
  const [showingSubmenuAuthOrgItems, setShowingSubmenuAuthOrgItems] = useState<boolean>(false);
  const [showingSubmenuAuthOrgSets, setShowingSubmenuAuthOrgSets] = useState<boolean>(false);

  // -- For org
  const [userCanViewActionPlan, setUserCanViewActionPlan] = useState<boolean>(false);
  const [userCanViewAssessments, setUserCanViewAssessments] = useState<boolean>(false);
  const [userCanViewDocbuilders, setUserCanViewDocbuilders] = useState<boolean>(false);
  const [userCanViewPrioritizationCalculator, setUserCanViewPrioritizationCalculator] = useState<boolean>(false);
  const [userCanViewTeam, setUserCanViewTeam] = useState<boolean>(false);

  // Reset all org permissions.
  const resetAccess = () => {
    setUserCanViewActionPlan(false);
    setUserCanViewAssessments(false);
    setUserCanViewDocbuilders(false);
    setUserCanViewPrioritizationCalculator(false);
    setUserCanViewTeam(false);
  };

  // Check and set all org permissions.
  const establishAccess = (_activeOrg: Organization, _currentUser: CurrentUser) => {
    if (_activeOrg && _currentUser && _currentUser.isAuthenticated) {
      let _viewActionPlan = userCan(_currentUser, "view_action_plans", _activeOrg);
      let _viewAssessments = userCan(_currentUser, "view_assessments", _activeOrg);
      let _viewDocbuilders = userCan(_currentUser, "view_docbuilders", _activeOrg);
      let _viewPrioritizationCalculator = userCan(_currentUser, "view_prioritization_calculators", _activeOrg);
      let _viewTeam = userCan(_currentUser, "view_team", _activeOrg);

      setUserCanViewActionPlan(_viewActionPlan);
      setUserCanViewAssessments(_viewAssessments);
      setUserCanViewDocbuilders(_viewDocbuilders);
      setUserCanViewPrioritizationCalculator(_viewPrioritizationCalculator);
      setUserCanViewTeam(_viewTeam);
      return;
    }
    // Otherwise, reset to no access.
    resetAccess();
  };

  // Initializations when user or org change.
  useEffect(() => {
    // Note that this gets called _before_ checking permissions so we have
    // the org object to evaluate against.
    resetAccess();

    setActiveOrg(null);

    // Be sure we have all we need to retrieve an org record.
    if (activeOrgId && currentUser && currentUser.isAuthenticated) {
      requestOrganization(activeOrgId)
        .then((res) => {
          let newActiveOrg = res?.data?.data || null;
          // Note: Perm checks are done elsewhere upon change to activeOrg.
          setActiveOrg(newActiveOrg);
        })
        .catch((err) => {
          errorHandler(err, false, true);
          setActiveOrg(null);
        });
    }
  }, [activeOrgId, currentUser]);

  // Check permissions when active org object changes.
  useEffect(() => {
    if (activeOrg && currentUser && currentUser.isAuthenticated) {
      establishAccess(activeOrg, currentUser);
      return;
    }
    resetAccess();
  }, [activeOrg, currentUser]); // eslint-disable-line

  // Determine what parts of menu should be expanded.
  useEffect(() => {
    let p = currentLocation.pathname;

    let newActiveOrgId = null;
    let newActiveSetId = null;
    let newShowingSubmenuAuthOrgItems = false;
    let newShowingSubmenuAuthOrgSets = false;
    let evaluatedPath = evaluatePath(p);

    if (evaluatedPath.active_organization_id) {
      newActiveOrgId = evaluatedPath.active_organization_id;
      newActiveSetId = evaluatedPath.active_set_id;
      newShowingSubmenuAuthOrgItems = true;
      if (evaluatedPath.active_set_id) {
        newShowingSubmenuAuthOrgSets = true;
      }
    }

    setActiveOrgId(newActiveOrgId);
    setActiveSetId(newActiveSetId);
    setShowingSubmenuAuthOrgItems(newShowingSubmenuAuthOrgItems);
    setShowingSubmenuAuthOrgSets(newShowingSubmenuAuthOrgSets);
  }, [currentLocation]);

  const toggleShowingSubmenuAuthOrgItems = useCallback((expand: boolean) => {
    setShowingSubmenuAuthOrgItems(Boolean(expand));
  }, []);

  const toggleShowingSubmenuAuthOrgSets = useCallback((expand: boolean) => {
    setShowingSubmenuAuthOrgSets(Boolean(expand));
  }, []);

  const toggleShowingSubmenuAuth = useCallback((expand: boolean) => {
    setShowingSubmenuAuth(Boolean(expand));
  }, []);

  return (
    <PrimaryMenuDrawer expandedAtMobileRes={expandedAtMobileRes} toggle={toggleDrawer}>
      {!currentUser.loading && (
        <>
          {/* ANON USER MENU */}
          {!currentUser.isAuthenticated && <SubmenuAnon currentUserIsAuthenticated={currentUser.isAuthenticated} />}
          {/* AUTHENTICATED USER MENU */}
          {currentUser.isAuthenticated && (
            <>
              <SubmenuAuth
                activeOrg={activeOrg || undefined}
                activeSetId={activeSetId || undefined}
                expanded={showingSubmenuAuth}
                toggleExpanded={toggleShowingSubmenuAuth}
                showingOrgItems={showingSubmenuAuthOrgItems}
                showingOrgSetItems={showingSubmenuAuthOrgSets}
                toggleShowingOrgItems={toggleShowingSubmenuAuthOrgItems}
                toggleShowingOrgSets={toggleShowingSubmenuAuthOrgSets}
                userCanViewActionPlan={userCanViewActionPlan}
                userCanViewAssessments={userCanViewAssessments}
                userCanViewDocbuilders={userCanViewDocbuilders}
                userCanViewPrioritizationCalculator={userCanViewPrioritizationCalculator}
                userCanViewTeam={userCanViewTeam}
              />
            </>
          )}

          <>
            {/* <Divider sx={{ marginBottom: 1.5, marginTop: 1.5 }} /> */}
            <LmsSubmenu />
          </>

          <List
            id="submenu-help-etc"
            sx={{...sxListItem, pb: 3, borderBottom: `3px solid ${colorLightGray}`}}
          >
            {/* GET HELP ITEM */}
            {/* NOTE: react-router-dom <Link> requires absolute URLs omit
                any protocol (i.e., `https:`) and simply begin with `//`. */}
            <ListItem sx={{...sxListItem, pl: 4}}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={helpFromMestUrl}
                style={styleLink}
              >
                <ListItemIcon sx={{ ...sxListItemIcon }}>
                  <IconContactSupport color="primary" />
                </ListItemIcon>
                <ListItemText sx={{ ...sxListItemText }} primary="Get Help" />
              </Link>
            </ListItem>

            {/* LOG OUT ITEM */}
            {currentUser.isAuthenticated && (
              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  to="/app/account/logout"
                  style={(isActive) => (isActive ? styleLinkActive : styleLink )}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <IconExitToApp color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{ ...sxListItemText }} primary="Log Out" />
                </NavLink>
              </ListItem>
            )}
          </List>

          {/* ADMIN MENU */}
          {currentUser.isAuthenticated && (
            <>
              {(currentUser.isStaff || currentUser.isAdmin) && (
                <AdministrationSubmenu currentUser={currentUser} />
              )}
            </>
          )}
        </>
      )}
    </PrimaryMenuDrawer>
  );
}

export default PrimaryMenu;

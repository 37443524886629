import { ReactNode } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  ListSubheader,
  OutlinedInput,
  Select,
  SxProps,
} from "@mui/material";

export type HgSelectOption = {
  disabled?: boolean;
  label: ReactNode|string;
  subLabel: ReactNode|string;
  value: string|number
}

/**
 * Our customized select field.
 *
 * Note that it includes `<FormControl>` and `<FormHelperText>`,
 * so don't provide those in calling code.
 *
 * Options with a value property of "__header__" will be treated as
 * unselectable headers in the dropdown.
 */
function HgSelect({
  color = "primary",
  disabled = false,
  displayEmpty = false,
  error = false,
  formControlSx = {},
  helperText,
  id,
  inputLabelSx = {},
  label,  // label: Shows "legend" style, or inside if no selection
  menuItemSx = {},
  multiple = false,
  name,
  noSelectionText = "Select...",
  onBlur,
  onChange,
  options,
  placeholder,
  renderValue,
  required,
  selectSx = {},
  size = "medium",
  value,
}: {
  color?: string,
  disabled?: boolean,
  displayEmpty?: boolean,
  error?: boolean,
  formControlSx?: SxProps,
  helperText?: ReactNode|string,
  id?: string,
  inputLabelSx?: SxProps,
  label?: ReactNode|string,   // label: Shows "legend" style, or inside if no selection
  menuItemSx?: SxProps,
  multiple?: boolean,
  name?: string,
  noSelectionText?: string,
  onBlur?: Function,
  onChange?: Function,
  options: HgSelectOption[],
  placeholder?: string,
  renderValue?: Function,
  required?: boolean,
  selectSx?: SxProps,
  size?: "small"|"medium",
  value?: number|string,
}) {
  return (
    <FormControl
      error={error}
      fullWidth
      size={size}
      disabled={disabled}
      required={required}
      sx={{ ...formControlDefaultSx, ...formControlSx }}
    >
      {label && (
        <InputLabel disabled={disabled} error={error} required={required} sx={inputLabelSx}>
          {label}
        </InputLabel>
      )}
      <Select
        required={required}
        // @ts-ignore
        color={color}
        disabled={disabled}
        displayEmpty={displayEmpty}
        error={error}
        id={id || ""}
        input={<OutlinedInput />}
        inputProps={{ "aria-label": "Without label" }}
        label={label}
        MenuProps={MENU_PROPS}
        multiple={multiple}
        name={name || ""}
        notched={true}
        // @ts-ignore
        onBlur={onBlur}
        // @ts-ignore
        onChange={onChange}
        placeholder={placeholder}
        // @ts-ignore
        renderValue={renderValue}
        value={value || ""}
        sx={selectSx}
      >
        <MenuItem
          key="__noselection__"
          value=""
          sx={{
            fontStyle: "italic",
            opacity: 0.5,
            ...menuItemSx
          }}
        >
          {noSelectionText}
        </MenuItem>

        {options.map((_option, index) => {
          if ("__header__" === _option.value) {
            return (
              <ListSubheader
                key={_option.value + index}
                sx={{ color: "#aaaaaa", ml: -1, mt: 0, mb: 0 }}
              >
                <em>{_option.label}</em>
              </ListSubheader>
            );
          } else {
            return (
              <MenuItem
                key={_option.value}
                value={_option.value}
                disabled={_option.disabled || undefined}
                sx={{
                  display: _option.subLabel ? "block" : "flex",
                  ...menuItemSx
                }}
              >
                <div>{_option.label}</div>
                {_option.subLabel && (
                  <div style={{fontStyle: "italic", fontSize: "1.25rem", opacity: 0.5}}>
                    {_option.subLabel}
                  </div>
                )}
              </MenuItem>
            );
          }
        })}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const formControlDefaultSx = {
  mb: 1,
  mt: 1.5,
};

HgSelect.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  error: PropTypes.bool,
  formControlSx: PropTypes.object, // sx style object
  helperText: PropTypes.node, // include error text here (and helper info)
  inputLabelSx: PropTypes.object, // sx style object
  label: PropTypes.node,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  noSelectionText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array, // ex: [{value:123, label:"Onetwothree", disabled:false}, ...]
  renderValue: PropTypes.func,
  required: PropTypes.bool,
  selectSx: PropTypes.object, // sx style object
  size: PropTypes.oneOf(["small", "medium"]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default HgSelect;

import { combineReducers } from "redux";
import appMetaReducers from "@/store/reducers/app_meta";
import authReducers from "@/store/reducers/auth";
import contentsReducers from "@/store/reducers/contents";
import changePasswordReducers from "@/store/reducers/change_password";
import forgotPasswordReducers from "@/store/reducers/forgot_password";
import organizationPlanItemsReducers from "@/store/reducers/organization_plan_items";
import organizationPlanBucketsReducers from "@/store/reducers/organization_plan_buckets";
import organizationResponsesReducers from "@/store/reducers/organization_responses";
import organizationSetsReducers from "@/store/reducers/organization_sets";
import organizationTeamReducers from "@/store/reducers/organization_team";
import programsReducers from "@/store/reducers/programs";

export default combineReducers({
  app_meta: appMetaReducers,
  auth: authReducers,
  contents: contentsReducers,
  change_password: changePasswordReducers,
  forgot_password: forgotPasswordReducers,
  organization_plan_items: organizationPlanItemsReducers,
  organization_plan_buckets: organizationPlanBucketsReducers,
  organization_responses: organizationResponsesReducers,
  organization_sets: organizationSetsReducers,
  organization_team: organizationTeamReducers,
  programs: programsReducers,
});

import { OrganizationType } from "@/common/classes/OrganizationTypeModel";
import { each, find, isEmpty, isNil, isNumber } from "lodash";

/**
 * Static-only helper class for organization type-related functionality.
 */
class OrganizationTypeUtils {
  /**
   * Get organization type object for a machine_name string.
   *
   * @param {String} machineName
   *  Org type machine name value.
   * @param {Object} orgTypes
   *  Typically you'll use appMeta.data.organizationTypes for this.
   * @returns {Object|Null}
   */
  static orgTypeForMachineName(machineName: string, orgTypes: {[key: number]: OrganizationType}): OrganizationType|null {
    let returnVal: OrganizationType|null = null;
    if (!isNil(machineName)) {
      let orgType = find(orgTypes, (v) => {
        return machineName === v.machine_name;
      });
      returnVal = orgType ? orgType as OrganizationType : returnVal;
    }
    return returnVal;
  }

  /**
   * Evaluate whether an organization type is considered "district like".
   *
   * Aside from districts, there are currently two other org types that
   * are treated similarly: ESDs and CMOs.
   *
   * @parma {object} orgType
   * @returns {bool}
   */
  static orgTypeIsDistrictLike(orgType: any): boolean {
    let otmn = orgType.machine_name;
    return otmn === "district" || otmn === "esd" || otmn === "cmo";
  }

  /**
   * Get organization type name as string for an ID or object.
   *
   * @param {Number|Object} idOrObj
   *  The org type ID or an object with organization_type_id prop.
   * @param {Object} orgTypes
   *  Typically you'll use app_meta.data.organizationTypes for this.
   * @param {String} ifNone
   *  String to return if org type not found.
   * @returns {String}
   */
  static orgTypeName(idOrObj: number | any, orgTypes: any, ifNone: string = ""): string {
    if (!isNil(idOrObj) && !isEmpty(idOrObj)) {
      let otId = idOrObj;
      if (!isNumber(otId)) {
        otId = otId.organization_type_id;
      }
      if (otId) {
        otId = parseInt(otId, 10);
        if (!isNil(orgTypes[otId])) {
          return orgTypes[otId].name;
        }
      }
    }
    return ifNone;
  }

  /**
   * Get object of organizationTypes keyed by machine name.
   *
   * Converts the `appMeta.data.organizationTypes` redux object,
   * which is keyed by numeric id.
   *
   * @param {object} orgTypes
   * @returns {object}
   */
  static orgTypesByMachineName(orgTypes: any): object {
    let res: any = {};
    each(orgTypes, (v) => {
      res[v.machine_name] = v;
    });
    return res;
  }
}

export const orgTypeForMachineName = OrganizationTypeUtils.orgTypeForMachineName;
export const orgTypeIsDistrictLike = OrganizationTypeUtils.orgTypeIsDistrictLike;
export const orgTypeName = OrganizationTypeUtils.orgTypeName;
export const orgTypesByMachineName = OrganizationTypeUtils.orgTypesByMachineName;

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styleLinkActive, styleLink } from "@/common/components/layout/PrimaryMenu/_common";
import { userCan } from "@/common/classes/UserUtils";
import { CurrentUser } from "@/common/classes/UserModel";
import { colorGrayBrown } from "@/style/vars.module.scss";

//
// Primary menu: Submenu for system administration.
//
function AdministrationSubmenu({ currentUser }: { currentUser: CurrentUser }) {

  const [allow, setAllow] = useState(false);
  const [userCanViewAllContent, setUserCanViewAllContent] = useState(false);
  const [userCanViewAllCriteria, setUserCanViewAllCriteria] = useState(false);
  const [userCanViewAllInvites, setUserCanViewAllInvites] = useState(false);
  const [userCanViewAllMaterials, setUserCanViewAllMaterials] = useState(false);
  const [userCanViewAllPrograms, setUserCanViewAllPrograms] = useState(false);
  const [userCanViewAllRequests, setUserCanViewAllRequests] = useState(false);
  const [userCanViewAllSets, setUserCanViewAllSets] = useState(false);
  const [userCanViewAllSetDemos, setUserCanViewAllSetDemos] = useState(false);
  const [userCanViewAllSettingTags, setUserCanViewAllSettingTags] = useState(false);
  const [userCanViewAllSurveys, setUserCanViewAllSurveys] = useState(false);
  const [userCanViewAllTags, setUserCanViewAllTags] = useState(false);
  const [userCanViewAllTerms, setUserCanViewAllTerms] = useState(false);
  const [userCanViewAllUsers, setUserCanViewAllUsers] = useState(false);

  useEffect(() => {
    if (!currentUser || !currentUser.loaded || (!currentUser.isAdmin && !currentUser.isStaff)) {
      // Clear perms if we don't have this stuff.
      setAllow(false);
      setUserCanViewAllContent(false);
      setUserCanViewAllCriteria(false);
      setUserCanViewAllInvites(false);
      setUserCanViewAllMaterials(false);
      setUserCanViewAllPrograms(false);
      setUserCanViewAllRequests(false);
      setUserCanViewAllSetDemos(false);
      setUserCanViewAllSets(false);
      setUserCanViewAllSettingTags(false);
      setUserCanViewAllSurveys(false);
      setUserCanViewAllTags(false);
      setUserCanViewAllTerms(false);
      setUserCanViewAllUsers(false);
    } else {
      // Otherwise, set the perms.
      setAllow(true);
      setUserCanViewAllContent(userCan(currentUser, "view_all_content"));
      setUserCanViewAllCriteria(userCan(currentUser, "view_all_criteria"));
      setUserCanViewAllInvites(userCan(currentUser, "view_all_invites"));
      setUserCanViewAllMaterials(userCan(currentUser, "view_all_materials"));
      setUserCanViewAllPrograms(userCan(currentUser, "view_all_programs"));
      setUserCanViewAllRequests(userCan(currentUser, "view_all_requests"));
      setUserCanViewAllSetDemos(userCan(currentUser, "view_all_set_demos"));
      setUserCanViewAllSets(userCan(currentUser, "view_all_sets"));
      setUserCanViewAllSettingTags(userCan(currentUser, "view_all_setting_tags"));
      setUserCanViewAllSurveys(userCan(currentUser, "view_all_surveys"));
      setUserCanViewAllTags(userCan(currentUser, "view_all_tags"));
      setUserCanViewAllTerms(userCan(currentUser, "view_all_terms"));
      setUserCanViewAllUsers(userCan(currentUser, "view_all_users"));
    }
  }, [currentUser, currentUser.loaded]);

  if (!allow) {
    return null;
  }

  return (
    <Accordion sx={{ border: "none !important", paddingTop: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`adminmenu`}
        id={`adminmenu-header`}
        sx={{ border: "none" }}
      >
        Administration
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
          borderBottom: "none",
        }}
      >
        <List
          component="div"
          sx={{
            ...sxAdminList,
            border: "none",
          }}
        >
          {currentUser.isAdmin && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/activity-log"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Activity Log" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllSets && userCanViewAllPrograms && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/sets"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Assessments" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllContent && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/content"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Content" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllCriteria && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/criteria"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Criteria" />
              </NavLink>
            </ListItem>
          )}

          {/* Set demos are the only demos we have right now, so that's
              the perm we check for "demos" admin item */}
          {userCanViewAllSetDemos && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/demos"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Demos" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllMaterials && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/lms"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="LMS" />
              </NavLink>
            </ListItem>
          )}

          {currentUser.isAdmin && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/metrics"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Metrics" />
              </NavLink>
            </ListItem>
          )}

          <ListItem sx={sxAdminListItem}>
            <NavLink
              to="/app/admin/organizations"
              style={(isActive) => (isActive ? styleLinkActive : styleLink )}
            >
              <ListItemText sx={{ ...sxAdminListItemText }} primary="Organizations" />
            </NavLink>
          </ListItem>

          {userCanViewAllInvites && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/pending/invites"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Pending Invites" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllRequests && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/pending/requests"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Pending Requests" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllPrograms && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/programs"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Programs" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllSettingTags && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/settings"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Setting Tags" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllSurveys && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/surveys"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Surveys" />
              </NavLink>
            </ListItem>
          )}

          {/* Intentionally "view_all_users" for soft gate logs */}
          {userCanViewAllUsers && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
              to="/app/admin/softgate"
              style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Soft-gate Logs" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllTags && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/tags"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Tags" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllTerms && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/terms"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Terms" />
              </NavLink>
            </ListItem>
          )}

          {userCanViewAllUsers && (
            <ListItem sx={sxAdminListItem}>
              <NavLink
                to="/app/admin/users"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemText sx={{ ...sxAdminListItemText }} primary="Users" />
              </NavLink>
            </ListItem>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

const sxAdminListItem = {
  mb: 0.5,
  p: 0,
  pl: 2,
}

const sxAdminListItemText = {
  color: "#FFFFFF",
  fontSize: 15,
  paddingLeft: 1,
}
const sxAdminList = {
  backgroundColor: colorGrayBrown,
  margin: 0,
  marginTop: 1,
  padding: 0,
  pb: 2,
  pt: 2,
};

export default AdministrationSubmenu;

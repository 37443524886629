import React from "react";

/**
 * Facilitates having an aria-live element for announcements.
 *
 * Provider is implemented in App.tsx.
 *
 * ```
 * // App.tsx
 * ...
 * const [globalAriaLiveContextMessage, setGlobalAriaLiveContextMessage] = useState("");
 * ...
 * <div className="sr-only" aria-live="polite">{globalAriaLiveContextMessage}</div>
 * ```
 *
 * ```
 * // Some other component.
 * const { setMessage } = useContext(GlobalAriaLiveContext);
 *
 * useEffect(() => {
 *   setMessage("Loading");
 *   return () => {
 *     setMessage("");
 *   }
 * }, []);
 * ...
 * ```
 */
const GlobalAriaLiveContext = React.createContext<{message: string, setMessage: Function}>({
  message: "",
  setMessage: () => {}
});

export default GlobalAriaLiveContext;
import { get } from "lodash";
import { takeEvery } from "redux-saga/effects";
import {
  fetchOrganizationSets,
  fetchOrganizationSetsStart,
  fetchOrganizationSetsSuccess,
  fetchOrganizationSetsFailure,
} from "@/store/actions";
import { fetcherForSagas } from "@/store/fetcherForSagas";
import { requestOrgSetsData } from "@/features/assessments/api/requests";

// @TODO

function* organization_sets() {
  yield takeEvery(
    fetchOrganizationSets,
    fetcherForSagas({
      start: fetchOrganizationSetsStart,
      success: fetchOrganizationSetsSuccess,
      failure: fetchOrganizationSetsFailure,
      request: ({ payload }) => {
        let orgId = Number(payload.organization_id);
        let params = {};

        // Filter payload to the params we'll allow to be sent from here.
        // These are all optional, so we allow empty values.
        params.set_id = get(payload, "set_id", "");

        // Hardcode pagination values (no need to support more flexibility).
        params.page = 1;
        params.per_page = 500;

        return requestOrgSetsData(orgId, params);
      },
    })
  );
}

export default organization_sets;

import { isString } from "lodash";

/**
 * Helper to change CSS value string to a JS number w/out units.
 *
 * For example, this will convert `"10px"` to `10`. Floats are
 * supported.
 *
 * Non-strings will result in a return value of `0`.
 *
 * Entirely non-numeric values (like "normal") will also return
 * `0`, unless passThroughNonNumeric=true, in which case the
 * value is returned as-is.
 *
 * Strings with multiple values (like margin shorthand) are not
 * supported and will give you some unspecified result.
 *
 * @param {string} value
 * @param {boolean} passThroughNonNumeric
 * @returns {number|string}
 */
export default function unitlessNumber(value: string, passThroughNonNumeric: boolean = false) {
  if (!value || !isString(value)) {
    return 0;
  }

  // If value starts with a number (or decimal point), we should be able to convert it.
  value = value.trim();
  if (/^.?[\d]/.test(value)) {
    return parseFloat(value); // strips off px, rem, etc
  }

  // Otherwise, we pass through if requested, throw an error if not.
  if (passThroughNonNumeric) {
    return value;
  }

  return 0;
}

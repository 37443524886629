import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import {
  styleLinkActive,
  styleLink,
  sxList,
  sxListItem,
  sxListItemIcon,
  sxListItemText,
} from "../_common";

//
// Primary menu: Submenu for anonymous users.
//
function SubmenuAnon({ currentUserIsAuthenticated }: { currentUserIsAuthenticated: boolean }) {

  if (currentUserIsAuthenticated) {
    return null;
  }

  return (
    <List id="submenu-anon" sx={sxList}>
      <ListItem sx={{...sxListItem, mt: 2, pl: 4, }}>
        <NavLink
          id="submenu-anon-login-link"
          to="/app/account/login"
          style={(isActive) => (isActive ? styleLinkActive : styleLink )}
        >
          <ListItemIcon sx={{ ...sxListItemIcon }}>
            <ForwardIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            sx={{
              sxListItemText,
            }}
            primary="Log in"
          />
        </NavLink>
      </ListItem>
    </List>
  );
}

export default SubmenuAnon;

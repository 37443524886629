import React, { useState, useCallback, useEffect } from "react";
import { applyPromptOnExitToLinks } from "@/features/prompt-on-exit/classes/PromptOnExitUtils";
import ExitPrompt from "@/features/prompt-on-exit/components/ExitPrompt";
import { usePrevious } from "state-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { get, isObject, isString } from "lodash";
import { Box, CircularProgress } from "@mui/material";
import { requestCsrfCookie } from "@/common/api/requests";
import { requestCreateUserActivity } from "@/common/api/requests-activity-log";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagManager from "react-gtm-module";
import AppRouter from "./AppRouter";
import GlobalAriaLiveContext from "@/common/contexts/GlobalAriaLiveContext";
import { Store } from "@/store/Store";

// Layout components
import PrimaryContent from "@/common/components/layout/PrimaryContent";
import Header from "@/common/components/layout/Header";
import PrimaryMenu from "@/common/components/layout/PrimaryMenu";
import SitewideAlertBanner from "@/common/components/views/SitewideAlertBanner";

// Actions
import { fetchAppMeta, fetchContents, fetchPrograms, initializeCurrentUserData } from "@/store/actions";

// Style stuff
import "@/style/App.scss";

// React GTM (in production only)
// @see https://www.npmjs.com/package/react-gtm-module
if ("production" === import.meta.env.MODE) {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}

//
// App.js
//
function App() {

  const currentLocation = useLocation();
  const dispatch = useDispatch();

  // Redux data.
  const appMeta = useSelector((state: Store) => state.app_meta);
  const contents = useSelector((state: Store) => state.contents);
  const currentUser = useSelector((state: Store) => state.auth.currentUser);
  const programs = useSelector((state: Store) => state.programs);

  // Redux loading state values.
  const [loadingAppMeta, setLoadingAppMeta] = useState(true);
  const [loadingCurrentUser, setLoadingCurrentUser] = useState(true);
  const [loadingPrograms, setLoadingPrograms] = useState(true);

  // Context for aria-live announcements.
  // @see HgCircularProgressGlobal
  const [globalAriaLiveContextMessage, setGlobalAriaLiveContextMessage] = useState("");

  // Other state values.

  // -- primaryMenuExpandedAtMobileRes:
  //    When true and screen is at a mobile resolution, the primary menu
  //    drawer is open. Not applicable above mobile resolution.
  const [primaryMenuExpandedAtMobileRes, setPrimaryMenuExpandedAtMobileRes] = useState(false);

  // -- includingPrimaryMenu:
  //    When true, the primary menu is rendered in the DOM (though
  //    still potententially hidden/collapsed). Is set to false for
  //    pages like login where navigation isn't applicable.
  const [includingPrimaryMenu, setIncludingPrimaryMenu] = useState(true);

  // -- showingSitewideAlert:
  //    Whether the global (sitewide) alert banner is to be visible.
  const [showingSitewideAlert, setShowingSitewideAlert] = useState<boolean>(false);

  // -- stillLoadingReqs:
  //    Whether we're still getting the basic data we need for bootstrapping
  //    the Action Center.
  const [stillLoadingReqs, setStillLoadingReqs] = useState(true);

  // -- exitPromptIsOpen
  //    Whether the "exit prompt" is currently being shown.
  const [exitPromptIsOpen, setExitPromptIsOpen] = useState(false);

  // -- exitPromptDestination
  //    A URL object that will be passed to the ExitPrompt component.
  const [exitPromptDestination, setExitPromptDestination] = useState<URL|null>(null);

  // Previous values.
  const prevCurrentLocation = usePrevious(currentLocation);

  // --
  // Set-up exit prompts for external links.
  useEffect(() => {
    applyPromptOnExitToLinks((destUrlObj: URL) => {
      setExitPromptDestination(destUrlObj);
      setExitPromptIsOpen(true);
    });
  }, []);

  // --
  // Adjust value of includingPrimaryMenu based on a pathname string.
  // @TODO MOVE TO PRIMARY NAV?
  const adjustIncludingPrimaryMenuForPath = (path: string) => {
    let newIncludingPrimaryMenu = true;
    if (isString(path) && path.match(/^\/app\/account\/login\/*$/gi)) {
      newIncludingPrimaryMenu = false;
    }
    setIncludingPrimaryMenu(newIncludingPrimaryMenu);
  };

  // --
  // Reverse current value of primaryMenuExpandedAtMobileRes.
  const togglePrimaryMenuExpandedAtMobileRes = useCallback(() => {
    setPrimaryMenuExpandedAtMobileRes(!primaryMenuExpandedAtMobileRes);
  }, [primaryMenuExpandedAtMobileRes]);

  // --
  // Logs location changes for authenticated users.
  useEffect(() => {
    if (currentLocation?.pathname && currentLocation?.pathname !== prevCurrentLocation?.pathname) {
      requestCreateUserActivity("page", currentLocation.pathname, "loaded_page");
    }
  }, [currentLocation, prevCurrentLocation]);

  // --
  // Retrieve the fundamental data required for the system.
  useEffect(() => {
    // Request CSRF token MUST be set by API before other
    // API calls are made. So, we call to have the token
    // set here and wait to make the other calls until the
    // that request is complete.
    //
    // API sets the CSRF token as a cookie and will automatically
    // refresh it as needed. We don't need to capture the value
    // or do anything besides make the initial request.
    requestCsrfCookie().then(() => {
      dispatch(fetchPrograms());
      dispatch(fetchAppMeta());
      dispatch(initializeCurrentUserData());
      dispatch(
        fetchContents({
          machine_name: "sitewide_alert",
        })
      );
    });
  }, [dispatch]);

  // --
  // Adjust loading state of appMeta.
  useEffect(() => {
    let newLoadingAppMeta = !isObject(appMeta) || !get(appMeta, "loaded", false);
    setLoadingAppMeta(newLoadingAppMeta);
  }, [appMeta]);

  // --
  // Adjust loading state of currentUser.
  useEffect(() => {
    // Unlike the other redux values we evaluate, we actually want to know if the
    // current user is "bootrapped".
    let cu = currentUser;
    let newLoadingCurrentUser = !isObject(cu) || !get(cu, "bootstrapped", false);
    setLoadingCurrentUser(newLoadingCurrentUser);
  }, [currentUser]);

  // --
  // Adjust loading state of programs.
  useEffect(() => {
    let newLoadingPrograms = !isObject(programs) || !get(programs, "loaded", false);
    setLoadingPrograms(newLoadingPrograms);
  }, [programs]);

  // --
  // Establish if we're still loading the minimum required data.
  useEffect(() => {
    let newStillLoadingReqs = loadingCurrentUser || loadingAppMeta || loadingPrograms;

    setStillLoadingReqs(newStillLoadingReqs);
  }, [loadingCurrentUser, loadingAppMeta, loadingPrograms]);

  // --
  // Respond to changes in location.
  useEffect(() => {
    let cl = currentLocation;
    let pathname = isObject(cl) ? get(cl, "pathname", null) : null;

    if (pathname) {
      // Adjust includingPrimaryMenu as needed.
      adjustIncludingPrimaryMenuForPath(pathname);
    }
  }, [currentLocation]);

  // --
  // Establish whether we should show the sitewide alert.
  useEffect(() => {
    // Whether we do depends only on if it's published or not.
    let newShowingSitewideAlert = Boolean(get(contents.data, `sitewide_alert.published`, false));
    setShowingSitewideAlert(newShowingSitewideAlert);
  }, [contents]);

  return (
    <GlobalAriaLiveContext.Provider
      value={{
        message: globalAriaLiveContextMessage,
        setMessage: setGlobalAriaLiveContextMessage
      }}>
      <div className="app">
        <Box>
          {stillLoadingReqs && (
            <Box sx={{ p: 3, textAlign: "center" }}>
              <CircularProgress color="primary" size={40} />
            </Box>
          )}

          {!stillLoadingReqs && (
            <React.Fragment>
              <Header
                togglePrimaryMenuExpandedAtMobileRes={togglePrimaryMenuExpandedAtMobileRes}
                currentUser={currentUser}
                includingPrimaryMenu={includingPrimaryMenu}
              />

              <div className="app-body">
                {includingPrimaryMenu && appMeta && appMeta.loaded && (
                  <>
                    <PrimaryMenu
                      expandedAtMobileRes={primaryMenuExpandedAtMobileRes}
                      toggleDrawer={togglePrimaryMenuExpandedAtMobileRes}
                      currentUser={currentUser}
                    />
                  </>
                )}
                {/* === CONTENT AREA === */}
                <PrimaryContent primaryMenuVisible={includingPrimaryMenu}>
                  {showingSitewideAlert && <SitewideAlertBanner />}
                  <AppRouter currentUser={currentUser} programs={programs} />
                </PrimaryContent>
              </div>
            </React.Fragment>
          )}

          {/* aria live output for GlobalAriaLiveContext */}
          <div className="sr-only" aria-live="polite">{globalAriaLiveContextMessage}</div>

          {/* Use utils/hgToast to trigger toast notifications */}
          <ToastContainer limit={3} />

          {/* Show dialog when external link is clicked */}
          {exitPromptDestination && (
            <ExitPrompt
              destination={exitPromptDestination}
              isOpen={exitPromptIsOpen}
              onCancel={() => setExitPromptIsOpen(false)}
            />
          )}
        </Box>

      </div>
    </GlobalAriaLiveContext.Provider>
  );
}

export default App;

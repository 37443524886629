import { useRef, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { pathShouldScrollToTop, scrollToTop } from "@/common/classes/ScrollToTopUtils";
import { siteMainMaxWidth, siteSidebarMenuWidth } from "@/style/vars.module.scss";
import Footer from "@/common/components/layout/Footer";

/**
 * Primary content area of Action Center.
 */
function PrimaryContent({
  children,
  primaryMenuVisible,
}: {
  children?: ReactNode,
  primaryMenuVisible?: boolean,
}) {
  const currentLocation = useLocation();
  const mainWrapper = useRef(null);

  useEffect(() => {
    if (currentLocation) {
      if (
        mainWrapper.current &&
        currentLocation &&
        pathShouldScrollToTop(currentLocation.pathname)
      ) {
        scrollToTop(mainWrapper.current);
      }
    }
  }, [currentLocation]);

  return (
    <Box
      ref={mainWrapper}
      sx={() => ({
        // NOTE: This element is responsible for all
        // content area scrolling above the 'md' breakpoint.
        scrollbarGutter: "stable",
        bgcolor: "background.default",
        height: {
          md: "100vh",
        },
        maxHeight: {
          md: "100vh",
        },
        minHeight: "100vh",
        overflowX: {
          xs: "hidden",
        },
        overflowY: {
          md: "scroll",
        },
        marginLeft: {
          md: primaryMenuVisible ? siteSidebarMenuWidth : "auto",
        },
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: {
          xs: 10,
          lg: 11,
        },
        position: "relative",
        "@media print": {
          height: null,
          overflow: null,
          paddingTop: 0.25,
        },
        scrollPaddingTop: "88px",
      })}
    >
      {/* === MAIN === */}
      <Box
        id="primary-content-container"
        component="main"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: siteMainMaxWidth,
          minHeight: {
            xs: "100px",
            // for sm, just enough to push footer out of view during loads
            sm: "300px",
          },
          width: "100%",
        }}
      >
        {children}
      </Box>

      {/* === FOOTER === */}
      <Footer />
    </Box>
  );
}

export default PrimaryContent;

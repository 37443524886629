import React from "react";
import { Box } from "@mui/material";
import { getSelectionEntity } from "draftjs-utils";
import { colorLightLightOrange, txtFontWeightDefaultSemibold } from "@/style/vars.module.scss";

const DecoratorControls = (props) => {
  const { allowedHtml, promptForDecorator, editorState } = props;
  let currentEntity = getSelectionEntity(editorState);
  let contentState = editorState.getCurrentContent();

  return (
    <>
      {allowedHtml.map((type) => {
        let active = false;
        if (currentEntity && contentState.getEntity(currentEntity).get("type") === type.style) {
          active = true;
        }
        return (
          <Box
            component="span"
            sx={{
              backgroundColor: active ? colorLightLightOrange : "#EEEEEE",
              borderRadius: "3px !important",
              color: "black",
              borderColor: active ? colorLightLightOrange : "#CCCCCC",
              borderStyle: "dotted",
              borderWidth: "2px",
              boxShadow: "0 0 4px rgba(0, 0, 0, 0.25)",
              cursor: "pointer",
              fontFamily: "sans-serif",
              fontSize: "10px",
              fontWeight: txtFontWeightDefaultSemibold,
              letterSpacing: "normal",
              lineHeight: 1,
              margin: 0.5,
              padding: "4px 6px",
              display: "inline-block",
              "&:hover": {
                borderColor: "white",
              },
            }}
            key={type.label}
            onMouseDown={(e) => promptForDecorator(e, type.style, type.label)}
          >
            {type.label}
          </Box>
        );
      })}
    </>
  );
};

export default DecoratorControls;

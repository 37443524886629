import React from "react";
import { Box, Tooltip } from "@mui/material";
import { colorLightBlue } from "@/style/vars.module.scss";

/**
 * AbbrTitle (tooltip) decorator for the DraftEditor.
 *
 * The output of this component is used in both edit and
 * read-only modes.
 */
const AbbrTitleDecorator = (props) => {
  const { dec } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <>
      <Tooltip title={dec} placement="top">
        <Box
          component="span"
          sx={{
            borderBottom: `1px dotted ${colorLightBlue}`,
            cursor: "help",
          }}
        >
          {props.children}
        </Box>
      </Tooltip>
    </>
  );
};

export default AbbrTitleDecorator;

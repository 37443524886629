import React, { useCallback, useState, useEffect, useRef, RefObject } from "react";
import { convertToRaw, convertFromRaw, CompositeDecorator, Editor, EditorState, Modifier, RichUtils } from "draft-js";
import { Box, FormControl, FormLabel } from "@mui/material";
import { cloneDeep, forEach, get, isEmpty, isNil, isNull, isString, sortBy } from "lodash";
import { Button, CircularProgress, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import isAbsoluteUrl from "@/common/lib/isAbsoluteUrl";
import isRootRelativePath from "@/common/lib/isRootRelativePath";
// @ts-ignore
import draftEditorTextIsEmpty from "@/features/rich-text/lib/draftEditorTextIsEmpty";
// @ts-ignore
import findAbbrTitleEntities from "@/features/rich-text/lib/findAbbrTitleEntities";
// @ts-ignore
import findAbbrDataEntities from "@/features/rich-text/lib/findAbbrDataEntities";
// @ts-ignore
import findLinkEntities from "@/features/rich-text/lib/findLinkEntities";
import { usePrevious } from "state-hooks";
// @ts-ignore
import AbbrTitleDecorator from "@/features/rich-text/components/DraftEditor/decorators/AbbrTitleDecorator";
// @ts-ignore
import LinkDecorator from "@/features/rich-text/components/DraftEditor/decorators/LinkDecorator";
// @ts-ignore
import TermDialogDecorator from "@/features/rich-text/components/DraftEditor/decorators/TermDialogDecorator";
// @ts-ignore
import BlockStyleControls from "@/features/rich-text/components/DraftEditor/controls/BlockStyleControls";
// @ts-ignore
import InlineStyleControls from "@/features/rich-text/components/DraftEditor/controls/InlineStyleControls";
// @ts-ignore
import DecoratorControls from "@/features/rich-text/components/DraftEditor/controls/DecoratorControls";
import { requestTerms } from "@/features/terms/api/requests";
import {
  colorBlue,
  colorGray,
  colorLightLightBlue,
  txtFontSizeSm,
  txtFontSizeXxs,
  txtFontWeightDefaultSemibold,
} from "@/style/vars.module.scss";
import HgSelect from "@/common/components/ui/atoms/HgSelect";
import errorHandler from "@/common/lib/errorHandler";
import { Term } from "@/features/terms/classes/TermModel";

// @TODO "Open in new window" isn't saved (at least in UI)

/**
 * Rewrite of our custom DraftEditor component.
 *
 * Creating this for two primary reasons:
 *
 * 1. Existing component is class-based, not hook-based. So, it's
 *    a pain to work with.
 *
 * 2. Existing component requires being unmounted to change values.
 */
function DraftEditor2({
  customToolbarHtml,
  formLabelText,
  indexProp,
  keyProp,
  onChange,
  readOnly,
  value
}: {
  customToolbarHtml?: any,
  formLabelText?: string, // not used when readOnly=true
  indexProp?: number,
  keyProp?: number|string,
  onChange?: Function,
  readOnly?: boolean,
  value?: any
}) {
  // REFS
  const editorRef = useRef<RefObject<any>|null>(null);
  const decoratorInputRef = useRef(null);

  // STATE
  const [editorState, setEditorState] = useState<EditorState|null>(null);
  const [showDecInput, setShowDecInput] = useState<boolean>(false);
  const [decValue, setDecValue] = useState<string>("");
  const [currentDecType, setCurrentDecType] = useState<string>("");
  const [terms, setTerms] = useState<Term[]>([]);
  const [termsLoading, setTermsLoading] = useState<boolean>(false);
  const [linkTargetBlank, setLinkTargetBlank] = useState<boolean>(false);
  const [urlError, setUrlError] = useState<boolean>(true);
  const [editorInitialized, setEditorInitialized] = useState<boolean>(false);
  const [decorator, setDecorator] = useState<CompositeDecorator|null>(null);
  const [incrementToRefocusEditor, setIncrementToRefocusEditor] = useState<number>(0);

  // PREVIOUS
  const prevValue = usePrevious(value);

  //
  // Refocus editor when incrementToRefocusEditor is incremented.
  //
  useEffect(() => {
    if (incrementToRefocusEditor > 0) {
      setTimeout(() => {
        // @ts-ignore
        editorRef.current?.focus();
      }, 0);
    }
  }, [incrementToRefocusEditor]);

  //
  // Set-up decorator.
  //
  useEffect(() => {
    let newDecorator = new CompositeDecorator([
      { strategy: findAbbrTitleEntities, component: AbbrTitleDecorator },
      {
        strategy: findAbbrDataEntities,
        component: TermDialogDecorator,
        props: { readOnly: readOnly },
      },
      { strategy: findLinkEntities, component: LinkDecorator },
    ]);
    setDecorator(newDecorator);
  }, [readOnly]);

  //
  // Set-up initial editor state, apply value prop updates.
  //
  useEffect(() => {
    let newES, preppedValue;

    // We only execute code in this hook if the the decorator is already set-up.
    if (decorator) {
      preppedValue = prepareValueProp(value);

      // == NEW INSTANCES. (readOnly and not)
      if (!editorInitialized) {
        if (!preppedValue) {
          newES = EditorState.createEmpty(decorator);
        } else {
          newES = EditorState.createWithContent(preppedValue, decorator);
        }
        setEditorState(newES);
        setEditorInitialized(true);
        onChangeEditor(newES)
      }
      // == EXISTING _READONLY_ INSTANCES
      else if (editorInitialized && readOnly && value !== prevValue) {
        newES = EditorState.createWithContent(preppedValue, decorator);
        setEditorState(newES);
        onChangeEditor(newES)
      }
    }
  }, [decorator, editorInitialized, prevValue, readOnly, value]);

  /**
   * What to do when editor is focused.
   *
   * @params {boolean} moveToEnd
   *  Optional. Typically used when an outside element event (like a button onClick)
   *  occurs, and we want to set the cursor at the end.
   *  Without moveToEnd, its the default behavior when focusing in the textfield. This
   *  also includes preservation of previous cursor position.
   */
  const focusEditor = useCallback((moveToEnd: boolean = false) => {
    if (moveToEnd === true) {
      // @ts-ignore
      setEditorState((editorState) => EditorState.moveFocusToEnd(editorState));
    } else {
      // @ts-ignore
      editorRef.current?.focus(); // @TODO was this.refs.editor.focus();
    }
  }, []);

  // APPARENTLY NOT IN USE AS OF 2022-02-03 (ak)
  //
  // Safely clear editor contents.
  // Generally called through ref with a parent component
  //
  // const clearEditor = useCallback(() => { // @TODO was _clearEditor
  //   const newES = EditorState.push(editorState, ContentState.createFromText(""));
  //   setEditorState(newES);
  // }, [editorState]);

  //
  // ...
  //
  const onChangeDec = (e: any) => {
    let value = get(e, "target.value", null);
    if (value) {
      setDecValue(value);
    }
  };

  //
  // ...
  //
  const onChangeEditor = useCallback(
    (editorState: any) => {
      if (onChange) {
        const content = editorState.getCurrentContent();
        if (!isNil(indexProp)) {
          onChange(convertToRaw(content), indexProp);
        } else {
          onChange(convertToRaw(content));
        }
      }
      setEditorState(editorState);
    },
    [indexProp, onChange]
  );

  //
  // Setup keybinds for editor. (Ctrl + B will bold in windows, for example)
  //
  const handleKeyCommand = useCallback(
    (command: any) => {
      // @ts-ignore
      const newES = RichUtils.handleKeyCommand(editorState, command);
      if (newES) {
        onChangeEditor(newES);
        return true;
      }
      return false;
    },
    [editorState, onChangeEditor]
  );

  // APPARENTLY NOT IN USE AS OF 2022-02-03 (ak)
  // const onTab = useCallback((e) => { // @TODO was _onTab(e) {
  //   const maxDepth = 4;
  //   onChangeEditor(RichUtils.onTab(e, editorState, maxDepth));
  // }, [editorState]);

  //
  // ...
  //
  const toggleBlockType = useCallback(
    (blockType: any) => {
      // @ts-ignore
      onChangeEditor(RichUtils.toggleBlockType(editorState, blockType));
    },
    [editorState, onChangeEditor]
  );

  //
  // ...
  //
  const toggleInlineStyle = useCallback(
    (inlineStyle: any) => {
      // @ts-ignore
      onChangeEditor(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    },
    [editorState, onChangeEditor]
  );

  //
  // Retrieve terms from server.
  //
  const getTerms = useCallback(() => {
    setTermsLoading(true);

    requestTerms({
      per_page: 1000,
    })
      .then((res) => {
        let terms = res?.data?.data;
        if (terms) {
          let termsForSelect = terms.map((term: Term) => {
            return { value: term.id, label: term.name + " - " + term.id };
          });
          let sortedTerms = sortBy(termsForSelect, ["label", "value"]);
          setTerms(sortedTerms);
        }
      })
      .catch((err) => {
        errorHandler(err, false, true);
        setTerms([]);
      })
      .finally(() => {
        setTermsLoading(false);
      });
  }, []);

  //
  // ...
  //
  const promptForDecorator = useCallback(
    (e: any, decType: string) => {
      e.preventDefault();

      const selection = editorState?.getSelection();
      if (selection && !selection.isCollapsed()) {
        const contentState = editorState?.getCurrentContent();
        const startKey = editorState?.getSelection().getStartKey();
        const startOffset = editorState?.getSelection().getStartOffset();
        const blockWithDecAtBeginning = contentState?.getBlockForKey(String(startKey));
        const decKey = blockWithDecAtBeginning?.getEntityAt(Number(startOffset));

        let dec = "";
        if (decKey) {
          const decInstance = contentState?.getEntity(decKey);
          dec = decInstance?.getData().dec;
        }

        if (decType === "ABBRDATA") {
          getTerms();
        }

        setShowDecInput(true);
        setDecValue(dec);
        setCurrentDecType(decType);
      }
    },
    [editorState, getTerms]
  );

  //
  // ...
  //
  const confirmDecorator = useCallback(
    (e: any) => {
      e.preventDefault();
      if (editorState) {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(currentDecType, "MUTABLE", {
          dec: decValue,
          isLinkTargetBlank: linkTargetBlank,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
          currentContent: contentStateWithEntity,
        });
        setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
        setShowDecInput(false);
        setDecValue("");
        setIncrementToRefocusEditor((refocusEditor) => refocusEditor + 1);
      }
    },
    [editorState, decValue, currentDecType, linkTargetBlank]
  );

  //
  // ...
  //
  const onDecoratorInputKeyDown = useCallback(
    (e: any) => {
      if (e.which === 13) {
        confirmDecorator(e);
      }
    },
    [confirmDecorator]
  );

  //
  // ...
  //
  const handleChangeLinkType = useCallback((linkTargetBlank: boolean, decValue: any) => {
    setLinkTargetBlank(!linkTargetBlank);
    validateUrl(decValue, !linkTargetBlank);
  }, []);

  //
  // ...
  //
  const handleDecLinkChange = useCallback((e: any, linkTargetBlank: boolean) => {
    let url = e.target.value;
    setDecValue(url);
    validateUrl(url, linkTargetBlank);
  }, []);

  //
  // ...
  //
  const validateUrl = (url: string, isLinkTargetBlank: boolean) => {
    let newUrlError;
    let isValidAbsUrl = isAbsoluteUrl(url);
    let isValidRelUrl = isRootRelativePath(url);

    if ((isLinkTargetBlank && isValidAbsUrl) || (!isLinkTargetBlank && isValidRelUrl)) {
      newUrlError = false;
    }
    if ((isLinkTargetBlank && !isValidAbsUrl) || (!isLinkTargetBlank && !isValidRelUrl)) {
      newUrlError = true;
    }
    setUrlError(Boolean(newUrlError));
  };

  // APPARENTLY NOT IN USE AS OF 2022-02-03 (ak)
  // const focusDecoratorInputField = (input) => { // @TODO was focusDecoratorInputField = (input) => {
  //   decoratorInputRef.current.focus();
  // };

  //
  // ...
  //
  const getConfirmButton = useCallback(
    (error: any = null) => {
      return (
        <Button onMouseDown={confirmDecorator} variant="contained" color="primary" disabled={error} size="small">
          Confirm
        </Button>
      );
    },
    [confirmDecorator]
  );

  //
  // ...
  //
  const getCancelButton = useCallback(() => {
    return (
      <Button onMouseDown={() => setShowDecInput(false)} variant="contained" color="secondary" size="small">
        Cancel
      </Button>
    );
  }, []);

  //
  // ...
  //
  const removeSelectedEntity = useCallback(() => {
    if (editorState) {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const startKey = selectionState.getStartKey();
      const contentBlock = contentState.getBlockForKey(startKey);
      const startOffset = selectionState.getStartOffset();
      const entity = contentBlock.getEntityAt(startOffset);

      if (!entity) {
        return;
      }

      let entitySelection = null;

      contentBlock.findEntityRanges(
        (character) => character.getEntity() === entity,
        (start, end) => {
          entitySelection = selectionState.merge({
            anchorOffset: start,
            focusOffset: end,
          });
        }
      );

      if (entitySelection) {
        const newContentState = Modifier.applyEntity(contentState, entitySelection, null);
        const newEditorState = EditorState.push(editorState, newContentState, "apply-entity");
        setEditorState(newEditorState);
      }
      setShowDecInput(false);
    }
  }, [editorState]);

  //
  // ...
  //
  const getRemoveButton = useCallback(() => {
    return (
      <Button onMouseDown={removeSelectedEntity} variant="contained" color="primary" size="small">
        Remove
      </Button>
    );
  }, [removeSelectedEntity]);

  //
  // ...
  //
  const getDecInput = useCallback(() => {
    // @TODO REFACTOR THIS METHOD

    // For Terms
    if (currentDecType === "ABBRDATA") {
      if (isEmpty(terms) && termsLoading) {
        return <CircularProgress />;
      }
      return (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <HgSelect
              placeholder="Select a term"
              // @ts-ignore This appears to work @TODO Investigate
              options={terms}
              value={decValue}
              onChange={onChangeDec}
              size="small"
            />
          </Box>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>{getCancelButton()}</Grid>
            <Grid item>{getRemoveButton()}</Grid>
            <Grid item>{getConfirmButton()}</Grid>
          </Grid>
        </>
      );
    }

    if (currentDecType === "ABBRTITLE") {
      return (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TextField
              onChange={onChangeDec}
              inputRef={decoratorInputRef}
              value={decValue}
              onKeyDown={onDecoratorInputKeyDown}
              variant="outlined"
              size="small"
            />
          </Box>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>{getConfirmButton()}</Grid>
            <Grid item>{getRemoveButton()}</Grid>
            <Grid item>{getCancelButton()}</Grid>
          </Grid>
        </>
      );
    }

    if (currentDecType === "LINK") {
      let helperText = "";
      if (urlError && linkTargetBlank) {
        helperText = `Must be an absolute URL (i.e., it must start with "https://")`;
      } else if (urlError && !linkTargetBlank) {
        helperText = `Must be a root-relative path (i.e., it must start with a slash '/')`;
      }

      return (
        <React.Fragment>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TextField
              onChange={(e) => handleDecLinkChange(e, linkTargetBlank)}
              inputRef={decoratorInputRef}
              value={decValue}
              onKeyDown={onDecoratorInputKeyDown}
              variant="outlined"
              error={urlError}
              helperText={helperText}
              size="small"
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox checked={linkTargetBlank} onChange={() => handleChangeLinkType(linkTargetBlank, decValue)} />
            }
            label="Open in new window"
          />
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>{getConfirmButton(urlError)}</Grid>
            <Grid item>{getRemoveButton()}</Grid>
            <Grid item>{getCancelButton()}</Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }, [
    currentDecType,
    terms,
    termsLoading,
    decValue,
    getCancelButton,
    getRemoveButton,
    getConfirmButton,
    onDecoratorInputKeyDown,
    urlError,
    linkTargetBlank,
    handleDecLinkChange,
    handleChangeLinkType,
  ]);

  // ======
  // OUTPUT
  // ======

  if (!editorState) {
    return null;
  }

  return (
    <Box sx={{mb: 2, mt: 1}}>
      <FormControl fullWidth variant="standard">

        {formLabelText && (
          <FormLabel>{formLabelText}</FormLabel>
        )}

        {readOnly && (
          <>
            {/* @ts-ignore */}
            <Editor editorState={editorState} customStyleMap={styleMap} ref={editorRef} readOnly={true} key={keyProp} />
          </>
        )}

        {!readOnly && (
          <>
            {/* @ts-ignore */}
            <Box backgroundColor={colorBlue} border={`solid 1px ${colorGray}`} fontSize={txtFontSizeSm}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  fontSize: txtFontSizeXxs,
                  margin: 0.5,
                  textTransform: "uppercase",
                }}
              >
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={toggleInlineStyle}
                  allowedHtml={customToolbarHtml ? customToolbarHtml.INLINE_STYLES : defaultToolbarConfig.INLINE_STYLES}
                />
                <BlockStyleControls
                  editorState={editorState}
                  onToggle={toggleBlockType}
                  allowedHtml={customToolbarHtml ? customToolbarHtml.BLOCK_TYPES : defaultToolbarConfig.BLOCK_TYPES}
                />
                <DecoratorControls
                  editorState={editorState}
                  promptForDecorator={promptForDecorator}
                  allowedHtml={customToolbarHtml ? customToolbarHtml.DECORATORS : defaultToolbarConfig.DECORATORS}
                />
              </Box>

              {showDecInput && (
                <Box
                  sx={{
                    backgroundColor: colorLightLightBlue,
                    padding: 1,
                  }}
                >
                  {getDecInput()}
                </Box>
              )}

              {/* @ts-ignore */}
              <Box
                borderTop={`solid 1px ${colorGray}`}
                onClick={focusEditor}
                minHeight={100}
                padding={1}
                sx={{backgroundColor:"white"}}
              >
                {/* @ts-ignore */}
                <Editor
                  editorState={editorState}
                  onChange={onChangeEditor}
                  customStyleMap={styleMap}
                  ref={editorRef}
                  key={keyProp}
                  handleKeyCommand={handleKeyCommand}
                  stripPastedStyles={true}
                />
              </Box>
            </Box>
          </>
        )}
      </FormControl>
    </Box>
  );
}

/**
 * Prepare a `value` prop for use as editor state content.
 *
 * @param {object|string|null} v
 *  The `value` prop of this component, which could be null or
 *  "raw" ContentState JSON (itself either a JSON string or
 *  object).
 * @returns {object|null}
 *  If return is an object, it's a Draft ContentState that is
 *  ready for use in EditorState. If null, there's no content.
 */
const prepareValueProp = (v: any) => {
  let preppedValue: any = null;

  if (!draftEditorTextIsEmpty(v)) {
    // Make sure value is actually JSON.
    // (API sometimes sends as plain text)
    if (isString(v)) {
      preppedValue = JSON.parse(v);
    } else {
      preppedValue = cloneDeep(v);
    }
    try {
      // DB may set empty text blocks to null, which messes up Draft.js. Set null text
      // props to an empty string here to get around this.
      let vBlocks = get(preppedValue, "blocks", []);
      forEach(vBlocks, (block, bdx) => {
        if (isNull(block.text)) {
          preppedValue.blocks[bdx].text = "";
        }
      });
      preppedValue = convertFromRaw(preppedValue);
    } catch (e: any) {
      console.error(`Error setting up initial editorState with EditorState.createWithContent(): ${e.message}`);
    }
  }
  return preppedValue;
};

const defaultToolbarConfig = {
  BLOCK_TYPES: [
    { label: "Blockquote", style: "blockquote" },
    { label: "Bullet List", style: "unordered-list-item" },
    { label: "Number List", style: "ordered-list-item" },
  ],
  INLINE_STYLES: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Subscript", style: "SUBSCRIPT" },
    { label: "Superscript", style: "SUPERSCRIPT" },
  ],
  DECORATORS: [
    { label: "Link", style: "LINK" },
    { label: "Tooltip", style: "ABBRTITLE" },
    { label: "Term", style: "ABBRDATA" },
  ],
};

//
// Style object for custom inlineStyles.
//
const styleMap = {
  // ABBRTITLE: { // tooltip
  //   backgroundColor: "black",
  //   textDecoration: "underline",
  // },
  // ABBRDATA: { // term
  //   backgroundColor: "black",
  //   textDecoration: "underline",
  // },
  SUBSCRIPT: {
    verticalAlign: "sub",
    fontSize: "smaller",
  },
  SUPERSCRIPT: {
    verticalAlign: "super",
    fontSize: "smaller",
  },
  BOLD: {
    // By default draftJs will use 'bold', but we want
    // a value managed from our style settings that
    // aligns with our other type styles.
    fontWeight: txtFontWeightDefaultSemibold,
  },
};

export default DraftEditor2;

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  styleLinkActive,
  styleLink,
  sxList,
  sxListItem,
  sxListItemText,
  sxListItemIcon,
} from "../_common";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { Store } from "@/store/Store";

function LmsSubmenu({}) {
  const currentUser = useSelector((state: Store) => state.auth.currentUser);

  // === out ===

  return (
    <>
      <List id="lms-submenu" sx={{...sxList, pb: 3}}>
        <ListItem sx={{...sxListItem, pl: 4}}>
          <NavLink
            id="lms-submenu-index-link"
            to="/app/resources"
            style={(isActive) => (isActive ? styleLinkActive : styleLink )}
          >
            <ListItemIcon sx={{ ...sxListItemIcon }}>
              <BuildOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              sx={{ ...sxListItemText }}
              primary="Resources & Trainings" />
          </NavLink>
        </ListItem>

        {currentUser.isAuthenticated && (
          <>
            <ListItem sx={{...sxListItem, pl: 4}}>
              <NavLink
                exact
                id="lms-submenu-favorites-link"
                to="/app/account/favorites"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemIcon sx={{ ...sxListItemIcon }}>
                  <FavoriteBorderIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  sx={{ ...sxListItemText }}
                  primary="My Favorites" />
              </NavLink>
            </ListItem>

            <ListItem sx={{...sxListItem, pl: 4}}>
              <NavLink
                exact
                id="lms-submenu-curricula-link"
                to="/app/account/curricula"
                style={(isActive) => (isActive ? styleLinkActive : styleLink )}
              >
                <ListItemIcon
                  sx={{
                    ...sxListItemIcon,
                  }}
                >
                  <SchoolOutlinedIcon color="primary" />
                </ListItemIcon>
                <ListItemText sx={{ ...sxListItemText }} primary="My Curricula" />
              </NavLink>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
}

export default LmsSubmenu;

import { ReactNode } from "react";
import { Box, Drawer } from "@mui/material";
import useIsWidthUp from "@/common/hooks/useIsWidthUp";
import { siteSidebarMenuWidth } from "@/style/vars.module.scss";

function PrimaryMenuDrawer({
  children,
  expandedAtMobileRes,
  toggle,
}: {
  children: ReactNode
  expandedAtMobileRes?: boolean,
  toggle: Function,
}) {
  let isLargerDisplay = useIsWidthUp("md");

  return (
    <Drawer
      id="primary-menu-drawer"
      variant={isLargerDisplay ? "permanent" : "temporary"}
      open={isLargerDisplay || expandedAtMobileRes}
      anchor={"left"}
      // @ts-ignore
      onClose={toggle}
      className="no-print"
      ModalProps={{
        // Better open performance on mobile.
        // (at least in earlier versions of MUI)
        keepMounted: true,
      }}
      sx={(theme) => ({
        borderRadius: 4,
        display: {
          sm: "block",
        },
        "&.MuiDrawer-docked": {
          height: "100%",
          zIndex: theme.zIndex.appBar - 1,
        },
        "& .MuiDrawer-paper": {
          scrollbarGutter: "stable",
          height: "100%",
          marginRight: 0,
          padding: 0,
          paddingTop: {
            md: theme.spacing(6.25),
            lg: theme.spacing(7.25),
          },
          width: siteSidebarMenuWidth,
          zIndex: theme.zIndex.appBar - 1,
        },
      })}
    >
      <Box component="nav" aria-label="primary navigation" sx={{ marginTop: 2.5 /*1.5*/ }}>
        {children}
      </Box>
    </Drawer>
  );
}

export default PrimaryMenuDrawer;

import { makeRequest } from "@/common/classes/ApiUtils";

/**
 * Update a Content record.
 *
 * @param   {string}  contentMachineName
 * @param   {Object}  content
 * @return  {Promise}
 */
export const requestUpdateContent = (contentMachineName: string, content: object) => {
  return makeRequest({
    url: `/api/v1/contents/${contentMachineName}`,
    body: content,
    method: "PUT",
  });
};

/**
 * Get multiple content records.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestContents = (params: object) =>
  makeRequest({
    url: "/api/v1/contents",
    body: params,
    method: "GET",
  });

/**
 * Get content based on machine name
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestContentsShow = (params: object) =>
  makeRequest({
    url: "/api/v1/contents-show",
    body: params,
    method: "GET",
  });

/**
 * Get files
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestFiles = (params: object) =>
  makeRequest({
    url: "/api/v1/files",
    body: params,
    method: "GET",
  });

/**
 * Evaluate a path string for active entities, other contextual info.
 *
 * @deprecated
 *
 * @param {string} p
 * @returns {object}
 */
export default function evaluatePath(p: string): EvaluatedPath {
  let res: EvaluatedPath = {
    path_type: null, // will be one of: account|admin|organization|other
    active_organization_id: null,
    active_program_id: null, // !!! is only set on program paths
    active_set_id: null,
  };

  p = p.toLowerCase();

  // Determine path type, set other values.
  if (p === "/app/account" || p.startsWith("/app/account/")) {
    // -----------------------------------
    // TYPE: ACCOUNT ---------------------
    // -----------------------------------
    res.path_type = "account";
    let orgMatch = p.match(/^\/app\/account\/organizations\/([0-9]+)/);
    if (orgMatch) {
      res.active_organization_id = parseInt(orgMatch[1], 10);
    }
  } else if (p === "/app/organizations" || p.startsWith("/app/organizations/")) {
    // -----------------------------------
    // TYPE: ORGANIZATION ----------------
    // -----------------------------------
    res.path_type = "organization";
    let orgMatch = p.match(/^\/app\/organizations\/([0-9]+)/);
    if (orgMatch) {
      res.active_organization_id = parseInt(orgMatch[1], 10);
      let setMatch = p.match(/^\/app\/organizations\/(?:[0-9]+)\/sets\/([0-9]+)/);
      if (setMatch) {
        res.active_set_id = parseInt(setMatch[1], 10);
      }
    }
  } else if (p === "/app/admin" || p.startsWith("/app/admin/")) {
    // -----------------------------------
    // TYPE: ADMIN -----------------------
    // -----------------------------------
    res.path_type = "admin";
    let progMatch = p.match(/^\/app\/admin\/programs\/([0-9]+)/);
    if (progMatch) {
      res.active_program_id = parseInt(progMatch[1], 10);
      let setMatch = p.match(/^\/app\/admin\/programs\/(?:[0-9]+)\/sets\/([0-9]+)/);
      if (setMatch) {
        res.active_set_id = parseInt(setMatch[1], 10);
      }
    }
    let orgMatch = p.match(/^\/app\/admin\/organizations\/([0-9]+)/);
    if (orgMatch) {
      res.active_organization_id = parseInt(orgMatch[1], 10);
    }
    let setMatch = p.match(/^\/app\/admin\/sets\/([0-9]+)/);
    if (setMatch) {
      res.active_set_id = parseInt(setMatch[1], 10);
    }
  } else {
    // -----------------------------------
    // TYPE: OTHER -----------------------
    // -----------------------------------
    res.path_type = "other";
  }
  return res;
}

export type EvaluatedPath = {
  path_type: "account"|"admin"|"organization"|"other"|null;
  active_organization_id: number|null;
  active_program_id: number|null;
  active_set_id: number|null;
}
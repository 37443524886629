import { forEach, get, isEmpty, isString, sortBy } from "lodash";
// @ts-ignore
import memoizee from "memoizee";
import { PlanItem } from "./PlanItemModel";

/**
 * Static-only helper class for working with action plans.
 */
class PlanUtils {
  /**
   * Faux-bucket object representing no bucket.
   *
   * @var {Object}
   */
  static nullBucket = {
    id: "_null_",
    name: "To work on",
    description:
      "Items to do eventually, but not current priority. Drag priority items to the appropriate category's column.",
  };

  /**
   * Item name to use when no Criterion is set.
   *
   * @var {string}
   */
  static defaultPlanItemName = "Untitled";

  /**
   * Item name when otherwise unavailable.
   *
   * This should be used when access is restricted or item is based on a Criterion that
   * doesn't exist or is not visible to user. (edge case).
   *
   * @var {string}
   */
  static unavailableName = "Unavailable";

  /**
   * Get sorted items for a bucket.
   *
   * @param {number|string} bucketId
   *  Numeric ID or special faux ID like the null bucket ID.
   * @param {Array} itemsArr
   *  Array of plan item objects that will be searched through.
   * @returns {Array}
   */
  static bucketItemsForBucket = (bucketId: number, itemsArr: PlanItem[]): PlanItem[] => {
    let _bucketItems: PlanItem[] = [];

    // Handle non-numeric bucket IDs (faux buckets), standardize others.
    switch (bucketId) {
      // @ts-ignore
      case PlanUtils.nullBucket.id:
        // @ts-ignore
        bucketId = null; // plan_bucket_ids will need to match this.
        break;
      default:
        bucketId = Number(bucketId);
    }

    forEach(itemsArr, (item) => {
      let _itemBID = item.plan_bucket_id ? Number(item.plan_bucket_id) : null;
      if (_itemBID === bucketId) {
        _bucketItems.push(item);
      }
    });

    let sorted = sortBy(_bucketItems, [
      function (obj) {
        return Number(obj.weight);
      },
    ]);
    return sorted;
  };

  /**
   * Generate an object with ready-to-use planItem properties for item cards, detail views.
   *
   * @param {Object} planItem
   * @param {boolean} userCanViewActionPlan
   * @param {boolean} userCanViewAssessments
   * @returns {Object}
   */
  static generatePlanItemViewData(planItem: PlanItem, userCanViewActionPlan: boolean = false, userCanViewAssessments: boolean = false) {
    let _criterion = null;
    let _isComplete = false;
    let _name = PlanUtils.unavailableName;

    // Handle for non-criterion plan items first, since that logic is shared regardless
    // of user/assessment perms.
    if (userCanViewActionPlan && (!planItem.criterion_id || isEmpty(planItem.criterion))) {
      // Not criterion-based (or is at least missing criterion obj).
      _name = isString(planItem.name) && planItem.name.length > 0 ? planItem.name : PlanUtils.defaultPlanItemName;
    }

    // Criterion-based.
    else {
      // Check that user can view assessments before setting set-related values.
      if (userCanViewAssessments) {
        _criterion = planItem.criterion;
        _name = get(planItem, "criterion.name", PlanUtils.unavailableName);
      }
    }

    if (planItem.date_completed) {
      _isComplete = Boolean(planItem.date_completed);
    }

    return {
      criterion: _criterion,
      isComplete: _isComplete,
      name: _name,
      hasNotes: planItem.has_notes,
      hasTasks: planItem.has_tasks,
    };
  }
}

export const defaultPlanItemName = PlanUtils.defaultPlanItemName;
export const bucketItemsForBucket = memoizee(PlanUtils.bucketItemsForBucket);
export const generatePlanItemViewData = memoizee(PlanUtils.generatePlanItemViewData);
export const nullBucket = PlanUtils.nullBucket;
export const unavailableName = PlanUtils.unavailableName;

import { makeRequest } from "@/common/classes/ApiUtils";
import errorHandler from "@/common/lib/errorHandler";

/**
 * Create a new activity log record for a User.
 *
 * @param   {string}  activity
 * @return  {Promise}
 */
export const requestCreateUserActivity = (
  actedOn: string, // ex: "resource"
  actedOnDetail: number|string, // ex: 123
  action: string, // ex: "completed"
  metadata?: object|null,
) => {
  return makeRequest({
    url: `/api/v1/log-activity`,
    body: {
      acted_on: actedOn,
      acted_on_detail: actedOnDetail,
      action: action,
      metadata: metadata,
    },
    method: "POST",
    redirectOnAuthFailure: false,
  })
  .catch((err) => {
    errorHandler(err, false, false, "Logging error");
  });
};

/**
 * Request admin-facing activity log.
 *
 * @return {Promise}
 */
export const requestAdminActivityLog = (params: object) => {
  return makeRequest({
    url: `/api/v1/activity-log`,
    method: "GET",
    body: params,
  });
};

/**
 * Request user-specific, user-facing activity log.
 *
 * @return {Promise}
 */
export const requestUserActivityLog = (userId: number, params: object = {}) => {
  return makeRequest({
    url: `/api/v1/users/${userId}/activity-log`,
    method: "GET",
    body: params,
  });
};

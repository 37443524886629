import { takeEvery } from "redux-saga/effects";
import {
  fetchOrganizationPlanItems,
  fetchOrganizationPlanItemsStart,
  fetchOrganizationPlanItemsSuccess,
  fetchOrganizationPlanItemsFailure,
} from "@/store/actions";
import { fetcherForSagas } from "@/store/fetcherForSagas";
import { requestOrganizationPlanItems } from "@/features/plans/api/requests";

function* organization_plan_items() {
  yield takeEvery(
    fetchOrganizationPlanItems,
    fetcherForSagas({
      start: fetchOrganizationPlanItemsStart,
      success: fetchOrganizationPlanItemsSuccess,
      failure: fetchOrganizationPlanItemsFailure,
      request: ({ payload }) => {
        let orgId = Number(payload.organization_id);
        let params = {};

        // Hardcode pagination values (no need to support more flexibility).
        params.page = 1;
        params.per_page = 500;

        return requestOrganizationPlanItems(orgId, params);
      },
    })
  );
}

export default organization_plan_items;

import { filter, find, has, includes, isArray, isNil, isNumber, map, round, sortBy, uniq } from "lodash";
import isAbsoluteUrl from "@/common/lib/isAbsoluteUrl";
import { Set } from "./SetModel";
import { Response } from "./ResponseModel";
import { CriterionInstance } from "./CriterionInstanceModel";
import unansweredImage from "@/features/assessments/images/alignments/alignment-unanswered.svg";
import zeroPercentImage from "@/features/assessments/images/alignments/alignment-0-percent.svg";
import twentyPercentImage from "@/features/assessments/images/alignments/alignment-20-percent.svg";
import twentyFivePercentImage from "@/features/assessments/images/alignments/alignment-25-percent.svg";
import thirtyThreePercentImage from "@/features/assessments/images/alignments/alignment-33-percent.svg";
import fourtyPercentImage from "@/features/assessments/images/alignments/alignment-40-percent.svg";
import fiftyPercentImage from "@/features/assessments/images/alignments/alignment-50-percent.svg";
import sixtyPercentImage from "@/features/assessments/images/alignments/alignment-60-percent.svg";
import sixtySevenPercentImage from "@/features/assessments/images/alignments/alignment-67-percent.svg";
import seventyFivePercentImage from "@/features/assessments/images/alignments/alignment-75-percent.svg";
import eightyPercentImage from "@/features/assessments/images/alignments/alignment-80-percent.svg";
import oneHundredPercentImage from "@/features/assessments/images/alignments/alignment-100-percent.svg";

/**
 * Static-only helper class for working with sets.
 */
class SetUtils {

  /**
   * Return correct image given an alignment percentage value (.33)
   *
   * @param {string} number
   */
  static alignmentValueImages(alignment: number|null) {
    if (isNil(alignment)) {
      return unansweredImage;
    }

    // Convert to number for comparison conditional statements.
    let alignmentNumber = Number(alignment);

    if (alignmentNumber < 0) {
      return unansweredImage;
    }

    if (alignmentNumber >= 0 && alignmentNumber <= 0.19) {
      return zeroPercentImage;
    }

    if (alignmentNumber >= 0.2 && alignmentNumber <= 0.24) {
      return twentyPercentImage;
    }

    if (alignmentNumber >= 0.25 && alignmentNumber <= 0.32) {
      return twentyFivePercentImage;
    }

    if (alignmentNumber >= 0.33 && alignmentNumber <= 0.39) {
      return thirtyThreePercentImage;
    }

    if (alignmentNumber >= 0.4 && alignmentNumber <= 0.49) {
      return fourtyPercentImage;
    }

    if (alignmentNumber >= 0.5 && alignmentNumber <= 0.59) {
      return fiftyPercentImage;
    }

    if (alignmentNumber >= 0.6 && alignmentNumber <= 0.65) {
      return sixtyPercentImage;
    }

    if (alignmentNumber >= 0.66 && alignmentNumber <= 0.74) {
      return sixtySevenPercentImage;
    }

    if (alignmentNumber >= 0.75 && alignmentNumber <= 0.79) {
      return seventyFivePercentImage;
    }

    if (alignmentNumber >= 0.8 && alignmentNumber <= 0.99) {
      return eightyPercentImage;
    }

    return oneHundredPercentImage;
  }


  /**
   * Calculate percent complete of an assessment.
   *
   * Assessment must be loaded from an organization-specific
   * endpoint so only applicable criterion_instances are included.
   *
   * **************************************************************************
   * NOTE! Percentage is based on number of _unique criteria_ vs number
   * of applicable responses. So, when a set has questions sharing criteria,
   * the value may not be exactly what's expected
   * **************************************************************************
   *
   * @param {Object} assessment Must include `criterion_instances`
   * @param {Array} responses
   *  May include responses from other sets, but must include all
   *  responses by the org for assessment.
   * @return {number|null}
   *  Returns a value between 0-1 or null if an invalid param
   *  is given.
   */
  static calculatePercentComplete(assessment: Set, responses: Response[]): number | null {
    if (!assessment || !isArray(responses) || !isArray(assessment?.criterion_instances)) {
      return null;
    }

    // Build array of unique criterion IDs in assessment.
    let criteria = map(assessment.criterion_instances, function (ci) {
      return ci.criterion_id;
    });
    criteria = uniq(criteria);

    // Build array of applicable, unique criterion IDs from responses.
    let applicableResponses = filter(responses, function (r) {
      return includes(criteria, r.criterion_id);
    });
    let applicableResponseCriteria = [];
    for (let i = 0; i < applicableResponses.length; i++) {
      let cur = applicableResponses[i];
      if (!includes(applicableResponseCriteria, cur.criterion_id)) {
        applicableResponseCriteria.push(cur.criterion_id);
      }
    }

    let qtyC = criteria.length;
    let qtyR = applicableResponseCriteria.length;
    return round(qtyR / qtyC, 2);
  }

  /**
   * Return status display text based on percent complete for an assessment
   *
   * @param {number} percentComplete Percentage as 0-1
   * @returns {Object}
   */
  static assessmentDisplayStatus(percentComplete: number): any {
    if (percentComplete === 0 || isNil(percentComplete)) {
      return {
        text: "Not Started",
        button: "Start",
      };
    }

    if (percentComplete > 0 && percentComplete < 1) {
      return {
        text: "In Progress",
        button: "Continue",
      };
    }

    if (percentComplete === 1) {
      return {
        text: "Complete",
        button: "View",
      };
    }
  }

  /**
   * Get helper text for a given field.
   *
   * For admin forms. Use the API property name for a given field (so,
   * typically snake_case values).
   *
   * DO NOT USE <p> TAGS IN THESE. Because
   * MUI will often wrap them in more.
   *
   * @param {string} fieldName
   * @returns {string|node|null}
   */
  static fieldHelpText(fieldName: string) {
    switch (fieldName) {
      case "core_module_id":
        return <>This value can only be populated after topics have been defined.</>;
      case "enable_demo":
        return (
          <>
            This activates a read-only demo of the assessment that is accessible{` `}
            to all users, including anonymous users, as long as the assessment{` `}
            and the program it belongs to are both public.
          </>
        );
      case "organization_type_id":
        return <>This value cannot be changed after an assessment has been created.</>;
      case "program_id":
        return <>This value cannot be changed after an assessment has been created.</>;
      case "public":
        return (
          <>
            Set this assessment to "public" if it should be available to{` `}
            end users. Has no impact if the program it belongs to is not{` `}
            itself public.
            <br />
            <br />
            Leave this disabled until ready for end users.
          </>
        );
      case "restricted":
        return (
          <>
            "Restricted" assessments are only available to{` `}
            organizations that have been assigned to have acces{` `}
            to it. Note that the assessment and its program must{` `}
            still both be "public" for it to visible to end users.
          </>
        );
      default:
        return null;
    }
  }

  /**
   * @param {Array|Object} sets
   * @param {Number|String|Object} organizationType
   * @return {Array}
   *  Returns array of sets sorted by name.
   */
  static filterSetsByOrganizationType(sets: Set[], organizationType: any): Set[] {
    let organizationTypeId = organizationType;
    if (!isNumber(organizationType)) {
      organizationTypeId = organizationType.id;
    }
    organizationTypeId = parseInt(organizationTypeId, 10);
    let results = filter(sets, (s) => {
      return organizationTypeId === s.organization_type_id;
    });
    return sortBy(results, "name");
  }

  /**
   * @param {Array|Object} sets
   * @param {Number|String|Object} program
   * @return Array
   *  Returns array of sets sorted by name(?)
   */
  static filterSetsByProgram(sets: Set[], program: any): Set[] {
    let programId = program;
    if (!isNumber(program)) {
      programId = program.id;
    }
    programId = parseInt(programId, 10);
    let results = filter(sets, (s) => {
      return programId === s.program_id;
    });
    return sortBy(results, "name");
  }

  /**
   * Get criterion IDs with no associated response.
   *
   * For a single organization.
   *
   * @param {array|null} questions
   *  Pool of questions to evaluate.
   * @param {array|null} responses
   *  Can include irrelevant responses as long as they're for the
   *  same organization. Note that items in the organization_responses
   *  redux store have the actual response object nested in a property
   *  called "response". This method does not unnest those, so be sure
   *  to do so before using this.
   * @returns {array}
   */
  static findAllUnansweredCriteria(questions: CriterionInstance[] | null, responses: Response[] | null): number[]|null {
    if (!questions || !responses) {
      return null;
    }

    // Loop thru questions...
    let unanswered = [];
    for (let i = 0; i < questions.length; i++) {
      let thisCriterionId = questions[i].criterion_id;
      for (let i2 = 0; i2 < responses.length; i2++) {
        let matchingResponse = find(responses, (r) => {
          return has(r, "criterion_id") && r.criterion_id === thisCriterionId;
        });
        if (!matchingResponse) {
          unanswered.push(thisCriterionId);
          break;
        }
      }
    }
    return unanswered;
  }

  /**
   * Validate downloadable set URL.
   *
   * All this currently does is check if a string is valid
   * as an absolute URL.
   *
   * @param {string} downloadUrl
   * @return {boolean}
   */
  static isValidDownloadableSetUrl(downloadUrl: string): boolean {
    // was validDownloadableSetUrl
    return isAbsoluteUrl(downloadUrl);
  }

  /**
   * Sort array of sets according to our standard set sorting algorithm.
   *
   * The sorting algorithm is to sort with the following priority:
   *
   * - Set weight       (asc)
   * - Set name         (asc)
   *
   * @param {Array} sets
   *  Array of sets to be sorted. Each set must include populated `program` object.
   * @returns {Array}
   *  Sorted sets
   */
  static sortSets(sets: Set[]): Set[] {
    return sortBy(sets, ["weight", "name"]);
  }
}

export const alignmentValueImages = SetUtils.alignmentValueImages;
export const assessmentDisplayStatus = SetUtils.assessmentDisplayStatus;
export const fieldHelpText = SetUtils.fieldHelpText;
export const filterSetsByOrganizationType = SetUtils.filterSetsByOrganizationType;
export const filterSetsByProgram = SetUtils.filterSetsByProgram;
export const findAllUnansweredCriteria = SetUtils.findAllUnansweredCriteria;
export const isValidDownloadableSetUrl = SetUtils.isValidDownloadableSetUrl;
export const calculatePercentComplete = SetUtils.calculatePercentComplete;
export const sortSets = SetUtils.sortSets;

import { useEffect, useState } from "react";
import { isArray, trim } from "lodash";
import { NavLink } from "react-router-dom";
import { Box, IconButton, ListItem, ListItemText, List, Collapse } from "@mui/material";
import ExpandMore from "@mui/icons-material/ArrowRightOutlined";
import ExpandLess from "@mui/icons-material/ArrowDropDownOutlined";
import {
  styleLink,
  styleLinkActive,
  sxList,
  sxListItem,
  sxListItemText,
} from "../_common";
import { Set } from "@/features/assessments/classes/SetModel";

const SubmenuAuthOrganizationSets = ({
  activeOrgId,
  activeSetId,
  organizationSetsArr,
  userCanViewPrioritizationCalculator,
}: {
  activeOrgId: number,
  activeSetId?: number,
  organizationSetsArr?: Set[]|null,
  userCanViewPrioritizationCalculator?: boolean,
}) => {
  const [expandedSet, setExpandedSet] = useState<number|null|undefined>(null);

  const [allowExpansion, setAllowExpansion] = useState<boolean>(false);

  useEffect(() => {
    setExpandedSet(activeSetId);
  }, [activeSetId]);

  useEffect(() => {
    // As of right now, the only possible child element is the
    // prioritization calculator. So, hide the option to expand
    // if they don't have access.
    setAllowExpansion(userCanViewPrioritizationCalculator || false);
  }, [userCanViewPrioritizationCalculator]);

  return (
    <List
      id="submenu-auth-organization-sets"
      sx={{...sxList, pb: 0, borderBottom: "none"}}
    >
      {isArray(organizationSetsArr) && organizationSetsArr.map((set: Set) => (
        <ListItem sx={{...sxListItem, pl: 2}} key={set.id}>
          <Box sx={{alignItems: "flex-start", display:"flex", mb: 1,}}>
            {allowExpansion && (
              <IconButton
                aria-label="expand"
                onClick={() => {
                  if (expandedSet === set.id) {
                    setExpandedSet(null);
                  } else {
                    setExpandedSet(set.id)
                  }
                }}
                sx={{}}
              >
                {expandedSet === set.id ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            )}
            <NavLink
              exact
              id={`submenu-auth-organization-sets-${set.id}-link`}
              to={`/app/organizations/${activeOrgId}/sets/${set.id}`}
              style={(isActive) => (isActive ? styleLinkActive : styleLink )}
            >
              <ListItemText
                sx={{ ...sxListItemText }}
                primary={`${set.name}`}
              />
            </NavLink>
          </Box>

          <Collapse in={expandedSet === set.id} timeout="auto" unmountOnExit>
            <List sx={{...sxList, borderBottom: "none"}}>

              {userCanViewPrioritizationCalculator && (
                <ListItem sx={{...sxListItem, pl: 4}}>
                  <NavLink
                    exact
                    to={`/app/organizations/${activeOrgId}/sets/${set.id}/calculator`}
                    style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                  >
                    <ListItemText
                      sx={{ ...sxListItemText, fontSize: 12 }}
                      primary="Topic Prioritization Calculator"
                    />
                  </NavLink>
                </ListItem>
              )}

              {set.download_url && trim(set.download_url).length > 0 && (
                <ListItem sx={{...sxListItem, pl: 4}}>
                  <a href={set.download_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemText
                      sx={{ ...sxListItemText, fontSize: 12 }}
                      primary="Guide"
                    />
                  </a>
                </ListItem>
              )}

              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  exact
                  to={`/app/organizations/${activeOrgId}/sets/${set.id}/glossary`}
                  style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                >
                  <ListItemText sx={{ ...sxListItemText, fontSize: 12 }} primary="Glossary" />
                </NavLink>
              </ListItem>

              <ListItem sx={{...sxListItem, pl: 4}}>
                <NavLink
                  exact
                  to={`/app/organizations/${activeOrgId}/sets/${set.id}/report`}
                  style={(isActive) => (isActive ? styleLinkActive : styleLink)}
                >
                  <ListItemText
                    sx={{ ...sxListItemText, fontSize: 12 }}
                    primary="Report"
                  />
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      ))}
    </List>
  );
};

export default SubmenuAuthOrganizationSets;

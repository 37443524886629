import { Box } from "@mui/material";

//
// View to show upon an unrecoverable error.
//
// @see also src/common/lib/errorHandler.js
function ErrorView() {

  return (
    <Box sx={{textAlign: "center", padding: 2, margin: "0 auto", maxWidth: "600px"}}>
      <p>
        <strong>Something went wrong.</strong>
      </p>
      <p>
        {/*useLoation() will not be available, so using window.location.*/}
        <a href={window.location.href}>Reload this page</a> or return to the <a href={import.meta.env.VITE_SITE_ROOT}>homepage</a>.
      </p>
    </Box>
  );
}

export default ErrorView;

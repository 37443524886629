import { get, includes, isArray } from "lodash";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import TrophyIcon from "@mui/icons-material/EmojiEventsOutlined";
import memoizee from "memoizee";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Docbuilder } from "@/features/docbuilders/classes/DocbuilderModel";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

/**
 * Static-only helper class for working with docbuilders.
 */
class DocbuilderUtils {
  /**
   * Filter an array of public docbuilders to those available to a given org.
   *
   * @param {array|object} docbuilders
   * @param {array} organization
   * @param {boolean} includeClosed
   *  If `true`, docbuilders where `closed=true` are included in results. This
   *  method relies on that property; it does _not_ calculate whether that
   *  property is still accurate. Default is `false`.
   * @returns {array}
   */
  static docbuildersForOrganization = memoizee((docbuilders, organization, includeClosed = false) => {
    let res = [];
    let dArr = [];
    if (docbuilders) {
      // Make sure we're working with an array.
      dArr = isArray(docbuilders) ? docbuilders : Object.values(docbuilders);
      // Loop thru them to find ones applicable to organization's type.
      for (const d of dArr) {
        if (includes(d.organization_types, organization.organization_type_id)) {
          // Only add public docbuilders. Omit closed docbuilders unless
          // caller requested them.
          let isClosed = get(d, "closed", false);
          if (d.public && (!isClosed || includeClosed)) {
            res.push(d);
          }
        }
      }
    }
    return res;
  });

  /**
   * Get icon component to represent a given docbuilder.
   *
   * @param {object|null} docbuilder
   * @returns {ReactElement}
   */
  static menuItemIcon = memoizee((docbuilder = null) => {
    let mn = docbuilder ? docbuilder.machine_name : null;
    switch (mn) {
      case "recognition_2022":
        return TrophyIcon;
      case "recognition_2023":
        return TrophyIcon;
      default:
        return DescriptionIcon;
    }
  });

  /**
   * Determine if a docbuilder is currently closed at time of call.
   *
   * Docbuilders from the API have a `closed` property, but that's calculated
   * by the server at the time of the request; therefore, it may become invalid.
   *
   * This method re-calculates what the `closed` property should currently be
   * based on the `closed_at` property. The new value is returned but the
   * provided docbuilder object is _not_ updated by this method.
   *
   * @param {object} docbuilder
   * @param {string|null} nowDatetimeUtc
   *  Optional parameter that allows setting what datetime is considered "now." When
   *  left as default of `null`, the current datetime is used. Otherwise, provide
   *  an ISO 8601 string that represents the desired "now" in UTC.
   * @returns {bool}
   */
  static calculateClosed(docbuilder: Docbuilder, nowDatetimeUtc: string|null = null) {
    let closedAt = get(docbuilder, "closed_at", null); // timestamp (UTC) or null
    let newVal = false;
    // Can only be closed if closed_at is set.
    if (closedAt) {
      // let nowObj = nowDatetimeUtc ? moment.utc(nowDatetimeUtc) : moment.utc();
      let nowObj = nowDatetimeUtc ? dayjs(nowDatetimeUtc).utc() : dayjs().utc();
      // let closedAtObj = moment.utc(closedAt);
      let closedAtObj = dayjs(closedAt).utc();
      // If now is after closedAt, docbuilder is closed.
      newVal = nowObj.isAfter(closedAtObj);
    }
    return newVal;
  }

  /**
   * Get milliseconds until a docbuilder is to be closed.
   *
   * @param {object} docbuilder
   * @param {number} buffer Milliseconds to be subtracted from the actual
   *  number of milliseconds before returning. This is to help keep the client-side
   *  code ahead of the server-side, so users are less likely end up submitting
   *  just after the server has closed a docbuilder.
   * @param {string|null} nowDatetimeUtc
   *  Optional parameter that allows setting what datetime is considered "now." When
   *  left as default of `null`, the current datetime is used. Otherwise, provide
   *  an ISO 8601 string that represents the desired "now" in UTC.
   * @returns {integer}
   *  If docbuilder is to close at some time in the future, the value returned
   *  will be the milliseconds until that time (minus some buffer). Otherwise:
   *  `0` is returned for already closed docbuilders, `-1` is returned for
   *  docbuilders that do not close.
   */
  static calculateTimeUntilClosed(docbuilder: Docbuilder, buffer: number = 5000, nowDatetimeUtc: string|null = null) {
    let res = -1; // default to non-closing.
    let closedAt = get(docbuilder, "closed_at", null); // timestamp (UTC) or null

    if (closedAt) {
      // let closedAtTimestamp = moment.utc(closedAt).valueOf();
      let closedAtTimestamp = dayjs(closedAt).utc().valueOf();

      // let nowObj = nowDatetimeUtc ? moment.utc(nowDatetimeUtc) : moment.utc();
      let nowObj = nowDatetimeUtc ? dayjs(nowDatetimeUtc).utc() : dayjs().utc();
      let nowTimestamp = nowObj.valueOf();

      let diff = closedAtTimestamp - buffer - nowTimestamp;
      res = diff > 0 ? diff : 0;
    }

    return res;
  }

  //
  // PT for Docbuilder vars object for an org in a docbuilder.
  // Corresponds to the payloads returned from requestDocbuilderVarsForOrg()
  //
  static propTypeShapeForVars = {
    meta: PropTypes.object.isRequired,
    system: PropTypes.object.isRequired,
  };
}

export const docbuildersForOrganization = DocbuilderUtils.docbuildersForOrganization;
export const menuItemIcon = DocbuilderUtils.menuItemIcon;
export const calculateClosed = DocbuilderUtils.calculateClosed;
export const calculateTimeUntilClosed = DocbuilderUtils.calculateTimeUntilClosed;
export const propTypeShapeForVars = DocbuilderUtils.propTypeShapeForVars;

/**
 * External URLs used throughout P2.
 *
 * NOTE: Omit protocols. Links must begin simply "//" (ex: "//sweetwater.com"). This is to
 * accommodate react-router-dom, which requires that syntax for its <Link>  component
 * (and possibly others).
 *
 * When creating links, note that all external links (including ones not defined here) must
 * include the following attribute values:
 *
 * - target="_blank"
 * - rel="noopener noreferrer"
 *
 * @var {string}
 */
export const helpFromMestUrl = "//www.healthiergeneration.org/node/6639";

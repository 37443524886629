import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Box, Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import IconAccountCircle from "@mui/icons-material/AccountCircleOutlined";
import IconExpandMore from "@mui/icons-material/ArrowRightOutlined";
import IconExpandLess from "@mui/icons-material/ArrowDropDownOutlined";
import IconHome from "@mui/icons-material/HomeOutlined";
import IconOrgs from "@mui/icons-material/AccountBalanceOutlined";
import SubmenuAuthOrganization from "@/common/components/layout/PrimaryMenu/components/SubmenuAuthOrganization";
import {
  sxList,
  sxListItem,
  sxListItemIcon,
  sxListItemText,
  styleLink,
  styleLinkActive,
} from "../_common";
import { Organization } from "@/common/classes/OrganizationModel";
import { Store } from "@/store/Store";

//
// Primary menu: Submenu for authenticated users.
//
function SubmenuAuth({
  activeOrg,
  activeSetId,
  expanded,
  showingOrgItems,
  showingOrgSetItems,
  toggleExpanded,
  toggleShowingOrgItems,
  toggleShowingOrgSets,
  userCanViewActionPlan,
  userCanViewAssessments,
  userCanViewDocbuilders,
  userCanViewPrioritizationCalculator,
  userCanViewTeam,
}: {
  activeOrg?: Organization,
  activeSetId?: number,
  expanded?: boolean,
  showingOrgItems?: boolean,
  showingOrgSetItems?: boolean,
  toggleExpanded: Function,
  toggleShowingOrgItems: Function,
  toggleShowingOrgSets: Function,
  userCanViewActionPlan?: boolean,
  userCanViewAssessments?: boolean,
  userCanViewDocbuilders?: boolean,
  userCanViewPrioritizationCalculator?: boolean,
  userCanViewTeam?: boolean,
}) {

  const currentUser = useSelector((state: Store) => state.auth.currentUser);

  if (!currentUser.isAuthenticated) {
    return null;
  }

  return (
    <>
      <List
        id="submenu-auth-user-menu"
        sx={{...sxList, mt: 2}}
      >
        <ListItem sx={sxListItem}>
          <Box sx={{mb: 1.5, alignItems: "flex-start", display:"flex"}}>
            <IconButton
              aria-label="expand"
              onClick={() => toggleExpanded(!expanded)} sx={{alignSelf: "center"}}
            >
              {expanded ? (
                <IconExpandLess />
              ) : (
                <IconExpandMore />
              )}
            </IconButton>
            <NavLink
              exact
              id="submenu-auth-dashboard-link"
              to="/app/account/dashboard"
              style={(isActive) => (isActive ? styleLinkActive : styleLink )}
            >
              <ListItemIcon sx={{ ...sxListItemIcon }}>
                <IconHome color="primary" />
              </ListItemIcon>
              <ListItemText sx={{ ...sxListItemText }} primary="Dashboard" />
            </NavLink>
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List sx={{...sxList, borderBottom: "none", mb: 0, pl: 5}}>

              <ListItem sx={sxListItem}>
                <NavLink
                  exact
                  id="submenu-auth-profile-link"
                  to="/app/account/profile"
                  style={(isActive) => (isActive ? styleLinkActive : styleLink )}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <IconAccountCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{ ...sxListItemText }} primary="My Account" />
                </NavLink>
              </ListItem>

              <ListItem sx={sxListItem}>
                <NavLink
                  exact
                  id="submenu-auth-activity-log-link"
                  to="/app/account/activity-log"
                  style={(isActive) => (isActive ? styleLinkActive : styleLink )}
                >
                  <ListItemIcon sx={{ ...sxListItemIcon }}>
                    <IconAccountCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{ ...sxListItemText }} primary="Activity Log" />
                </NavLink>
              </ListItem>

            </List>
          </Collapse>

        </ListItem>
      </List>

      <List
        id="submenu-auth-orgs-menu"
        sx={{...sxList, pb: 2.5}}
      >
       <ListItem sx={{...sxListItem, pl: 4,}}>
          <NavLink
            exact
            id="submenu-auth-organizations-link"
            to={"/app/organizations"}
            style={(isActive) => (isActive ? styleLinkActive : styleLink )}
          >
            <ListItemIcon sx={{ ...sxListItemIcon }}>
              <IconOrgs color="primary" />
            </ListItemIcon>
            <ListItemText sx={{ ...sxListItemText }} primary="My Organizations" />
          </NavLink>
        </ListItem>
      </List>

      {activeOrg && (
        <SubmenuAuthOrganization
          activeOrg={activeOrg}
          activeSetId={activeSetId}
          showingOrgItems={showingOrgItems}
          showingOrgSets={showingOrgSetItems}
          toggleShowingOrgItems={toggleShowingOrgItems}
          toggleShowingOrgSets={toggleShowingOrgSets}
          userCanViewActionPlan={userCanViewActionPlan}
          userCanViewAssessments={userCanViewAssessments}
          userCanViewDocbuilders={userCanViewDocbuilders}
          userCanViewPrioritizationCalculator={userCanViewPrioritizationCalculator}
          userCanViewTeam={userCanViewTeam}
        />
      )}
    </>
  );
}

export default SubmenuAuth;

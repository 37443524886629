import { Link } from "react-router-dom";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import logo from "@/common/images/logo-horizontal.svg";
import {
  colorBlack,
  txtFontWeightDefaultNormal,
  txtFontWeightDefaultLight,
  txtFontWeightDefaultSemibold,
  siteMinWidth,
  colorLightGray,
} from "@/style/vars.module.scss";
import { CurrentUser } from "@/common/classes/UserModel";

function Desktop({
  currentUser,
  includingPrimaryMenu,
  userFirstName,
}: {
  currentUser: CurrentUser,
  includingPrimaryMenu?: boolean,
  userFirstName: string,
}) {

  return (
    <>
      <Box className="only-print" sx={{ textAlign: "center" }}>
        <img alt="Healthier Generation" style={{ width: "180px" }} src={logo} />
      </Box>
      <Box
        className="no-print"
        sx={{
          left: 0,
          minWidth: siteMinWidth,
          position: {
            xs: "absolute",
            md: "fixed",
          },
          top: 0,
          width: "100%",
          zIndex: "appBar",
        }}
      >
        <AppBar
          position="sticky"
          color="inherit"
          role="banner"
          sx={{
            backgroundColor: "color.white",
            borderTop: "4px solid #E13F00",
            minWidth: siteMinWidth,
            padding: 0,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              minHeight: {
                md: headerHeightMd,
                lg: headerHeightLg,
              },
            }}
            variant="dense"
          >
            <Box
              id="desktop-header-home-link"
              component="a"
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                lineHeight: 0,
                margin: 0,
                textAlign: "left",
                width: "unset",
              }}
            >
              <Box
                id="desktop-header-logo"
                component="img"
                alt="Healthier Generation"
                src={logo}
                sx={{
                  marginTop: "2px",
                  maxWidth: "unset",
                  width: {
                    md: "238px",
                    lg: "320px",
                  },
                }}
              />
            </Box>

            <>
              {/* (just a divider bar) */}
              <Box
                sx={{
                  backgroundColor: `${colorLightGray}`,
                  height: "25px",
                  marginLeft: 2,
                  marginRight: 2,
                  marginTop: "2px",
                  width: "1px",
                }}
              ></Box>

              {/* SITE TITLE */}
              <Link to="/app">
                <Box
                  sx={{
                    color: colorBlack,
                    fontSize: {
                      md: 12,
                      lg: 13,
                    },
                    fontWeight: txtFontWeightDefaultSemibold,
                    marginTop: "2px",
                    textTransform: "uppercase",
                    textWrap: "nowrap",
                  }}
                >
                  Action Center
                </Box>
              </Link>
            </>

            {includingPrimaryMenu && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    height: {
                      md: headerHeightMd,
                      lg: headerHeightLg,
                    },
                    marginLeft: "auto",
                  }}
                >
                  {!currentUser.isAuthenticated && !currentUser.loading ? (
                    <>
                      {/*  DESKTOP LOGIN BUTTON */}
                      <Button
                        id="desktop-header-login-link"
                        color="primary"
                        component={Link}
                        to="/app/account/login"
                        sx={{
                          ...sxDesktopNavButton,
                        }}
                      >
                        Log in
                      </Button>
                    </>
                  ) : (
                    !currentUser.loading && (
                      <>
                        {/*  DESKTOP WELCOME, PROFILE BUTTON, LOGOUT BUTTON */}
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            id="desktop-header-welcome-text"
                            sx={{
                              fontSize: {
                                md: 12,
                                lg: 15,
                              },
                              fontWeight: txtFontWeightDefaultLight,
                              marginRight: 4,
                              verticalAlign: "middle",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "right",
                            }}
                          >
                            Welcome, {userFirstName}
                          </Box>
                          <Button
                            id="desktop-header-account-link"
                            color="primary"
                            component={Link}
                            to="/app/account/profile"
                            sx={{
                              ...sxDesktopNavButton,
                            }}
                            startIcon={<AccountCircleIcon />}
                          >
                            <Box sx={{ color: colorBlack }}>My Account</Box>
                          </Button>
                          <Button
                            id="desktop-header-logout-link"
                            color="primary"
                            component={Link}
                            to="/app/account/logout"
                            sx={{
                              ...sxDesktopNavButton,
                            }}
                            startIcon={<ExitToAppIcon />}
                          >
                            <Box sx={{ color: colorBlack }}>Log out</Box>
                          </Button>
                        </Box>
                      </>
                    )
                  )}
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

// const headerHeightXs = "48px";
const headerHeightMd = "54px";
const headerHeightLg = "62px";

const sxDesktopNavButton = {
  borderLeft: `1px solid ${colorLightGray}`,
  borderRadius: "0 !important",
  borderRight: `none`,
  fontSize: {
    md: 12,
    lg: 15,
  },
  fontWeight: txtFontWeightDefaultNormal,
  height: "100%",
  marginBottom: 0,
  marginTop: 0,
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 4,
  paddingRight: 4,
  textTransform: "none",
  textWrap: "nowrap",
};

export default Desktop;

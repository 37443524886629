import React, { useState } from "react";
import { Box, CircularProgress, Dialog, DialogTitle, DialogContent } from "@mui/material";
import DraftEditor from "@/features/rich-text/components/DraftEditor";
import { get, isEmpty, isString } from "lodash";
import { requestTerm } from "@/features/terms/api/requests";
import { colorBlue, txtFontSizeXs } from "@/style/vars.module.scss";
import errorHandler from "@/common/lib/errorHandler";

interface TermDialogDecorator {
  contentState: any;
  entityKey: string;
  readOnly: Boolean;
  children: any;
}

const termDefault = {name: '', definition: '', source: ''};

/**
 * TermDialog decorator for the DraftEditor.
 *
 * This decorates the link and provides the dialog that shows the term definition.
 * Link output is used in edit and read-only modes.
 */
const TermDialogDecorator = (props: TermDialogDecorator) => {
  const { contentState, entityKey, readOnly } = props;
  const [open, setOpen] = useState(false);
  const [term, setTerm] = useState(termDefault);
  const [termLoading, setTermLoading] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }

    getTerm();
    setOpen(!open);
    setTerm(termDefault);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isEmptyDraftObject = (draftObj: any) => {
    if (isString(draftObj)) {
      try {
        draftObj = JSON.parse(draftObj);
      } catch (e: any) {
        console.error(`Tried converting string draftObj to JSON but failed: ${e.message}`);
      }
    }

    return (isEmpty(draftObj) ||
    (draftObj.blocks.length === 1 && (draftObj.blocks[0].text === null || draftObj.blocks[0].text === "")))
  };

  /**
   * Retrieve terms from server.
   */
  const getTerm = () => {
    const { dec } = contentState.getEntity(entityKey).getData();

    setTermLoading(true);

    requestTerm(dec)
      .then((res) => {
        let term = res?.data?.data;
        if (term) {
          setTerm(term);
          setTermLoading(false);
        }
      })
      .catch((err) => {
        // ERROR
        setTerm(termDefault);
        setTermLoading(false);
        errorHandler(err, false, true);
      });
  };

  const { dec } = contentState.getEntity(entityKey).getData();

  let hasTerm = !isEmpty(term);
  let hasSource = !isEmptyDraftObject(get(term, "source", {}));
  let hasDefinition = !isEmptyDraftObject(get(term, "definition", {}));

  const wrapperSx = {
    borderBottom: `2px dashed ${colorBlue}`,
    cursor: "pointer",
  };

  if (!readOnly) {
    return (
      <Box component="span" data-term={dec} sx={wrapperSx}>
        {props.children}
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box
        component="span"
        sx={wrapperSx}
        data-term={dec}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          handleClick(e);
        }}
      >
        {props.children}
      </Box>

      <Dialog open={open} onClose={handleClose} sx={{ p: 2 }}>
        {termLoading && <CircularProgress size="2em" sx={{ m: 2 }} />}
        {hasTerm && (
          <React.Fragment>
            <DialogTitle>
              <div>{term.name}</div>
            </DialogTitle>
            <DialogContent>
              {hasDefinition && (
                <Box>
                  <DraftEditor readOnly={true} value={term.definition ? term.definition : ""} />
                </Box>
              )}
              {hasSource && (
                <Box sx={{ fontSize: txtFontSizeXs, mt: 2 }}>
                  <Box>
                    <strong>Source</strong>
                  </Box>
                  <DraftEditor readOnly={true} value={term.source ? term.source : ""} />
                </Box>
              )}
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default TermDialogDecorator;

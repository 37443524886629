import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import PageNotFound from "@/common/components/views/PageNotFound";
import HgSkeletonPage from "@/common/components/ui/atoms/HgSkeletonPage";

// Routing components.
import StaffRoute from "@/common/components/routes/StaffRoute";
import PrivateRoute from "@/common/components/routes/PrivateRoute";
import { CurrentUser } from "@/common/classes/UserModel";

// Routes by second-level path segment.
const AccountRoot = lazy(() => import("@/pages/account")); // => /app/account(/*)
// @ts-ignore
const AdminRoot = lazy(() => import("@/pages/admin")); // => /app/admin(/*)
// @ts-ignore
const DeeplinksRoot = lazy(() => import("@/pages/deeplinks")); // => /app/deeplinks(/*)
// @ts-ignore
const DeeplinkQuestion = lazy(() => import("@/pages/deeplink-question")); // => /app/deeplink-question/ID
// @ts-ignore
const DeeplinkSet = lazy(() => import("@/pages/deeplink-set")); // => /app/deeplink-set/ID
// @ts-ignore
const DemosRoot = lazy(() => import("@/pages/demos")); // => /app/demos(/*)
// @ts-ignore
const MaterialsRoot = lazy(() => import("@/pages/materials")); // => /app/materials(/*)
// @ts-ignore
const OrganizationsRoot = lazy(() => import("@/pages/organizations")); // => /app/organizations(/*)
// @ts-ignore
const ProgramsRoot = lazy(() => import("@/pages/programs")); // => /app/programs(/*)
// @ts-ignore
const ResourcesRoot = lazy(() => import("@/pages/resources")); // => /app/resources(/*)
// @ts-ignore
const SurveyPreviewsRoot = lazy(() => import("@/pages/survey-previews/detail")); // => /app/survey-previews(/*)
// @ts-ignore
const SurveyRoot = lazy(() => import("@/pages/surveys/detail")); // => /app/surveys(/*)

//
// Top-level routing for our app.
//
function AppRouter({ currentUser }: { currentUser: CurrentUser }) {
  return (
    <Switch>
      <Redirect exact from="/" to="/app" />
      <Redirect exact path="/app" to="/app/account/dashboard" />

      <Route
        path="/app/account"
        render={() => (
          <Suspense fallback={<HgSkeletonPage />}>
            <AccountRoot />
          </Suspense>
        )}
      />

      <Route
        path="/app/demos"
        render={() => (
          <Suspense fallback={<HgSkeletonPage />}>
            <DemosRoot />
          </Suspense>
        )}
      />

      <Route
        path="/app/materials/:material_id"
        render={({ match }) => (
          <Suspense fallback={<HgSkeletonPage />}>
            <MaterialsRoot materialId={match.params.material_id} />
          </Suspense>
        )}
      />

      <Route
        path="/app/organizations"
        render={() => (
          <Suspense fallback={<HgSkeletonPage />}>
            <OrganizationsRoot />
          </Suspense>
        )}
      />

      <Route
        path="/app/programs"
        render={() => (
          <Suspense fallback={<HgSkeletonPage />}>
            <ProgramsRoot />
          </Suspense>
        )}
      />

      <Route
        path="/app/resources"
        render={() => (
          <Suspense fallback={<HgSkeletonPage />}>
            <ResourcesRoot />
          </Suspense>
        )}
      />

      <Route
        path="/app/surveys/:survey_organization_uuid/:language_id?">
        <Suspense fallback={<HgSkeletonPage />}>
          <SurveyRoot />
        </Suspense>
      </Route>

      <Route path="/app/survey-previews/:survey_id/:language_id?">
        <Suspense fallback={<HgSkeletonPage />}>
          <SurveyPreviewsRoot />
        </Suspense>
      </Route>

      <Route path="/app/survey-previews">
        <PageNotFound />
      </Route>

      <PrivateRoute
        // @ts-ignore
        path="/app/deeplinks"
        currentUser={currentUser}
        render={() => (
          <Suspense fallback={<HgSkeletonPage />}>
            <DeeplinksRoot />
          </Suspense>
        )}
      />

      <PrivateRoute
        // @ts-ignore
        path="/app/deeplink-set/:set_id"
        currentUser={currentUser}
        render={({ match }: { match: any }) => (
          <Suspense fallback={<HgSkeletonPage />}>
            <DeeplinkSet setId={match.params.set_id} />
          </Suspense>
        )}
      />

      <PrivateRoute
        // @ts-ignore
        path="/app/deeplink-question/:question_id"
        currentUser={currentUser}
        render={({ match }: { match: any }) => (
          <Suspense fallback={<HgSkeletonPage />}>
            <DeeplinkQuestion questionId={match.params.question_id} />
          </Suspense>
        )}
      />

      <StaffRoute
        // @ts-ignore
        path="/app/admin"
        currentUser={currentUser}
        render={() => (
          <Suspense fallback={<HgSkeletonPage />}>
            <AdminRoot />
          </Suspense>
        )}
      />

      <Route path="/app" component={PageNotFound} />
    </Switch>
  );
}

AppRouter.propTypes = {
  currentUser: PropTypes.object.isRequired,
  programs: PropTypes.object.isRequired,
};

export default AppRouter;

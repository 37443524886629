import { ReactNode } from "react";
import { toast, Bounce } from "react-toastify";

/**
 * Wrapper for toastify notifications.
 *
 * Standardizes various parameters to reduce per-use configuration needs.
 *
 * @see src/common/lib/hgToastForPush
 *
 * @TODO Use severity colors from styleVars and refine the display in general.
 *
 * @param   {string}  message
 * @param   {string}  severity
 * @param   {Object}  callerOpts
 */
export default function hgToast(message: ReactNode, severity: string = "success", callerOpts: object = {}): void {
  const defOpts = {
    autoClose: 3500,
    hideProgressBar: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    transition: Bounce, // https://fkhadra.github.io/react-toastify/replace-default-transition/
  };
  const opts = { ...defOpts, ...callerOpts };

  let toastType;
  switch (severity) {
    case "error":
      toastType = toast.TYPE.ERROR;
      break;
    case "success":
      toastType = toast.TYPE.SUCCESS;
      break;
    case "warn":
    case "warning":
      toastType = toast.TYPE.WARNING;
      break;
    case "info":
    default:
      toastType = toast.TYPE.INFO;
      break;
  }

  toast(message, {
    ...opts,
    type: toastType,
    position: "top-right",
  });
}

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { navigateToDestination } from "@/features/prompt-on-exit/classes/PromptOnExitUtils";

/**
 * Prompt display to show prior to exiting our site for
 * on that's on THE LIST.
 *
 * @see https://mui.com/material-ui/react-dialog/
 */
function ExitPrompt({
  destination,
  isOpen,
  onCancel,
}: {
  destination: URL,
  isOpen: boolean,
  onCancel: Function,
}) {
  const handleProceedTap = () => {
    if (destination) {
      // Go to the place
      navigateToDestination(destination);
      // Close the dialog behind you
      onCancel();
    }
  };

  const handleCancelTap = () => {
    onCancel();
  };

  return (
    <Box>
      <Dialog
        open={isOpen}
        onClose={handleCancelTap}
        aria-labelledby="exitprompt-dialog-title"
        aria-describedby="exitprompt-dialog-description"
      >
        <DialogTitle id="exitprompt-dialog-title">
          You are now leaving Healthier Generation.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="exitprompt-dialog-description">
            Do you want to continue? Use "Yes" to continue and "Cancel" to remain on the Healthier
            Generation website.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProceedTap} autoFocus>
            Yes
          </Button>
          <Button onClick={handleCancelTap}>No</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

ExitPrompt.propTypes = {
  destination: PropTypes.object, // URL object
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

export default ExitPrompt;

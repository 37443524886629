import { colorLightGray, txtColorDefault } from "@/style/vars.module.scss";

//
// Common values for PrimaryMenu and children.
//
export const styleLink = {
  alignItems: "center",
  color: "black !important",
  display: "flex",
  pt: 2,
  pb: 2,
  width: "100%",
}
export const styleLinkActive = {
  ...styleLink,
  borderRight: "4px solid #D24712",
  fontWeight: "bold",
}

export const sxList = {
  borderBottom: `3px solid ${colorLightGray}`,
  display: "block",
  mt: 0,
  mr: 0,
  mb: 3,
  ml: 0,
  pb: 2,
  pt: 0,
  pl: 0,
  pr: 0,
}

export const sxListItem = {
  display: "block",
  m: 0,
  ml: 0,
  mb: 2,
  p: 0,
  // pr: 0.25,
  ["&:last-of-type"]: {
    mb: 0,
  }
}

export const sxListItemIcon = {
  alignSelf: "center",
  marginRight: 0.75,
};

export const sxListItemText = {
  color: txtColorDefault,
  fontSize: 15,
  pt: 0,
  pr: 0.5,
  pb: 0,
  pl: 0,
  textTransform: "none",
};

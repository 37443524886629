import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { txtFontWeightDefaultSemibold, txtFontSizeXs, siteMainMaxWidth } from "@/style/vars.module.scss";
import NpsSurvey2024 from "@/common/components/temp/NpsSurvey2024";
import { Store } from "@/store/Store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween)

/**
 * Action Center global footer.
 */
function Footer() {
  const currentUser = useSelector((state: Store) => state.auth.currentUser);
  const currentLocation = useLocation();

  // Whether the NPS Survey is to be visible.
  const [showingNpsSurvey, setShowingNpsSurvey] = useState(false);

  //
  // Establish whether we should show the NPS survey.
  //
  useEffect(() => {
    let newShowingNpsSurvey = false;
    // Only show to authenticated users.
    if (currentUser && currentUser.isAuthenticated) {
      // Hide on Action Plan pages.
      //
      // Example matches to regex below:
      //    /app/organizations/316136/plan
      //    /app/organizations/316136/plan/
      //    /app/organizations/316136/plan/asdfasd
      //    /app/organizations/316136/plan/asdfasd/asdfs-adsfa
      const regExForActionPlanPathname = new RegExp(/^\/app\/organizations\/[0-9]+\/plan[/]?.*$/, "i");
      newShowingNpsSurvey = !regExForActionPlanPathname.test(currentLocation.pathname);
    }

    // If not otherwise excluded, make sure it's between desired start/end dates.
    if (newShowingNpsSurvey) {
      newShowingNpsSurvey = dayjs().isBetween("2024-05-01", "2024-05-31", "day", "[]");
    }

    setShowingNpsSurvey(newShowingNpsSurvey);
  }, [currentUser, currentLocation.pathname]);

  return (
    <Box
      component="footer"
      role="contentinfo"
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 6,
        maxWidth: siteMainMaxWidth,
        right: 0,
        textAlign: "left",
        position: "relative",
        zIndex: 0,
      }}
    >
      {/* NPS embed */}
      {showingNpsSurvey && (
        <Box sx={{ display: "flex", justifyContent: "center" }} className="no-print">
          <NpsSurvey2024 />
        </Box>
      )}
      <Box sx={{ padding: 2, textAlign: "center" }}>
        <Box
          sx={{
            fontSize: txtFontSizeXs,
            fontWeight: txtFontWeightDefaultSemibold,
            paddingBottom: 3,
          }}
        >
          <Box
            sx={(theme) => ({
              display: "block",
              [theme.breakpoints.up("sm")]: {
                display: "inline",
              },
            })}
          >
            <em>Every mind, every body, every young person healthy and ready to succeed</em>
          </Box>
        </Box>

        <Box sx={{ fontSize: 11 }}>&copy; {dayjs().format("YYYY")} Alliance for a Healthier Generation</Box>
        <Box sx={{ fontSize: 11 }}>
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="https://www.healthiergeneration.org/privacy-policy"
          >
            Privacy Policy
          </a>
          &nbsp; | &nbsp;
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="https://www.healthiergeneration.org/terms-of-use"
          >
            Terms of Use
          </a>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;

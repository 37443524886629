import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { colorLightLightOrange, txtFontWeightDefaultSemibold } from "@/style/vars.module.scss";

/*
 * Style button component used for inline and block style
 */
function StyleButton({ active, label, onToggle, style }) {
  const _onToggle = useCallback(
    (e) => {
      e.preventDefault();
      onToggle(style);
    },
    [onToggle, style]
  );

  return (
    <Box
      sx={{
        backgroundColor: active ? colorLightLightOrange : "#EEEEEE",
        borderRadius: "3px !important",
        color: "black",
        borderColor: active ? colorLightLightOrange : "#CCCCCC",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
        fontFamily: "sans-serif",
        fontSize: "10px",
        fontWeight: txtFontWeightDefaultSemibold,
        letterSpacing: "normal",
        lineHeight: 1,
        margin: 0.5,
        padding: "4px 6px",
        display: "inline-block",
        "&:hover": {
          borderColor: "white",
        },
      }}
      onMouseDown={_onToggle}
    >
      {label}
    </Box>
  );
}

export default StyleButton;

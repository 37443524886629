import { handleActions } from "redux-actions";
import { has, isArray } from "lodash";
import {
  fetchOrganizationTeamStart,
  fetchOrganizationTeamSuccess,
  fetchOrganizationTeamFailure,
} from "@/store/actions";

// Top level:
// {
//    loading: 0, // qty of active requests (0 when none)
//    data: {}    // data object as described below.
// }
//
// The data object will be keyed by org ID, with each entry being an object
// with various values for the request. Actual team members are in the "team"
// property (array).
//
// {
//   10: {                    // (org #10)
//     loading: true,
//     failed: false,
//     team: [{id:20, name_first:"Fred", ...}, {id:21, name_first:"Ted", ...}]
//   },
//   11: {                    // (org #11)
//     loading: true,
//     failed: false,
//     team: [{id:77, name_first:"Jane", ...}, {id:38, name_first:"Frane", ...}]
//   },
// }
//
// Note: Requests that specify an `organization_id` are tracked as
// `data[organizationId].failed` and
// `data[organizationId].loading`.
//
// If a organization_id is logged as `failed`, that flag is removed
// at the start of the next request for it. The flag will be set
// back to true if the request fails.
//
// Bulk requests are _not_ tracked in those arrays. However, the
// results of bulk requests _will_ reset the failed and loading
// flags to false for criteria that had a response object successfully
// received.
//
const initialState = {
  data: {},
};

export default handleActions(
  {
    // Payload must contain:
    // - organization_id
    [fetchOrganizationTeamStart]: (state, { payload }) => {
      let _state = { ...state };

      // Add org entry if not yet present.
      if (!has(_state.data, payload.organization_id)) {
        _state.data[Number(payload.organization_id)] = {};
      }

      // Add/increment loading flag (top-level, not org-specific).
      if (!has(_state, "loading")) {
        _state.loading = 1;
      } else {
        _state.loading++;
      }

      return _state;
    },
    [fetchOrganizationTeamSuccess]: (state, { payload }) => {
      if (!payload?.data || !payload?.meta) {
        console.error("Missing payload property in fetchOrganizationTeamSuccess.", payload);
        return { ...state };
      }

      // -----------------
      // @TODO
      // We need the org ID but don't currently have a reliable, structured
      // place to get it. For now, extract it from the meta.path string.
      // We'll eventually add `organization_id` as a meta property.
      let path = payload.meta.path;
      let matches = path.match(/\/api\/v1\/organizations\/(\d+)\/users/);
      let orgId = Number(matches[1]);
      // -----------------

      let team = payload?.data && isArray(payload.data) ? payload.data : [];
      let newOrgProps = {
        failed: false,
        loading: false,
        team: team,
      };

      return {
        ...state,
        loading: state.loading - 1,
        data: {
          ...state.data,
          [orgId]: {
            ...newOrgProps,
          },
        },
      };
    },
    [fetchOrganizationTeamFailure]: (state, { payload }) => {
      // Not a lot we can do for a failed request, so log it to the
      // console with some debugging.
      console.error("fetchOrganizationTeamFailure", state, payload);

      // Just return a mostly unmodified copy of state.
      // @TODO Unset loading, etc
      return {
        ...state,
        loading: state.loading - 1,
      };
    },
  },
  initialState
);

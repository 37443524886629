import { all } from "redux-saga/effects";

import accountActivationSagas from "@/store/sagas/account_activation";
import appMetaSagas from "@/store/sagas/app_meta";
import authSagas from "@/store/sagas/auth";
import contentsSagas from "@/store/sagas/contents";
import changePasswordSagas from "@/store/sagas/change_password";
import forgotPasswordSagas from "@/store/sagas/forgot_password";
import organizationResponsesSagas from "@/store/sagas/organization_responses";
import organizationPlanItemsSagas from "@/store/sagas/organization_plan_items";
import organizationPlanBucketsSagas from "@/store/sagas/organization_plan_buckets";
import organizationSetsSagas from "@/store/sagas/organization_sets";
import organizationTeamSagas from "@/store/sagas/organization_team";
import programsSagas from "@/store/sagas/programs";
import registerSagas from "@/store/sagas/register";
import resetPasswordSagas from "@/store/sagas/reset_password";

export default function* rootSaga(context) {
  yield all([
    accountActivationSagas(context),
    appMetaSagas(context),
    authSagas(context),
    contentsSagas(context),
    changePasswordSagas(context),
    forgotPasswordSagas(context),
    organizationPlanItemsSagas(context),
    organizationPlanBucketsSagas(context),
    organizationResponsesSagas(context),
    organizationSetsSagas(context),
    organizationTeamSagas(context),
    programsSagas(context),
    registerSagas(context),
    resetPasswordSagas(context),
  ]);
}

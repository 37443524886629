import { appCookiesContext } from "@/common/other/appCookiesContext";
import errorHandler from "@/common/lib/errorHandler";

/**
 * Static-only helper class for auth related functionality.
 *
 * Ported from constants/authConfigs.js and elsewhere. Likely to be refactored.
 */
class AuthUtils {
  /**
   * @var {string}
   */
  static authSamlEndpointPath = "/api/auth/saml";

  // == Cookie/local storage names ==

  /**
   * Note: Axios automatically uses "XSRF-TOKEN" regardless of what we declare here.
   *
   * @var {string}
   */
  static csrfTokenCookieName = "XSRF-TOKEN";

  /**
   * Associated cookie stores timestamp of last call to API.
   *
   * @var {string}
   */
  static latestRequestLsKey = "p2lr";

  /**
   * Associated cookie stores timestamp of next-to-last call to API.
   *
   * @var {string}
   */
  static prevLatestRequestLsKey = "p2plr";

  /**
   * Associated cookie stores soft-gate data.
   *
   * @var {string}
   */
  static softGateKey = "p2sgr";

  /**
   * Associated cookie stores post register destination
   *
   * @var {string}
   */
  static registerDest = "p2regdest";

  /**
   * Associated cookie stores API auth cookie
   *
   * @var {[type]}
   */
  static auth = "__Secure-p2auth";

  /**
   * Associated cookie stores announcement bar dismissed
   *
   * @var {string}
   */
  static announcementBarDismissed = "p2_announcement_bar_dismissed";

  // =====================================

  /**
   * Get current CSRF token cookie value.
   *
   * @return {string}
   */
  static getCsrfToken() {
    let csrfTokenCookie = appCookiesContext.get(AuthUtils.csrfTokenCookieName);
    return csrfTokenCookie;
  }

  /**
   * Remove current CSRF token cookie.
   */
  static removeCsrfToken() {
    try {
      appCookiesContext.remove(AuthUtils.csrfTokenCookieName);
    } catch (err: any) {
      errorHandler(err, false, false);
    }
  }
}

export const getCsrfToken = AuthUtils.getCsrfToken;
export const removeCsrfToken = AuthUtils.removeCsrfToken;
export const authSamlEndpointPath = AuthUtils.authSamlEndpointPath;
export const csrfTokenCookieName = AuthUtils.csrfTokenCookieName;
export const latestRequestLsKey = AuthUtils.latestRequestLsKey;
export const prevLatestRequestLsKey = AuthUtils.prevLatestRequestLsKey;
export const softGateKey = AuthUtils.softGateKey;
export const registerDest = AuthUtils.registerDest;
export const announcementBarDismissed = AuthUtils.announcementBarDismissed;

export default AuthUtils; // @TODO Remove (only allow individual exports)

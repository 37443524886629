import React from "react";
import StyleButton from "@/features/rich-text/components/DraftEditor/common/StyleButton";

const InlineStyleControls = (props) => {
  let currentStyle = props.editorState.getCurrentInlineStyle();
  const { allowedHtml, classes } = props;

  return (
    <React.Fragment>
      {allowedHtml.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          classes={classes}
        />
      ))}
    </React.Fragment>
  );
};

export default InlineStyleControls;

import React from "react";
import StyleButton from "@/features/rich-text/components/DraftEditor/common/StyleButton";

/**
 * Editor control for block style buttons.
 */
const BlockStyleControls = (props) => {
  const { allowedHtml, editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <>
      {allowedHtml.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          classes={props.classes}
        />
      ))}
    </>
  );
};

export default BlockStyleControls;

import { takeEvery } from "redux-saga/effects";
import {
  fetchOrganizationPlanBuckets,
  fetchOrganizationPlanBucketsStart,
  fetchOrganizationPlanBucketsSuccess,
  fetchOrganizationPlanBucketsFailure,
} from "@/store/actions";
import { fetcherForSagas } from "@/store/fetcherForSagas";
import { requestOrganizationPlanBuckets } from "@/features/plans/api/requests";

function* organization_plan_buckets() {
  // @see https://bit.ly/43UU7c9
  yield takeEvery(
    fetchOrganizationPlanBuckets,
    fetcherForSagas({
      start: fetchOrganizationPlanBucketsStart,
      success: fetchOrganizationPlanBucketsSuccess,
      failure: fetchOrganizationPlanBucketsFailure,
      request: ({ payload }) => {
        // payload must be numeric org ID
        let orgId = Number(payload);
        let params = {};

        // Hardcode pagination values (no need to support more flexibility).
        params.page = 1;
        params.per_page = 500;

        return requestOrganizationPlanBuckets(orgId, params);
      },
    })
  );
}

export default organization_plan_buckets;

import Bugsnag from "@bugsnag/js";
import hgToast from "@/common/lib/hgToast";

/**
 * Error handler to be called in catch blocks with an Error object.
 *
 * ```
 *  import errorHandler from "@/common/lib/errorHandler"
 *  [...]
 *  try {
 *   somethingThatFails();
 *  } catch((err) => {
 *    // Call errorHandler and have it show user a custom message.
 *    errorHandler(err, false, true, true, "An error occurred");
 *  })
 * ```
 * @see https://docs.bugsnag.com/platforms/javascript/react/#reporting-handled-errors
 *
 * @param {Object} e Error object
 * @param {boolean} report Whether to report to Bugsnag
 * @param {bool} showMessageOnError If true, an hgToast is shown to user
 * @param {string|null} errorMessage Error message shown to user, if any
 * @param {any} debugging Arbitrary debug data to send to Bugsnag
 *
 */
const errorHandler = (
  e: any,
  report: boolean = true,
  showMessageOnError: boolean = true,
  errorMessage: string | null = null,
  debugging: any = null,
): void => {
  let msg = errorMessage || "An error occurred. Please reload the page to try again.";
  if (report) {
    Bugsnag.notify(e, (event) => {
      if (debugging) {
        event.addMetadata("debugging", debugging);
      }
    });
  }
  console.error(msg, e);
  if (showMessageOnError) {
    hgToast(msg, "error");
  }
};

export default errorHandler;

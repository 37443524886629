import { makeRequest } from "@/common/classes/ApiUtils";

/**
 * Get multiple Terms
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestTerms = (params: any) =>
  makeRequest({
    url: "/api/v1/terms",
    body: params,
    method: "GET",
  });

/**
 * Get single Term
 *
 * @param   {number}  termId
 * @return  {Promise}
 */
export const requestTerm = (termId: number) =>
  makeRequest({
    url: `/api/v1/terms/${termId}`,
    method: "GET",
  });

/**
 * Update a Term record.
 *
 * @param   {number}  termId
 * @param   {Object}  term
 * @return  {Promise}
 */
export const requestUpdateTerm = (termId: number, term: any) => {
  return makeRequest({
    url: `/api/v1/terms/${termId}`,
    body: term,
    method: "PUT",
  });
};

/**
 * Delete a Term record.
 *
 * @param   {number}  termId
 * @return  {Promise}
 */
export const requestDeleteTerm = (termId: number) => {
  return makeRequest({
    url: `/api/v1/terms/${termId}`,
    method: "DELETE",
  });
};

/**
 * Create a Term record.
 *
 * @param   {Object}  term
 * @return  {Promise}
 */
export const requestCreateTerm = (term: any) => {
  return makeRequest({
    url: `/api/v1/terms`,
    body: term,
    method: "POST",
  });
};

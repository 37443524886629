import { useState } from "react";
import DisplayContent from "@/features/contents/components/DisplayContent";
import ErrorIcon from "@mui/icons-material/ErrorOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Box } from "@mui/material";

function SitewideAlertBanner() {
  const [closed, setClosed] = useState(false);

  const onClose = () => {
    setClosed(true);
  };

  return (
    <Box
      id="sitewide-alert-banner"
      className="no-print"
      sx={(theme) => ({
        display: closed ? "none" : "flex",
        padding: 2,
        marginBottom: 2.5,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      })}
    >
      <Box marginRight={1.5}>
        <ErrorIcon />
      </Box>
      <Box
        sx={(theme) => ({
          "& a, & a:link, & a:visited, & a:hover, & a:active, & a:focus": {
            color: theme.palette.primary.contrastText,
            textDecoration: "underline",
          },
        })}
      >
        <DisplayContent machineName="sitewide_alert" />
      </Box>
      <Box marginLeft="auto">
        <IconButton aria-label="Close" color="inherit" onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default SitewideAlertBanner;

import memoizee from "memoizee";
import { endsWith, get, trim } from "lodash";

class PromptOnExitUtils {
  /**
   * Returns array of hosts that trigger exit prompt.
   *
   * Looks for a comma-separated string in the VITE_PROMPT_ON_EXIT_HOSTS
   * environment variable. Splits and trims it, and memoizes results.
   *
   * @returns {Array}
   */
  static exitPromptTriggerHostsArr = memoizee(() => {
    let res = [];
    let str = import.meta.env.VITE_PROMPT_ON_EXIT_HOSTS;
    if (str) {
      str = str.replace(/\s+/g, ""); // strip whitespace
      res = str.split(","); // split into array
    }
    return res;
  });

  /**
   * Apply the listener.
   *
   * @param {function} promptFn
   *  This must be a function that accepts one parameter:
   *  - {object} URL object for destination
   *
   * The function must update the ExitPrompt component
   *  instance props below:
   *
   *  - `isOpen`: promptFn must set this to `true`
   *  - `destination`: promptFn must set this to the destination URL
   *     parameter (object) it received.
   */
  static applyPromptOnExitToLinks(promptFn: Function) {
    if (document.addEventListener) {
      document.addEventListener("click", (e: any) => {
        // Get the originating anchor and parse its href.
        let anchor = e.target.closest("a");
        if (!anchor) {
          return;
        }
        let href = anchor ? get(anchor, "href", null) : null;
        if (promptFn && href) {
          let destUrl;

          // Below is wrapped with `try{}` so we can bypass invalid
          // or relative href values (URL constructor will throw).
          try {
            destUrl = new URL(href);
          } catch (e) {
            console.warn("Unable to create URL from href", e);
            return;
          }
          // If trigger, proceed with prompting.
          if (PromptOnExitUtils.urlHostTriggersExitPrompt(destUrl, PromptOnExitUtils.exitPromptTriggerHostsArr())) {
            e.preventDefault(); // Cancel the normal link execution.
            promptFn(destUrl); // Show the prompt.
          }
        }
      });
    }
  }

  /**
   * Navigate user to specified destination.
   *
   * @param {URL} dest URL object to send user to.
   */
  static navigateToDestination(dest: URL) {
    window.open(dest.href, "_blank", "noopener,noreferrer");
  }

  /**
   * Evaluate a URL as one that triggers exit prompt or not.
   *
   * @param {URL} destUrl Destination URL object (from href, etc)
   * @param {string} triggerList List of triggering hosts (as defined in env)
   * @returns {bool}
   *  Returns true if destUrl triggers exit prompt.
   */
  static urlHostTriggersExitPrompt(destUrl: URL, triggerList: string) {
    let destHost,
      isTriggerHost = false;

    destHost = destUrl ? trim(get(destUrl, "hostname", "")) : "";
    destHost = destHost.toString().toLowerCase();

    // Compare destination w/trigger list
    if (destHost && destHost.length) {
      for (let i = 0; i < triggerList.length; i++) {
        let a = triggerList[i].toLowerCase(); // current trigger list item
        if (
          destHost === a || // exact match
          endsWith(destHost, `.${a}`) // subdomain match
        ) {
          isTriggerHost = true;
          break;
        }
      }
    }
    return isTriggerHost;
  }
}

export const applyPromptOnExitToLinks = PromptOnExitUtils.applyPromptOnExitToLinks;
export const exitPromptTriggerHostsArr = PromptOnExitUtils.exitPromptTriggerHostsArr;
export const navigateToDestination = PromptOnExitUtils.navigateToDestination;
export const urlHostTriggersExitPrompt = PromptOnExitUtils.urlHostTriggersExitPrompt;

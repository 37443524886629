import { useEffect, useState } from "react";
import { get } from "lodash";
import useIsWidthDown from "@/common/hooks/useIsWidthDown";
import Desktop from "@/common/components/layout/Header/Desktop";
import Mobile from "@/common/components/layout/Header/Mobile";
import { CurrentUser } from "@/common/classes/UserModel";

function Header({
  currentUser,
  includingPrimaryMenu,
  togglePrimaryMenuExpandedAtMobileRes,
}: {
  currentUser: CurrentUser,
  includingPrimaryMenu?: boolean,
  togglePrimaryMenuExpandedAtMobileRes: Function,
}) {

  const isSmallDevice = useIsWidthDown("md");
  const [nameFirst, setNameFirst] = useState<string>("");

  useEffect(() => {
    let newNameFirst = "";
    if (currentUser) {
      newNameFirst = get(currentUser, "data.name_first", "");
    }
    setNameFirst(newNameFirst);
  }, [currentUser]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      {isSmallDevice ? (
        <Mobile
          currentUser={currentUser}
          includingPrimaryMenu={includingPrimaryMenu}
          togglePrimaryMenuExpandedAtMobileRes={togglePrimaryMenuExpandedAtMobileRes}
          userFirstName={nameFirst}
        />
      ) : (
        <Desktop currentUser={currentUser} includingPrimaryMenu={includingPrimaryMenu} userFirstName={nameFirst} />
      )}
    </>
  );
}

export default Header;

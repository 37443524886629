import { makeRequest } from "@/common/classes/ApiUtils";
import { isArray } from "lodash";
import { User } from "../classes/UserModel";

/**
 * API requests
 *
 * Using a local instance of programs-api, endpoints are documented at
 * `{api_host}/docs`. Those docs describe the required and optional parameters
 * of each endpoint, as well as the various status codes that you'll need to
 * evaluate when parsing a response.
 *
 * Note: Our custom libraries contain additional library-specific request
 * definitions in their directories at `src/libs/{lib}/requests.js`.
 *
 * Example method usage:
 *
 * ```
 * import { requestPrograms } from `api/requests.js'
 *
 * requestPrograms().then(res => {
 *   console.log(res);
 * });
 * ```
 */

/**
 * Create a team member invitation.
 *
 * @param   {Object}  payload
 * @return  {Promise}
 */
export const requestCreateInvitation = (payload: object) => {
  return makeRequest({
    url: `/api/v1/users/invite-team-member`,
    body: payload,
    method: "POST",
  });
};

/**
 * Resend a team member invitation.
 *
 * @param   {Object}  payload
 * @return  {Promise}
 */
export const requestResendInvitation = (payload: object) => {
  return makeRequest({
    url: `/api/v1/users/resend-team-member-invite`,
    body: payload,
    method: "POST",
  });
};

/**
 * Create a User record.
 *
 * @param   {object}  user
 * @return  {Promise}
 */
export const requestCreateUser = (user: User) => {
  return makeRequest({
    url: `/api/v1/users`,
    body: user,
    method: "POST",
  });
};

// ----------------------
// Updates (success: 200 or 201 [@TODO Standardize])
// ----------------------

/**
 * Update a User record.
 *
 * @param   {Object}  data
 * @return  {Promise}
 */
export const requestUpdateUser = (data: User) => {
  return makeRequest({
    url: `/api/v1/users/${data.id}`,
    body: data,
    method: "PUT",
  });
};

/**
 * Get all StaffActions.
 *
 * Requester must be an admin or staff with "edit_all_users" or "view_all_users".
 *
 * @return  {Promise}
 */
export const requestStaffActions = () =>
  makeRequest({
    url: `/api/v1/staff-actions`,
    body: null,
    method: "GET",
  });

/**
 * Set the StaffActions associated with a "Staff" user.
 *
 * Requester must be an admin or staff with "edit_all_users".
 *
 * @param {number} userId
 * @param {Array} staffActions
 *  Complete array of StaffAction machine names to be assigned
 *  to the user. Any StaffActions assigned to the user in the back-end
 *  but not present in this array will be removed.
 * @return  {Promise}
 */
export const requestUpdateUserStaffActions = (userId: number, staffActions: string[]) =>
  makeRequest({
    url: `/api/v1/users/${userId}/staff-actions`,
    body: { staff_actions: staffActions },
    method: "PUT",
  });

/**
 * Remove a single StaffAction from a "Staff" user.
 *
 * Requester must be an admin or staff with "edit_all_users".
 *
 * @param {number} userId
 * @param {string} staffAction Machine name of action to remove.
 * @return  {Promise}
 */
export const requestUnlinkUserStaffAction = (userId: number, staffAction: string[]) =>
  makeRequest({
    url: `/api/v1/users/${userId}/staff-actions/${staffAction}`,
    body: null,
    method: "DELETE",
  });

/**
 * Add a single StaffAction to a "Staff" user.
 *
 * Requester must be an admin or staff with "edit_all_users".
 *
 * @param {number} userId
 * @param {string} staffAction Machine name of action to add.
 * @return  {Promise}
 */
export const requestLinkUserStaffAction = (userId: number, staffAction: string[]) =>
  makeRequest({
    url: `/api/v1/users/${userId}/staff-actions/${staffAction}`,
    body: null,
    method: "PUT",
  });

/**
 * Update pivot data in a User/Organization relationship.
 *
 * @param   {number}  userId
 * @param   {number}  organizationId
 * @param   {Object}  payload
 * @return  {Promise}
 */
export const requestUpdateUserOrganization = (userId: number, organizationId: number, payload: object) =>
  makeRequest({
    url: `/api/v1/users/${userId}/organizations/${organizationId}`,
    body: payload,
    method: "PUT",
  });

/**
 * Approve a User request to join an Organization.
 *
 * @param   {number}  userId
 * @param   {number}  organizationId
 * @return  {Promise}
 */
export const requestApproveUserOrganizationRequest = (userId: number, organizationId: number) =>
  makeRequest({
    url: `/api/v1/users/${userId}/organizations/${organizationId}/approve`,
    body: null,
    method: "PUT",
  });

/**
 * Deny a User request to join an Organization.
 *
 * @param   {number}  userId
 * @param   {number}  organizationId
 * @return  {Promise}
 */
export const requestDenyUserOrganizationRequest = (userId: number, organizationId: number) =>
  makeRequest({
    url: `/api/v1/users/${userId}/organizations/${organizationId}/deny`,
    body: null,
    method: "PUT",
  });

/**
 * Associate user with an organization.
 *
 * @param   {number}  userId
 * @param   {Object}  payload
 * @return  {Promise}           [return description]
 */
export const requestLinkUserOrganization = (userId: number, payload: object) =>
  makeRequest({
    url: `/api/v1/users/${userId}/organizations`,
    body: payload,
    method: "POST",
  });

/**
 * Associate a User with an Organization.
 *
 * @param   {number}  userId
 * @param   {number}  organizationId
 * @param   {number}  organizationRoleId
 * @param   {number}  userFunctionId
 * @return  {Promise}
 */
export const requestLinkOrganizationUser = (userId: number, organizationId: number, organizationRoleId: number, userFunctionId: number|null = null) => {
  return makeRequest({
    url: `/api/v1/users/${userId}/organizations`,
    body: {
      user_id: userId,
      organization_id: organizationId,
      organization_role_id: organizationRoleId,
      user_function_id: userFunctionId,
    },
    method: "POST",
  });
};

// ----------------------------------------------------
// M2M relationship UNlinking (success is usually: 204)
// ----------------------------------------------------

/**
 * Disassociate a User from an Organization.
 *
 * @param   {number}  userId
 * @param   {number}  organizationId
 * @return  {Promise}                  [return description]
 */
export const requestUnlinkUserOrganization = (userId: number, organizationId: number) =>
  makeRequest({
    url: `/api/v1/users/${userId}/organizations/${organizationId}`,
    method: "DELETE",
  });

// -------
// Deletes
// -------

/**
 * Delete a User record.
 *
 * @param   {number}  userId
 * @return  {Promise}
 */
export const requestDeleteUser = (userId: number) =>
  makeRequest({
    url: `/api/v1/users/${userId}`,
    method: "DELETE",
  });

// --------------
// GET Retrievals
// --------------

/**
 * Get admin metrics.
 */
export const requestMetrics = () =>
  makeRequest({
    url: `/api/v1/dashboard/statistics`,
    method: "GET",
    body: null,
  });

/**
 * Get response log for an organization/criterion.
 *
 * @param   {number}  organizationId
 * @param   {number}  criterionId
 * @param   {number}  maxItems
 * @return  {Promise}
 */
export const requestOrganizationCriterionResponseLog = (organizationId: number, criterionId: number, maxItems: number = 100) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/response-log/criteria/${criterionId}`,
    method: "GET",
    body: {
      per_page: maxItems,
    },
  });

/**
 * Get AppMeta structure (includes multiple models).
 *
 * @return  {Promise}
 */
export const requestAppMeta = () =>
  makeRequest({
    url: `/api/v1/app/meta`,
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Get all GradeLevel records.
 *
 * Note: Also available in redux under app_meta.
 *
 * @return  {[type]}  [return description]
 */
export const requestGradeLevels = () =>
  makeRequest({
    url: `/api/v1/grade-levels`,
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Get an Invitation record based on token, email
 *
 * @param   {string}  inviteToken
 * @param   {string}  email
 * @return  {Promise}
 */
export const requestInvitation = (inviteToken: string, email: string) => {
  return makeRequest({
    url: `/api/v1/users/invite-team-member`,
    body: { email: email, token: inviteToken },
    method: "GET",
  });
};

/**
 * Get a single Organization record.
 *
 * @param   {number}  organizationId
 * @return  {Promise}
 */
export const requestOrganization = (organizationId: number) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}`,
    method: "GET",
  });

/**
 * Get Programs an Organization is eligible for.
 *
 * Previously served Programs an Organization was "enrolled" in, but there is
 * no longer a direct M2M relationship between orgs and progs.
 *
 * @param   {number}  organizationId
 * @param   {object}  params
 * @return  {Promise}
 */
export const requestOrganizationPrograms = (organizationId: number, params: object) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/programs`,
    body: params,
    method: "GET",
  });

/**
 * Get all OrganizationRole records.
 *
 * Note: Also available in redux under app_meta.
 *
 * @return  {Promise}
 */
export const requestOrganizationRoles = () =>
  makeRequest({
    url: "/api/v1/organization-roles",
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Get multiple Organization records.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestOrganizations = (params: object) =>
  makeRequest({
    url: `/api/v1/organizations`,
    body: params,
    method: "GET",
  });

/**
 * Get all OrganizationType records.
 *
 * Note: Also available in redux under app_meta.
 *
 * @return  {Promise}  [return description]
 */
export const requestOrganizationTypes = () =>
  makeRequest({
    url: "/api/v1/organization-types",
    method: "GET",
  });

/**
 * Get Organizations of a given OrganizationType.
 *
 * @param   {number}  organizationTypeId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestOrganizationTypeOrganizations = (organizationTypeId: number, params: object) =>
  makeRequest({
    url: `/api/v1/organization-types/${organizationTypeId}/organizations`,
    body: params,
    method: "GET",
  });

/**
 * Get Users associated with an Organization.
 *
 * @param   {number}  organizationId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestOrganizationUsers = (organizationId: number, params?: object) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/users`,
    body: params,
    method: "GET",
    redirectOnAuthFailure: false, // not unexpected for a user to not have access
  });

/**
 * Get Users that are pending approval for a specific Organization.
 *
 * @param   {number}  organizationId
 * @param   {object}  params
 * @return  {Promise}
 */
export const requestOrganizationUsersPending = (organizationId: number, params: object) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/pending-users`,
    body: params,
    method: "GET",
    redirectOnAuthFailure: false, // not unexpected for a user to not have access
  });

/**
 * Get Users that are pending approval for any Organization.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestUsersPending = (params: object) =>
  makeRequest({
    url: `/api/v1/users/organization-pending`,
    body: params,
    method: "GET",
  });

/**
 * Get Users that have pending invites for all organizations.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestUsersInvitesPending = (params: object) =>
  makeRequest({
    url: `/api/v1/users/invite-pending`,
    body: params,
    method: "GET",
  });

/**
 * Get multiple Program records.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestPrograms = (params: object) =>
  makeRequest({
    url: "/api/v1/programs",
    body: params,
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Get single Program record that current user has access to.
 *
 * @param   {number}  programId
 * @return  {Promise}
 */
export const requestProgram = (programId: number) =>
  makeRequest({
    url: `/api/v1/programs/${programId}`,
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Returns curated Set status data for a single Organization
 *
 * ...in the context of a given Program. It's not a standard P2 model
 * or collection of models.
 *
 * @deprecated
 *
 * @see utils/parseOrgProgData.js
 *
 * @param   {number}  programId
 * @param   {number}  organizationId
 *
 * @return  {Promise}
 */
export const requestOrgProgData = (programId: number, organizationId: number) =>
  makeRequest({
    url: `/api/v1/programs/${programId}/organizations/${organizationId}`,
    method: "GET",
  });

/**
 * Get Organizations eligible for a Program.
 *
 * This previously provided Organizations that were associated with a Program,
 * but that relationship is no longer in use. It now provides Organizations that
 * are eligible for the Program based on the OrganizationType.
 *
 * @param   {number}  programId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestProgramOrganizations = (programId: number, params: object) =>
  makeRequest({
    url: `/api/v1/programs/${programId}/organizations`,
    body: params,
    method: "GET",
  });

/**
 * Get the OrganizationTypes specified by a Program.
 *
 * @param   {number}  programId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestProgramOrganizationTypes = (programId: number, params: object) =>
  makeRequest({
    url: `/api/v1/programs/${programId}/organization-types`,
    body: params,
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Get Organizations in a US State.
 *
 * State IDs are the 2-character abbreviations, lowercase.
 *
 * @param   {number}  stateId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestStateOrganizations = (stateId: string, params: object) =>
  makeRequest({
    url: `/api/v1/states/${stateId}/organizations`,
    body: params,
    method: "GET",
  });

/**
 * Get all SystemRoles records.
 *
 * Also availble in app_meta.
 *
 * @return  {Promise}
 */
export const requestSystemRoles = () =>
  makeRequest({
    url: "/api/v1/system-roles",
    method: "GET",
  });

/**
 * Get all UserFunctionCategory records.
 *
 * Also availble in app_meta.
 *
 * @return  {Promise}
 */
export const requestUserFunctionCategories = () =>
  makeRequest({
    url: "/api/v1/user-function-categories",
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Get all UserFunction records.
 *
 * Also availble in app_meta.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestUserFunctions = (params: object) =>
  makeRequest({
    url: "/api/v1/user-functions",
    body: params,
    method: "GET",
    redirectOnAuthFailure: false,
  });

/**
 * Get all Organizations a user is associated with.
 *
 * @param   {number}  userId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestUserOrganizations = (userId: number, params: object) =>
  makeRequest({
    url: `/api/v1/users/${userId}/organizations`,
    body: params,
    method: "GET",
  });

/**
 * Get the API-calculated "default" Organization for the current user (if any).
 *
 * @param   {Array}  arrayOfTypeIds
 * @return  {Promise}
 */
export const requestDefaultUserOrganization = (arrayOfTypeIds: number[]) => {
  let params: any = {};
  if (arrayOfTypeIds && isArray(arrayOfTypeIds) && arrayOfTypeIds.length > 0) {
    params.organization_type_ids = arrayOfTypeIds;
  }
  return makeRequest({
    url: `/api/v1/users/default-organization`,
    body: params,
    method: "GET",
  });
};

/**
 * Get multiple User records.
 *
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestUsers = (params: object) =>
  makeRequest({
    url: "/api/v1/users",
    body: params,
    method: "GET",
  });

/**
 * Get single User record.
 *
 * @param   {number}  userId
 * @return  {Promise}
 */
export const requestUser = (userId: number) =>
  makeRequest({
    url: `/api/v1/users/${userId}`,
    method: "GET",
  });

/**
 * Request a password reset link to be sent via email.
 *
 * @param {string} email
 * @return  {Promise} Note that success response status is 202.
 */
export const requestPasswordResetForEmail = (email: string) => {
  return makeRequest({
    url: "/api/password/email",
    body: { email },
    method: "POST",
  });
};

/**
 * Change password afer a password reset email.
 *
 * @see requestPasswordResetForEmail()
 *
 * @param {Object} payload
 *  Must have the following properties:
 *  - "email": User email
 *  - "password": New password
 *  - "password_confirmation": New password
 *  - "token": Token string from the reset email sent to user.
 * @return {Promise} Note that success response status is 204.
 */
export const requestPasswordResetChangePassword = (payload: object) => {
  return makeRequest({
    url: "/api/password/reset",
    body: payload,
    method: "POST",
  });
};

/**
 * Change password for current user. (only valid for current user)
 *
 * @param  {Number} userId ID to change pw for
 * @param  {Object} payload Ex: `{password:'abc123', password_confirmation:'abc123'}`
 * @returns {Promise} Success response status is 200.
 */
export const requestChangeOwnPassword = (userId: number, payload: object) => {
  return makeRequest({
    url: `/api/v1/users/${userId}/change-password`,
    body: payload,
    method: "POST",
  });
};

/**
 * Deactivate current user account.
 *
 * @return  {Promise} Status 204 on success.
 */
export const requestDeactivateSelf = () => {
  return makeRequest({
    url: "/api/v1/account/deactivate",
    method: "GET",
  });
};

/**
 * Request reactivation of user by email.
 *
 * @param   {string}  email
 * @return  {Promise} Status 202 on success.
 */
export const requestReactivateUser = (email: string) => {
  return makeRequest({
    url: "/api/account/reactivate",
    body: { email },
    method: "POST",
  });
};

/**
 * Request login via provided creds.
 *
 * @param   {Object}  creds
 * @return  {Promise} Status 200 on success.
 */
export const requestLogin = (creds: object) => {
  return makeRequest({
    url: "/api/auth/login",
    body: creds,
    method: "POST",
    redirectOnAuthFailure: false,
  });
};

/**
 * Request logout of current user.
 *
 * @param   {Object}  args
 * @return  {Promise} Status 200 on success, 401 if user is already logged out.
 */
export const requestLogout = (args: object) => {
  return makeRequest({
    url: "/api/auth/logout",
    body: args,
    method: "GET",
    // Disable redirect on auth failure because API may return a 4xx
    // if user is already logged-out.
    redirectOnAuthFailure: false,
  });
};

/**
 * Request API set CSRF cookie.
 *
 * @return  {Promise}
 */
export const requestCsrfCookie = () => {
  // Endpoint returns 200 on success.
  return makeRequest({
    url: "/sanctum/csrf-cookie",
    method: "GET",
    redirectOnAuthFailure: false,
  });
};

/**
 * Request API refresh authentication of current user.
 *
 * @return  {Promise} Status 200 on success.
 */
export const requestAuthRefresh = () => {
  return makeRequest({
    url: "/api/auth/refresh",
    method: "POST",
    redirectOnAuthFailure: false,
  });
};

/**
 * Request info for current user.
 *
 * @return  {Promise} Status 200 on success
 */
export const requestSelf = () => {
  return makeRequest({
    url: "/api/auth/me",
    method: "GET",
    throwOnFailure: "notauth", // expected to have auth fail sometimes
    redirectOnAuthFailure: false,
  });
};

/**
 * Request new user registration
 *
 * @param   {Object}  regData
 * @return  {Promise}
 */
export const requestRegister = (regData: object) => {
  // Endpoint returns 202 on success
  return makeRequest({
    url: "/api/auth/register",
    body: regData,
    method: "POST",
    redirectOnAuthFailure: false,
  });
};

/**
 * Verify an email account verification token.
 *
 * @param   {string}  vToken
 * @return  {Promise}
 *  Statuses:
 *  - 200 Verified (first time for this token)
 *  - 204 Token was previously verified
 *  - 422 for failure to verify token
 */
export const requestProcessEmailVerificationToken = (vToken: string) => {
  return makeRequest({
    url: `/api/email/verify/${vToken}`,
    method: "GET",
    redirectOnAuthFailure: false,
  });
};

/**
 * Request to resent verification email
 *
 * @param   {string}  email
 * @return  {Promise}
 *  Statuses
 *  - 200 Will resend
 *  - 422 Email already verified
 */
export const requestResendEmailVerificationMessage = (email: string) => {
  return makeRequest({
    url: `/api/email/resend`,
    body: { email },
    method: "POST",
    redirectOnAuthFailure: false,
  });
};

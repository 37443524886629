import { makeRequest } from "@/common/classes/ApiUtils";
import { currentWebsocketId } from "@/common/classes/ApiUtils";
import { forEach } from "lodash";
import isNumeric from "@/common/lib/isNumeric";

/**
 * Create an Action Plan Item.
 *
 * Requires that payload.organization_id be provided. If
 * payload.plan_id is provided, the item will go there
 * (assuming it's a valid destination). Otherwise, the default
 * plan for organization will be used.
 *
 * Provide criterion_id for criterion-based items (all as of
 * this writing).
 *
 * @param {Object} payload
 * @return {Promise}
 */
export const requestCreatePlanItem = (payload) => {
  if (!payload.organization_id) {
    console.error("Invalid payload in requestCreatePlanItem");
    return;
  }
  // Add socketId to payload if websocket is available.
  payload.socket_id = currentWebsocketId() || null;
  return makeRequest({
    url: `/api/v1/plan-items`,
    body: payload,
    method: "POST",
  });
};

/**
 * Create multiple Organization Action Plan Items.
 *
 * @param {Object} payload
 * @return {Promise}
 */
export const requestCreatePlanItems = (payload) => {
  // Add socketId to payload if websocket is available.
  payload.socket_id = currentWebsocketId() || null;
  return makeRequest({
    url: `/api/v1/plan-items-bulk`,
    body: payload,
    method: "POST",
  });
};

/**
 * Update an Organization Action Plan Item.
 *
 * @param   {Object}  planItemObj
 * @return  {Promise}
 */
export const requestUpdatePlanItem = (planItemObj) => {
  let payload = planItemObj;
  payload.socket_id = currentWebsocketId() || null;
  return makeRequest({
    url: `/api/v1/plan-items/${planItemObj.id}`,
    body: payload,
    method: "PUT",
  });
};

/**
 * Set the assignee (owner_id) of a plan item.
 *
 * @param   {number}  planItemId
 * @param   {number}  newOwnerId
 * @return  {Promise}
 */
export const requestUpdatePlanItemOwner = (planItemId, newOwnerId = null) => {
  let payload = {
    id: planItemId,
    owner_id: newOwnerId,
    socket_id: currentWebsocketId() || null,
  };
  makeRequest({
    url: `/api/v1/plan-items/${planItemId}`,
    body: payload,
    method: "PUT",
  });
};

/**
 * Update items in a bucket.
 *
 * Must include _all_ items that are to remain in bucket (not just the new
 * ones). Sorting is applied to the provided items based on the order of
 * the provided `items` array. We translate that into a corresponding weight
 * property on each item.
 *
 * @param {number}  organizationId
 * @param {number}  bucketId
 * @param {Array}   items
 * @return {Promise}
 */
export const requestUpdatePlanBucketItems = (organizationId, bucketId, items = []) => {
  // Convert provided items array to the meta format required by API
  // and adjust/force certain values.
  let sending = [];
  forEach(items, (item, itemIdx) => {
    sending.push({
      plan_item_id: item.id,
      weight: itemIdx + 1,
    });
  });

  if (!bucketId || !isNumeric(bucketId)) {
    bucketId = 0;
  }
  bucketId = Number(bucketId);

  let socketId = currentWebsocketId() || "";

  return makeRequest({
    url: `/api/v1/organizations/${organizationId}/plans/current/buckets/${bucketId}/items`,
    body: {
      plan_bucket_items: sending,
      socket_id: socketId,
    },
    method: "PUT",
  });
};

/**
 * Delete an Action Plan Item record.
 *
 * @param   {number}  planItemId
 * @return  {Promise}
 */
export const requestDeletePlanItem = (planItemId) => {
  let socketId = currentWebsocketId(); // Include socket id if websocket is available.
  let queries = "";
  if (socketId) {
    queries = `socket_id=${socketId}`;
  }
  return makeRequest({
    url: `/api/v1/plan-items/${planItemId}?${queries}`,
    method: "DELETE",
  });
};

/**
 * Get current Action Plan for an Organization.
 *
 * @param   {number}  organizationId
 * @return  {Promise}
 */
export const requestOrganizationPlan = (organizationId) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/plans/current`,
    method: "GET",
    redirectOnAuthFailure: false, // not unexpected for a user to not have access
  });

/**
 * Get a specific plan item.
 *
 * @param   {Object}  planItem
 * @return  {Promise}
 */
export const requestPlanItem = (planItem) =>
  makeRequest({
    url: `/api/v1/plan-items/${planItem}`,
    body: null,
    method: "GET",
  });

/**
 * Get Buckets for the current Action Plan for an Organization.
 *
 * @param   {number}  organizationId
 * @return  {Promise}
 */
export const requestOrganizationPlanBuckets = (organizationId) =>
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/plans/current/buckets`,
    method: "GET",
  });

/**
 * Get Items for the current Action Plan for an Organization.
 *
 * @param   {number}  organizationId
 * @param   {Object}  params
 * @return  {Promise}
 */
export const requestOrganizationPlanItems = (organizationId, params = {}) =>
  // Note: results can be filtered by bucket. Use plan_bucket_id:'-1'" to
  // filter by the no bucket.
  makeRequest({
    url: `/api/v1/organizations/${organizationId}/plans/current/plan-items`,
    body: params,
    method: "GET",
    redirectOnAuthFailure: false, // not unexpected for a user to not have access
  });

/**
 * Get buckets for an action plan.
 * @see requestOrganizationPlanBuckets()
 *
 * @param   {number}  planId
 * @return  {Promise}
 */
export const requestPlanBuckets = (planId) =>
  makeRequest({
    url: `/api/v1/plans/${planId}/buckets`,
    method: "GET",
  });

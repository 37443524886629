import * as styleVars from "@/style/vars.module.scss";
import { createTheme } from "@mui/material/styles";
import remToPxNumber from "@/style/utils/remToPxNumber";
import sp from "@/style/utils/sp";

let muiTheme = createTheme({

  // ===========
  // BREAKPOINTS
  // ===========
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  typography: {
    fontFamily: `${styleVars.txtFontFamilyDefault}`,
  },
  // =======
  // PALETTE
  // =======
  palette: {
    // @see https://material-ui.com/customization/palette/
    // @see https://mui.com/customization/default-theme/

    // --
    // COLOR-RELATED SETTINGS
    mode: "light",
    contrastThreshold: 3, // default: 3
    tonalOffset: 0.2, // default: 0.2
    // --
    // COLOR BASICS
    background: {
      paper: styleVars.colorWhite,
      default: styleVars.colorOffWhite,
    },
    common: {
      black: styleVars.colorBlack,
      white: styleVars.colorWhite,
    },
    // grey: {
    //   // Leaving empty to allow defaults for now. But FYI, we do
    //   // have some specific grays specified in styleVars.
    // },
    // --
    // PRIMARY/SECONDARY
    primary: {
      main: styleVars.colorPrimary,
      // lighter: @TODO
      light: styleVars.colorPrimaryLight,
      dark: styleVars.colorPrimaryDark,
      // darker: @TODO
      contrastText: styleVars.colorWhite,
    },
    secondary: {
      main: styleVars.colorSecondary,
      // lighter: @TODO
      light: styleVars.colorSecondaryLight,
      dark: styleVars.colorSecondaryDark,
      // darker: @TODO
      contrastText: styleVars.colorWhite,
    },
    // --
    // SEVERITIES
    error: {
      main: styleVars.colorStatusError,
      light: styleVars.colorStatusErrorLight,
      dark: styleVars.colorStatusErrorDark,
      contrastText: styleVars.colorStatusErrorContrast,
    },
    warning: {
      main: styleVars.colorStatusWarning,
      light: styleVars.colorStatusWarningLight,
      dark: styleVars.colorStatusWarningDark,
      contrastText: styleVars.colorStatusWarningContrast,
    },
    info: {
      main: styleVars.colorStatusInfo,
      light: styleVars.colorStatusInfoLight,
      dark: styleVars.colorStatusInfoDark,
      contrastText: styleVars.colorStatusInfoContrast,
    },
    success: {
      main: styleVars.colorStatusSuccess,
      light: styleVars.colorStatusSuccessLight,
      dark: styleVars.colorStatusSuccessDark,
      contrastText: styleVars.colorStatusSuccessContrast,
    },
  },

  // =====
  // SHAPE
  // =====
  shape: {
    borderRadius: 4,
  },
});

// Define a new theme where we can use dynamically set properties
// from above (i.e. `muiTheme.whatever`).
muiTheme = createTheme(muiTheme, {
  // ==========
  // TYPOGRAPHY
  // ==========
  typography: {
    // ********************** Note! ***************************************
    // We avoid MUI's <Typography> component and prefer instead to use and
    // style semantic HTML tags where possible. A primary benefit of this
    // is that non-MUI code and components an be used without more easily.
    // However, some MUI components may use Typography internally, so
    // we populate the values below using the same variables we apply to
    // non-MUI tags.
    // ********************************************************************

    htmlFontSize: 16, // MUI default value: 16 (default is eval'd as px, but no unit suffix)
    fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
    fontFamily: styleVars.txtFontFamilyDefault,
    fontWeightLight: styleVars.txtFontWeightDefaultLight,
    fontWeightRegular: styleVars.txtFontWeightDefaultNormal,
    fontWeightMedium: styleVars.txtFontWeightDefaultSemibold,
    fontWeightBold: styleVars.txtFontWeightDefaultSemibold,

    // ================
    // H* Header styles
    // ----------------
    // Note: As with most semantic HTML styles, don't use H* tags (including
    // via Typography variants) just to achieve styling that resembles one of
    // these. Instead, use the appropriate semantic HTML tags, and if  really
    // needed, use one-off styles applied to the semantic tags.
    // However, you could apply these styles to component-specific markup
    // via the `styles` object using `muiTheme.typography.{tag}.{prop}` if you
    // really had a need to align the styles.
    // ================
    h1: {
      // About H1: We use H1 for the primary title of the individual page
      // being viewed, and no where else. We don't use it for the site title
      // in the app bar (as some sites/systems do).
      // -----------------------------------------------------------------
      color: styleVars.txtColorH1,
      // fontFamily: styleVars.txtFontFamilyH1,
      fontSize: styleVars.txtFontSizeH1, //  26px
      fontVariant: styleVars.txtFontVariantH1,
      fontWeight: styleVars.txtFontWeightH1,
      lineHeight: styleVars.txtLineHeightH1,
      letterSpacing: styleVars.txtLetterSpacingH1,
      margin: `0 0 ${styleVars.txtMarginBottomH1}`,
      textTransform: styleVars.txtTextTransformH1,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeH1BpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeH1BpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeH1BpLg,
      },
    },
    h2: {
      // About H2: We use H2 for the title of secondary page blocks when
      // possible. However, sometimes H2s aren't appropriate based on the
      // overall page structure and/or components being used to create the
      // page, so use your best judgement. If unsure and there's no clear
      // semantically-correct option, use H2 or H3 based on desired sizing.
      // Also used within large blocks of primary page text as would be
      // expected if it's known that the content is only used as primary
      // page text.
      // -----------------------------------------------------------------
      color: styleVars.txtColorH2,
      // fontFamily: styleVars.txtFontFamilyH2,
      fontSize: styleVars.txtFontSizeH2, //  18px
      fontVariant: styleVars.txtFontVariantH2,
      fontWeight: styleVars.txtFontWeightH2,
      lineHeight: styleVars.txtLineHeightH2,
      letterSpacing: styleVars.txtLetterSpacingH2,
      margin: `0 0 ${styleVars.txtMarginBottomH2}`,
      textTransform: styleVars.txtTextTransformH2,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeH2BpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeH2BpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeH2BpLg,
      },
    },
    h3: {
      // About H3: We use H3 for the title of tertiary page blocks, and
      // as misc blocks where the content level is unknown/unspecified.
      // Miscelaneous call-to-action and sidebar blocks will typically use
      // use an H3 for their titles.
      // Also used within large blocks text as a 3rd-level header (relative
      // to the page title) as would be expected.
      // -----------------------------------------------------------------
      color: styleVars.txtColorH3,
      // fontFamily: styleVars.txtFontFamilyH3,
      fontSize: styleVars.txtFontSizeH3, //  16px
      fontVariant: styleVars.txtFontVariantH3,
      fontWeight: styleVars.txtFontWeightH3,
      lineHeight: styleVars.txtLineHeightH3,
      letterSpacing: styleVars.txtLetterSpacingH3,
      margin: `0 0 ${styleVars.txtMarginBottomH3}`,
      textTransform: styleVars.txtTextTransformH3,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeH3BpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeH3BpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeH3BpLg,
      },
    },
    h4: {
      // About H4: We use H4 for the title of quaternary page blocks and
      // within large blocks of text as would be expected. For blocks that
      // are used on multiple pages at unknown/unspecified levels, you're
      // better off using H3.
      // Also used within large blocks text as a 4th-level header (relative
      // to the page title) as would be expected.
      // -----------------------------------------------------------------
      color: styleVars.txtColorH4,
      // fontFamily: styleVars.txtFontFamilyH4,
      fontSize: styleVars.txtFontSizeH4, //  16px
      fontVariant: styleVars.txtFontVariantH4,
      fontWeight: styleVars.txtFontWeightH4,
      lineHeight: styleVars.txtLineHeightH4,
      letterSpacing: styleVars.txtLetterSpacingH4,
      margin: `0 0 ${styleVars.txtMarginBottomH4}`,
      textTransform: styleVars.txtTextTransformH4,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeH4BpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeH4BpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeH4BpLg,
      },
    },
    h5: {
      // About H5: Used within large blocks text as a 4th-level header (relative
      // to the page title) as would be expected.
      // -----------------------------------------------------------------
      color: styleVars.txtColorH5,
      // fontFamily: styleVars.txtFontFamilyDefault,
      fontSize: styleVars.txtFontSizeH5, //  13px
      fontVariant: styleVars.txtFontVariantH5,
      fontWeight: styleVars.txtFontWeightH5,
      lineHeight: styleVars.txtLineHeightH5,
      letterSpacing: styleVars.txtLetterSpacingH5,
      margin: `0 0 ${styleVars.txtMarginBottomH5}`,
      textTransform: styleVars.txtTextTransformH5,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeH5BpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeH5BpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeH5BpLg,
      },
    },
    h6: {
      // About H6: Used within large blocks text as a 6th-level header (relative
      // to the page title) as would be expected. Pretty uncommon.
      // -----------------------------------------------------------------
      color: styleVars.txtColorH6,
      // fontFamily: styleVars.txtFontFamilyH6,
      fontSize: styleVars.txtFontSizeH6, //  12px
      fontVariant: styleVars.txtFontVariantH6,
      fontWeight: styleVars.txtFontWeightH6,
      lineHeight: styleVars.txtLineHeightH6,
      letterSpacing: styleVars.txtLetterSpacingH6,
      margin: `0 0 ${styleVars.txtMarginBottomH6}`,
      textTransform: styleVars.txtTextTransformH6,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeH6BpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeH6BpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeH6BpLg,
      },
    },
    body1: {
      // Default text styles.
      // --------------------
      color: styleVars.txtColorDefault,
      // fontFamily: styleVars.txtFontFamilyH6,
      fontSize: styleVars.txtFontSizeDefault, //  14px
      fontWeight: styleVars.txtFontWeightDefaultNormal,
      letterSpacing: styleVars.txtLetterSpacingBody,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeDefaultBpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeDefaultBpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeDefaultBpLg,
      },
    },
    body2: {
      // Default text styles, but when smaller is more appropriate.
      // ----------------------------------------------------------
      color: styleVars.txtColorDefault,
      fontSize: styleVars.txtFontSizeSm,
      fontWeight: styleVars.txtFontWeightDefaultNormal,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeSmBpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeSmBpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeSmBpLg,
      },
    },
    button: {
      // NOTE: Intentionally not declaring fontSize here because it
      // would otherwise impact things like tabs, requiring additional
      // specificity to correct them.
      fontFamily: styleVars.txtFontFamilyButtons,
      fontWeight: styleVars.txtFontWeightButtons,
    },
    caption: {
      color: styleVars.txtColorDefault,
      fontSize: styleVars.txtFontSizeSm,
      fontWeight: styleVars.txtFontWeightDefaultNormal,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeSmBpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeSmBpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeSmBpLg,
      },
    },
    subtitle1: {
      color: styleVars.txtColorDefault,
      fontSize: styleVars.txtFontSizeDefault,
      fontWeight: styleVars.txtFontWeightDefaultSemibold,
      [muiTheme.breakpoints.up("sm")]: {
        fontSize: styleVars.txtFontSizeDefaultBpSm,
      },
      [muiTheme.breakpoints.up("md")]: {
        fontSize: styleVars.txtFontSizeDefaultBpMd,
      },
      [muiTheme.breakpoints.up("lg")]: {
        fontSize: styleVars.txtFontSizeDefaultBpLg,
      },
    },
  },

  // -- COMPONENTS
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: styleVars.txtColorDefault,
          fontFamily: styleVars.txtFontFamilyDefault,
          fontWeight: styleVars.txtFontWeightDefaultNormal,
          lineHeight: styleVars.txtLineHeightDefault,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiAutocomplete-endAdornment": {
            top: "calc(50% - 12px)",
            ".MuiSvgIcon-root": {
              fontSize: styleVars.txtFontSizeXxl,
            },
          },
        },
        paper: {
          fontSize: styleVars.txtFontSizeLg,
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLg),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLg),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLg),
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: styleVars.txtFontSizeDefault,
        },
        label: {
          fontWeight: styleVars.txtFontWeightDefaultNormal,
        },
      },
    },
    // --
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: `solid 2px ${styleVars.colorLightGray}`,
          borderBottom: "unset",
        },
        rounded: {
          "&:first-of-type": {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          },
          "&:last-child": {
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            borderBottom: `solid 2px ${styleVars.colorLightGray}`,
          },
        },
      },
    },

    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          ".MuiBottomNavigationAction-root.Mui-selected": {
            backgroundColor: "#FCF0EB",
          },
          ".MuiBottomNavigationAction-root .MuiBottomNavigationAction-label": {
            fontSize: styleVars.txtFontSizeDefault,
            fontWeight: styleVars.txtFontWeightDefaultNormal,
            marginTop: 3,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: styleVars.txtFontSizeDefault,
        },
      },
    },
    // --
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: sp(2),
          paddingRight: sp(2),
          paddingTop: sp(2),
          paddingBottom: sp(2),
          fontSize: styleVars.txtFontSizeDefault,
        },
      },
    },

    // --
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: sp(2),
          paddingRight: sp(2),
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: styleVars.txtFontSizeDefault,
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          fontSize: styleVars.txtFontSizeXs,
          color: styleVars.txtColorLight,
          textTransform: "uppercase",
          fontFamily: styleVars.txtFontFamilyDefault,
          lineHeight: styleVars.txtLineHeightDefault,
          fontWeight: styleVars.txtFontWeightDefaultSemibold,
          letterSpacing: "0.5px",
        },
      },
    },
    // --
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
          lineHeight: styleVars.txtLineHeightButtons,
          marginBottom: sp(1),
          marginTop: sp(1.5),
          paddingLeft: sp(1.25),
          paddingRight: sp(1.25),
          paddingTop: "0.5em",
          paddingBottom: "0.4em",
          fontWeight: styleVars.txtFontWeightDefaultSemibold,
          textTransform: "none",
        },
        sizeLarge: {
          fontSize: remToPxNumber(styleVars.txtFontSizeButtonsLarge),
        },
        sizeMedium: {
          fontSize: remToPxNumber(styleVars.txtFontSizeButtons),
        },
        sizeSmall: {
          fontSize: remToPxNumber(styleVars.txtFontSizeButtonsSmall),
          textTransform: "uppercase",
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeButtonsSmallBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeButtonsSmallBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeButtonsSmallBpLg),
          },
        },
        startIcon: {
          marginRight: "0.25em",
          ".MuiSvgIcon-root": {
            fontSize: "1em !important",
            paddingBottom: "0.125em",
            paddingTop: 0,
          },
        },
        endIcon: {
          marginLeft: "0.25em",
          ".MuiSvgIcon-root": {
            fontSize: "1em !important",
            paddingBottom: "0.125em",
            paddingTop: 0,
          },
        },
        contained: {
          borderStyle: "solid", // otherwise border doesn't show up
          borderWidth: 2, // for uniformity w/"outlined"
          borderColor: "transparent", // so border color is same as button's bg
          boxShadow:
            "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
          "&:hover": {
            borderWidth: 2, // for uniformity w/"outlined"
          },
          "&[disabled]": {
            borderWidth: 2, // for uniformity w/"outlined"
          },
        },
        outlined: {
          boxShadow: "none",
          borderStyle: "solid",
          borderWidth: 2,
          "&:hover": {
            borderWidth: 2,
          },
          "&[disabled]": {
            borderWidth: 2,
          },
        },
        text: {},
        containedWarning: {
          color: styleVars.colorWhite,
        },

        textPrimary: {
          // Styles applied to the root element if variant="text"
          // and color="primary"
          color: styleVars.colorPrimaryExtraContrast,
        },
        textSecondary: {
          // Styles applied to the root element if variant="text"
          // and color="secondary"
          color: styleVars.colorSecondaryExtraContrast,
        },
      },
    },

    MuiButtonGroup: {
      styleOverrides: {
        outlined: {
          ".MuiButtonGroup-groupedOutlined": {
            opacity: 0.5,
          },
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          lineHeight: styleVars.txtLineHeightHeaders,
          fontSize: remToPxNumber(styleVars.txtFontSizeSm),
          background: "unset",
          padding: 0,
          width: 32,
          height: 32,
          borderRadius: "50% 50% 50% 0",
          backgroundColor: styleVars.colorPrimary,
          transformOrigin: "bottom left",
          transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
          "&:before": { display: "none" },
          "&.MuiSlider-valueLabelOpen": {
            transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
          },
          "& > *": {
            transform: "rotate(45deg)",
          },
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeSm),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeSm),
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&:hover, &.Mui-checked:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          padding: "0 10px",
          boxShadow:
            "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
          ".MuiSvgIcon-root": {
            fontSize: remToPxNumber(styleVars.txtFontSizeXxl),
          },
        },
        extended: {
          padding: "0 17px 0 10px",
        },
        sizeSmall: {
          fontSize: remToPxNumber(styleVars.txtFontSizeButtonsSmall),
        },
        sizeMedium: {
          fontSize: remToPxNumber(styleVars.txtFontSizeButtons),
        },
        sizeLarge: {
          fontSize: remToPxNumber(styleVars.txtFontSizeButtonsLarge),
        },
      },
    },
    MuiSpeedDialIcon: {
      styleOverrides: {
        root: {
          height: 21,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          ".MuiSvgIcon-fontSizeSmall": {
            fontSize: remToPxNumber(styleVars.txtFontSizeLg),
          },
          ".MuiSvgIcon-fontSizeMedium": {
            fontSize: remToPxNumber(styleVars.txtFontSizeXxl),
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        sizeSmall: {
          fontSize: remToPxNumber(styleVars.txtFontSizeRatingSm),
        },
        sizeMedium: {
          fontSize: remToPxNumber(styleVars.txtFontSizeRatingMd),
        },
        sizeLarge: {
          fontSize: remToPxNumber(styleVars.txtFontSizeRatingLg),
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          fontWeight: styleVars.txtFontWeightDefaultLight,
        },
        label: {
          fontSize: styleVars.txtFontSizeDefault,
          fontFamily: styleVars.txtFontFamilyDefault,
        },
      },
    },
    // --
    MuiCard: {
      styleOverrides: {
        root: {},
      },
    },

    // --
    MuiCardContent: {
      styleOverrides: {
        root: {
          //
        },
      },
    },

    // --
    MuiCardHeader: {
      defaultProps: {
        disableTypography: true,
      },
      styleOverrides: {
        root: {
          //
        },
        title: {
          fontSize: remToPxNumber(styleVars.txtFontSizeLg),
          fontWeight: styleVars.txtFontWeightDefaultBold,
          marginBottom: "0.125em",
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLgBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLgBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLgBpLg),
          },
        },
      },
    },

    // --
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: "2px solid #F3F5F7",
        },
      },
    },

    // --
    MuiCheckbox: {
      styleOverrides: {
        root: {
          ".MuiSvgIcon-fontSizeMedium": {
            fontSize: remToPxNumber(styleVars.txtFontSizeXl),
          },
          ".MuiSvgIcon-fontSizeSmall": {
            fontSize: 15,
          },
        },
      },
    },

    // --
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "none",
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderWidth: 2,
        },
      },
    },
    // --
    MuiDialogTitle: {
      defaultProps: {
        // Unfortunately, disableTypography does not work on this.
        // disableTypography: true,
      },
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyDefault,
          fontSize: remToPxNumber(styleVars.txtFontSizeXxl),
          fontWeight: styleVars.txtFontWeightH3,
          lineHeight: styleVars.txtLineHeightH3,
          paddingBottom: sp(0.25),
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeXxlBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeXxlBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeXxlBpLg),
          },
        },
      },
    },

    // --
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: styleVars.colorDividerDefault,
          borderColor: styleVars.colorDividerDefault,
          height: 2,
        },
        vertical: {
          width: 2,
          height: "auto",
        },
        light: {
          backgroundColor: styleVars.colorDividerLight,
          borderColor: styleVars.colorDividerLight,
        },
      },
    },

    // --
    MuiInput: {
      // NOTE: This is for <TextField variant="standard">.
      // @see MuiFilledInput (<TextField variant="filled">)
      // @see MuiOutlinedInput (<TextField variant="outlined">)
      //
      // *** DO NOT SET HEIGHT ON INPUTS ***
      styleOverrides: {
        root: {
          backgroundColor: styleVars.colorInputBackground,
          "&.Mui-disabled": {
            backgroundColor: styleVars.colorInputBackgroundDisabled,
            color: styleVars.colorGray,
          },
        },
        input: {
          backgroundColor: styleVars.colorOffWhite,
          lineHeight: styleVars.txtLineHeightInputs,
          paddingLeft: sp(1.25),
          paddingRight: sp(0.5),
          paddingTop: sp(1.5),
          paddingBottom: sp(1.5),
        },
        inputMultiline: {
          lineHeight: styleVars.txtLineHeightInputs,
          paddingLeft: sp(1.25),
          paddingRight: sp(0.5),
          paddingTop: sp(1.5),
          paddingBottom: sp(1.5),
        },
        underline: {
          "&:before": {
            borderBottom: `2px solid ${styleVars.colorLightGray}`,
          },
        },
      },
    },

    // --
    MuiInputAdornment: {
      defaultProps: {
        disableTypography: true,
      },
    },

    // --
    MuiInputBase: {
      // *** DO NOT SET HEIGHT ON INPUTS ***
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyInputs,
          fontSize: remToPxNumber(styleVars.txtFontSizeInputs),
          fontWeight: styleVars.txtFontWeightInputs,
          lineHeight: styleVars.txtLineHeightInputs,
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeInputsBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeInputsBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeInputsBpLg),
          },
        },
      },
    },

    // --
    MuiFilledInput: {
      // NOTE: This is for <TextField variant="filled">.
      // @see MuiInput (<TextField variant="standard">)
      // @see MuiOutlinedInput (<TextField variant="outlined">)
      // *** DO NOT SET HEIGHT ON INPUTS ***
      styleOverrides: {
        root: {
          backgroundColor: styleVars.colorInputBackground,
          "&.Mui-disabled": {
            backgroundColor: styleVars.colorInputBackgroundDisabled,
            color: styleVars.colorGray,
          },
        },
        input: {
          boxShadow: "inset 0 0 4px #000000",
          lineHeight: styleVars.txtLineHeightInputs,
          paddingLeft: sp(1.25),
          paddingRight: sp(0.5),
          paddingTop: sp(1.5),
          paddingBottom: sp(1.5),
        },
        inputMultiline: {
          lineHeight: styleVars.txtLineHeightInputs,
          paddingLeft: sp(1.25),
          paddingRight: sp(0.5),
          paddingTop: sp(1.5),
          paddingBottom: sp(1.5),
        },
      },
    },

    // --
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MuiTextField-root": {
            marginTop: sp(1.5),
            marginBottom: sp(1),
          },
        },
      },
    },

    // --
    MuiFormControlLabel: {
      defaultProps: {
        disableTypography: true,
      },
      styleOverrides: {
        label: {
          fontFamily: styleVars.txtFontFamilyInputs,
          fontSize: remToPxNumber(styleVars.txtFontSizeLg),
          textTransform: "none",
        },
        root: {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.26)",
          },
          "&.MuiFormControlLabel-labelPlacementEnd": {
            alignItems: "center",
            transition: "filter 0.3s",
            width: "100%",
            "&:hover,&:active,&:focus": {
              filter: "contrast(125%)",
            },
          },
        },
      },
    },

    MuiFormGroup: {
      root: {
        //
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: remToPxNumber(styleVars.txtFontSizeXxl),
        },
      },
    },
    // --
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyDefault,
          fontSize: remToPxNumber(styleVars.txtFontSizeXs),
          fontWeight: styleVars.txtFontWeightDefaultNormal,
          marginBottom: sp(0.5),
          marginTop: sp(0.125),
          maxWidth: "600px",
          color: styleVars.colorDarkGray,
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeXsBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeXsBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeXsBpLg),
          },
        },
      },
    },

    // --
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyInputs,
          fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
          textTransform: "none",
          color: styleVars.txtColorLight,
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpLg),
          },
        },
        shrink: {
          backgroundColor: "#FFFFFF",
          "&.Mui-disabled": {
            backgroundColor: "rgba(255,255,255,0.5)",
          },
        },
      },
    },

    // --
    MuiLinearProgress: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: "#F3F5F7",
        },
      },
    },

    // --
    MuiListItem: {
      styleOverrides: {
        button: {
          //
        },
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: styleVars.txtFontSizeSm,
          fontWeight: styleVars.txtFontWeightDefaultNormal,
          lineHeight: styleVars.txtLineHeightDefault,
          minHeight: 40,
          display: "flex",
          alignItems: "center",
        },
      },
    },
    // --
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: sp(1),
          minWidth: 0,
        },
      },
    },
    // --
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: styleVars.colorMenuHover,
          },
        },
      },
    },

    // --
    MuiListItemText: {
      defaultProps: {
        disableTypography: true,
      },
      styleOverrides: {
        root: {
          fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
        },
      },
    },

    // --
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyDefault,
          fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
          lineHeight: styleVars.txtLineHeightDefault,
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpLg),
          },
          ".MuiListItemIcon-root": {
            minWidth: 20,
          },
          "&:hover": {
            backgroundColor: styleVars.colorMenuHover,
          },
        },
      },
    },

    // --
    MuiOutlinedInput: {
      // NOTE: This is for <TextField variant="outlined">.
      // @see MuiInput (<TextField variant="standard">)
      // @see MuiFilledInput (<TextField variant="filled">)
      // *** DO NOT SET HEIGHT ON INPUTS ***
      styleOverrides: {
        root: {
          backgroundColor: styleVars.colorInputBackground,
          "&.Mui-disabled": {
            backgroundColor: styleVars.colorInputBackgroundDisabled,
            color: styleVars.colorGray,
            fieldset: {
              border: "none !important",
            },
          },
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: styleVars.colorPrimary,
            },
          },
        },
        notchedOutline: {
          borderWidth: 2,
          borderColor: styleVars.colorLightGray,
        },
        input: {
          lineHeight: styleVars.txtLineHeightInputs,
          boxShadow: "unset !important",
        },
        inputMultiline: {
          lineHeight: styleVars.txtLineHeightInputs,
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          lineHeight: 0.5,
          fontSize: styleVars.txtFontSizeDefault,
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
          },
        },
      },
    },
    // --
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: styleVars.colorWhite,
          borderRadius: "3px",
          boxShadow: "0 0 12px rgba(0,0,0,0.25) !important",
          color: "#222",
          minWidth: sp(1),
          padding: 1,
        },
      },
    },

    // --
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          color: styleVars.txtColorDefault,
          "@media print": {
            border: "none",
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        outlined: {
          border: `solid 2px ${styleVars.colorLightGray}`,
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
          fontFamily: styleVars.txtFontFamilyDefault,
          fontWeight: styleVars.txtFontWeightDefaultNormal,
          p: {
            color: "inherit",
          },
          "a, a:link, a:visited, a:hover, a:active, a:focus": {
            color: "inherit",
            textDecoration: "underline",
          },
        },
        outlined: {
          borderWidth: 2,
        },
        standardError: {
          border: `2px solid ${styleVars.colorStatusError}`,
          color: styleVars.txtAlertError,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertError,
          },
        },
        standardWarning: {
          border: `2px solid ${styleVars.colorStatusWarning}`,
          color: styleVars.txtAlertWarning,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertWarning,
          },
        },
        standardInfo: {
          border: `2px solid ${styleVars.colorStatusInfo}`,
          color: styleVars.txtAlertInfo,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertInfo,
          },
        },
        standardSuccess: {
          border: `2px solid ${styleVars.colorStatusSuccess}`,
          color: styleVars.txtAlertSuccess,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertSuccess,
          },
        },

        outlinedError: {
          color: styleVars.txtAlertError,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertError,
          },
        },
        outlinedWarning: {
          color: styleVars.txtAlertWarning,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertWarning,
          },
        },
        outlinedInfo: {
          color: styleVars.txtAlertInfo,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertInfo,
          },
        },
        outlinedSuccess: {
          color: styleVars.txtAlertSuccess,
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertSuccess,
          },
        },

        filledError: {
          border: `2px solid ${styleVars.colorStatusErrorDark}`,
          color: styleVars.colorWhite,
          ".MuiAlertTitle-root": {
            color: styleVars.colorWhite,
          },
        },
        filledWarning: {
          border: `2px solid ${styleVars.colorStatusWarningDark}`,
          ".MuiAlert-message": {
            color: styleVars.txtAlertWarning,
          },
          ".MuiAlertTitle-root": {
            color: styleVars.txtAlertWarning,
          },
        },
        filledInfo: {
          border: `2px solid ${styleVars.colorStatusInfoDark}`,
          color: styleVars.colorWhite,
          ".MuiAlertTitle-root": {
            color: styleVars.colorWhite,
          },
        },
        filledSuccess: {
          border: `2px solid ${styleVars.colorStatusSuccessDark}`,
          color: styleVars.colorWhite,
          ".MuiAlertTitle-root": {
            color: styleVars.colorWhite,
          },
        },
        action: {
          alignItems: "center",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyDefault,
        },
      },
    },
    // --
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: styleVars.colorWhite,
          boxShadow: "0 0 0 1px rgba(0,0,0,0.05)",
          "&.Mui-disabled": {
            backgroundColor: styleVars.colorInputBackgroundDisabled,
          },
        },
        icon: {
          top: "calc(50% - 10px)",
        },
      },
    },

    // --
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyDefault,
        },
      },
    },

    // --
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // height: "0.8em",
          // fontSize: 22,
          // width: "0.8em",
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 2px ${styleVars.colorLightGray}`,
        },
        selectLabel: {
          marginBottom: 3,
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLgBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLgBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeLgBpLg),
          },
        },
        displayedRows: {
          marginBottom: 0,
        },
      },
    },
    // --
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyTableBody,
          fontSize: remToPxNumber(styleVars.txtFontSizeTableBody),
          fontWeight: styleVars.txtFontWeightTableBody,
          lineHeight: styleVars.txtLineHeightTableBody,
          height: "auto",
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableBodyBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableBodyBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableBodyBpLg),
          },
        },
        head: {
          height: "auto",
          fontFamily: styleVars.txtFontFamilyTableHead,
          fontSize: remToPxNumber(styleVars.txtFontSizeTableHead),
          fontWeight: styleVars.txtFontWeightTableHead,
          lineHeight: styleVars.txtLineHeightTableHead,
          // padding: styleVars.tblHeadCellPadding,
          textTransform: "uppercase",
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableHeadBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableHeadBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableHeadBpLg),
          },
        },
        body: {
          fontSize: remToPxNumber(styleVars.txtFontSizeTableBody),
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableBodyBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableBodyBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeTableBodyBpLg),
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: styleVars.txtFontWeightDefaultSemibold,
        },
      },
    },

    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          // ...
        },
        icon: {
          fontSize: "0.95em",
        },
      },
    },
    // --
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "auto",
          borderTop: `solid 2px ${styleVars.colorLightGray}`,
          "&:first-of-type:not(.MuiTableRow-head)": {
            borderTop: "none",
          },
        },
        head: {
          height: "auto",
          borderTop: "none",
          borderBottom: `solid 2px ${styleVars.colorLightGray}`,
        },
        hover: {
          color: "#FB4F14",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: `${styleVars.colorOffWhite} !important`, // @TODO adjust to not need !important
            color: "#FB4F14 !important",
          },
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 0,
          ".MuiSvgIcon-root": {
            fontSize: remToPxNumber(styleVars.txtFontSizeXxl),
          },
          ".MuiSvgIcon-root.Mui-selected": {
            backgroundColor: "rgba(0,0,0,0.12)",
          },
        },
        sizeSmall: {
          width: 40,
          height: 40,
        },
        sizeMedium: {
          width: 48,
          height: 48,
        },
        sizeLarge: {
          width: 56,
          height: 56,
        },
      },
    },
    // --
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: styleVars.txtFontFamilyDefault,
          fontSize: remToPxNumber(styleVars.txtFontSizeDefault),
          fontWeight: styleVars.txtFontWeightDefaultNormal,
          letterSpacing: "-0.025em",
          minHeight: sp(4.5),
          textTransform: "none",
          [muiTheme.breakpoints.up("sm")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpSm),
          },
          [muiTheme.breakpoints.up("md")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpMd),
          },
          [muiTheme.breakpoints.up("lg")]: {
            fontSize: remToPxNumber(styleVars.txtFontSizeDefaultBpLg),
          },
          "&.Mui-selected": {
            color: styleVars.txtColorDefault,
            fontWeight: styleVars.txtFontWeightDefaultSemibold,
          },
        },
      },
    },

    // --
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `solid 2px ${styleVars.colorDividerLight}`,
          minHeight: sp(4.5),
        },
        indicator: {
          bottom: 0,
          height: 3,
        },
      },
    },
    // --
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },

    // --
    MuiToolbar: {
      styleOverrides: {
        root: {},
        dense: {},
      },
    },
    // --
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: styleVars.txtFontSizeXs,
        },
      },
    },
  },
});

export default muiTheme;

import { Route, Redirect, RedirectProps, RouteProps } from "react-router-dom";
import { get } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { CurrentUser } from "@/common/classes/UserModel";

/**
 * Route component for paths that require user to be staff (or admin).
 *
 * We allow users with system role "admin" or "staff" to access these
 * routes. Further permission checking must typically be done at the
 * destination components.
 *
 * As of this writing, this Route component allows access to users
 * that, at minimum have system role "staff" and no individual actions.
 */
const StaffRoute = ({
  currentUser,
  ...props
}: StaffRouteProps) => {
  const userSystemRole = get(currentUser, "data.system_role_machine_name", null);
  const userIsLoading = get(currentUser, "loading", true);

  if (userIsLoading) {
    return (
      <Route
        {...props}
        render={() => (
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
        )}
      />
    );
  }

  switch (userSystemRole) {
    case "staff":
    case "admin":
      // return <Route render={render} component={component} {...props} />;
      return <Route {...props} />;
    default:
      // @ts-ignore
      return <Route {...props} render={() => <AdminRedirect />} />;
  }
};

export interface StaffRouteProps extends RouteProps {
  currentUser: CurrentUser,
}

export const AdminRedirect = (props: RedirectProps) => <Redirect {...props} to="/app/account/login" />;

export default StaffRoute;

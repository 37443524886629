import { Route, Redirect, RouteProps } from "react-router-dom";
import { get } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { CurrentUser } from "@/common/classes/UserModel";

/**
 * Route component for paths requiring an authenticated user.
 *
 * No other user requirements are imposed on the user besides
 * having an account being logged-in.
 */
function PrivateRoute({
  currentUser,
  redirectBackOnLogin = true,
  ...props
}: PrivateRouteProps) {
  const userIsLoading = get(currentUser, "loading", true);

  // Wait until user is fully loaded. (authenticated, that is)
  if (userIsLoading) {
    return (
      <Route
        {...props}
        render={() => (
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
        )}
      />
    );
  }

  // If not authenticated, they don't get through.
  if (!currentUser.isAuthenticated) {
    return (
      <Route
        {...props}
        render={({ location }) => {
          const redirectTo: any = { pathname: "/app/account/login" };
          if (redirectBackOnLogin) {
            redirectTo.state = { from: location };
          }
          return <Redirect to={redirectTo} />;
        }}
      />
    );
  }

  // If done loading and successfully authenticated, they get through.
  return <Route {...props} />;
};

export interface PrivateRouteProps extends RouteProps {
  currentUser: CurrentUser,
  redirectBackOnLogin?: boolean,
}

export default PrivateRoute;

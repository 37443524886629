import React from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import DraftEditor from "@/features/rich-text/components/DraftEditor";
import HgSkeleton from "@/common/components/ui/atoms/HgSkeleton";
import { get, isEmpty } from "lodash";
import { Store } from "@/store/Store";

// @TODO Centralize related functionality into a feature

/**
 * Standardized wrapper for displaying dynamic content.
 *
 * Uses "contents" from redux.
 */
const DisplayContent = ({ machineName, skeletonRows = 1 }: { machineName: string, skeletonRows?: number }) => {
  let contents = useSelector((state: Store) => state.contents);
  let loadingArray: string[] = get(contents, `loading`, []);

  let loading = loadingArray.indexOf(machineName) === -1 ? false : true;
  let contentByMachineName = get(contents, `data.${machineName}`, {});
  let content = get(contentByMachineName, `content`, "");
  let hasContent = !isEmpty(content);

  if (loading) {
    let loaders = [];
    for (let i = 0; i < skeletonRows; i++) {
      loaders.push(<HgSkeleton key={i} variant="text" width="100%" />);
    }
    return loaders;
  }

  if (hasContent) {
    return (
      <React.Fragment>
        <DraftEditor value={content} readOnly={true} />
      </React.Fragment>
    );
  }

  return null;
};

export default DisplayContent;

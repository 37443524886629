import React from "react";

// @TODO Is it possible to use React Router "Link" copmonents in
// here instead of <a> tags? (when link is not absolute/external)

/**
 * Link decorator for the DraftEditor.
 *
 * The output of this component is used in both edit and
 * read-only modes.
 */
const LinkDecorator = (props) => {
  const { dec, isLinkTargetBlank } = props.contentState.getEntity(props.entityKey).getData();
  if (isLinkTargetBlank) {
    return (
      <a href={dec} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  } else {
    return <a href={dec}>{props.children}</a>;
  }
};

export default LinkDecorator;

import { createStore, applyMiddleware } from "redux";
import rootReducer from "@/store/reducers";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";
import sagas from "@/store/sagas/index";
import { appHistory } from "@/common/other/appHistory";

// @TODO createStore deprecated?

const configureStore = (history) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  const composeEnhancers = composeWithDevToolsDevelopmentOnly({
    // options like actionSanitizer, stateSanitizer
  });
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
  sagaMiddleware.run(sagas, { history });
  return store;
};

const appStore = configureStore(appHistory);

export default appStore;

import { takeEvery } from "redux-saga/effects";
import {
  fetchOrganizationTeam,
  fetchOrganizationTeamStart,
  fetchOrganizationTeamSuccess,
  fetchOrganizationTeamFailure,
} from "@/store/actions";
import { fetcherForSagas } from "@/store/fetcherForSagas";
import { requestOrganizationUsers } from "@/common/api/requests";

function* organization_team() {
  yield takeEvery(
    fetchOrganizationTeam,
    fetcherForSagas({
      start: fetchOrganizationTeamStart,
      success: fetchOrganizationTeamSuccess,
      failure: fetchOrganizationTeamFailure,
      request: ({ payload }) => {
        let orgId = Number(payload.organization_id);
        let params = {};

        // Hardcode pagination values (no need to support more flexibility).
        params.page = 1;
        params.per_page = 500;

        return requestOrganizationUsers(orgId, params);
      },
    })
  );
}

export default organization_team;

import { Box } from "@mui/material";

/**
 * Renders custom NPS survey.
 */
function NpsSurvey2024() {
  return (
    <Box
      component="iframe"
      src="https://healthiergen.formstack.com/forms/net_promoter_score_survey_copy"
      title="Net Promoter Score Survey"
      width="600"
      height="380"
      sx={{ border: "none", height: "380px", maxWidth: "800px", width: "100%" }}
    ></Box>
  );
}

export default NpsSurvey2024;

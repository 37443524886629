import { forEach, get, has, includes, isEmpty, isNil, isNumber, isObjectLike, isString, trim } from "lodash";
import { orgTypeForMachineName } from "@/common/classes/OrganizationTypeUtils";
import { Organization } from "./OrganizationModel";
import { CurrentUser, User } from "./UserModel";

/**
 * Static-only helper class for working with users.
 */
class UserUtils {
  /**
   * Determine if a currentUser can perform an action.
   *
   * Aside from direct evaluation of the requested action for the
   * specfied user/organization, this method will account for staff
   * role permissions that deliver equivalent access.
   *
   * @param {object} currentUser
   *  Object with `data` property (at currentUser.data).
   * @param {string} action
   *  Machine name of an "action" to evaluate. Can be a staff role "action" or
   *  a user org "permissions.action". It will be treated as the former if
   *  the `organization` paramter is null, the latter if populated with
   *  and organization object.
   * @param {object|null} organization
   *  If evaluating a user _org_ permission action, this must contain
   *  the applicable organization with `requester_permissions`,
   *  ` array
   *  populated for currentUser.
   *
   * @returns {boolean}
   *  Returns whether or not the user shall be allowed to take the
   *  action requested.
   */
  static userCan(
    currentUser: CurrentUser,
    action: string,
    organization: Organization | null = null,
    organizationRelationship: "own"|"parent"|"child" = "own"
  ): boolean {
    let _staffActions: any[] = [];

    if (currentUser && currentUser.bootstrapped) {
      _staffActions = get(currentUser, "data.staff_actions", []);
    }

    // Sanity check the parameters.
    if (!isObjectLike(currentUser) || !has(currentUser, "data") || !isString(action)) {
      return false;
    }

    // In the interest of performance, we'll skip all this and move on if the
    // user is an admin since they can do it all.
    if (currentUser.isAdmin) {
      return true;
    }

    // Determine if we treat the "action" param as a user/org permission action
    // or a staff role action.
    const actionScope = isNil(organization) ? "staff" : "org";

    // Scope: Staff action
    // -------------------
    if ("staff" === actionScope) {
      if ("staff" !== currentUser.data.system_role_machine_name) {
        // Staff action scope, but not a staff user.
        return false;
      }
      // Return is determined by presence/absence of action in user's `staff_actions`.
      let _r = includes(_staffActions, action);
      return _r;
    }

    // Scope: Org role action
    // ----------------------

    // If user is staff, check if they have a staff action that encompasses
    // the specified org action.
    if ("staff" === currentUser.data.system_role_machine_name) {
      // Find the array of staff actions for the specified org action in lookup.
      // @see UserUtils.organizationActionsStaffActions
      let equivalentStaffActions = get(UserUtils.organizationActionsStaffActions, action, []);
      if (equivalentStaffActions.length > 0) {
        // Add the generic "_STAFF_" flag to the staff action machine names we'll look for.
        let cuStaffActions = [..._staffActions, "_STAFF_"];
        // If equivalentStaffActions contains any cuStaffActions, user is allowed
        // so we can return immediately.
        for (let i = 0; i < equivalentStaffActions.length; i++) {
          let esd = equivalentStaffActions[i];
          if (includes(cuStaffActions, esd)) {
            return true;
          }
        }
      }
    }

    // ----------
    // Beyond this point, the user is either a "staff" user with no
    // staff action that gives them access, or they are simply a
    // standard user.
    //
    // So, we evaluate access based only on what permissions they have
    // for the organization, if any.
    // ----------

    // Determine which property to use for reading permissions.
    let permsProperty: "requester_permissions"|"requester_permissions_child"|"requester_permissions_parent";

    if ("parent" === organizationRelationship) {
      permsProperty = "requester_permissions_parent";
    }
    else if ("child" === organizationRelationship) {
      permsProperty = "requester_permissions_child";
    }
    else {
      permsProperty = "requester_permissions";
    }

    // First: Log a warning if organization.requester_permissions isn't present,
    // since that may indicate we need to adjust the source of that organization
    // to one that provides that data.
    if (organization && !organization.hasOwnProperty(permsProperty)) {
      console.warn(
        `UserUtils.userCan() organization parameter lacks ${permsProperty} property ` +
          `(user ID: ${currentUser.data.id}, organization ID: ${organization.id})`
      );
    }

    // Check if user is pending approval to be accepted to org
    if (organization && UserUtils.userIsPendingApprovalForOrg(currentUser.data.id, organization)) {
      // Not officially associated yet, so any org role they have is not
      // applicable to the scope of this check.
      return false;
    }
    let perms = get(organization, permsProperty, []);
    let res = includes(perms, action);
    return res;
  }

  /**
   * Calculates the total number of organizations for a currentUser object.
   *
   * This relies on the `organization_counts` property provided
   * by the API for the current user (via `/api/auth/me`), which
   * means other user objects will probably not work.
   *
   * @param {object} currentUserData The currentUser data object from redux
   *  (auth.currentUser.data)
   * @return {number|null} Returns the total number of organizations.
   *  As per the the organization_counts property, only organizations
   *  where the use is approved are counted. Returns null if currentUserData
   *  is invalid.
   */
  static currentUserOrgCount(currentUserData: User): number | null {
    if (
      isEmpty(currentUserData) ||
      !currentUserData.hasOwnProperty("organization_counts") ||
      isEmpty(currentUserData.organization_counts)
    ) {
      // currentUserData appears to be invalid.
      return null;
    }

    let total = 0;
    forEach(currentUserData.organization_counts, (v) => {
      if (isNumber(v)) {
        total = total + v;
      }
    });
    return total;
  }

  /**
   * Calculates the number of orgs of a given type for currentUser.
   *
   * This relies on the `organization_counts` property provided
   * by the API for the current user (via `/api/auth/me`), which
   * means other user objects will probably not work.
   *
   * @param {string} orgTypeMachineName
   * @param {object} currentUserData The currentUser data object from redux
   *  (auth.currentUser.data)
   * @param {object} orgTypes
   *  Typically you'll use appMeta.data.organizationTypes for this.
   * @return {number|null} Returns the total number of organizations.
   *  As per the the organization_counts property, only organizations
   *  where the use is approved are counted. Returns null if an argument
   *  is identified as invalid.
   */
  static currentUserOrgTypeCount(orgTypeMachineName: string, currentUserData: User, orgTypes: any): number | null {
    if (
      !isEmpty(orgTypes) &&
      !isEmpty(currentUserData) &&
      currentUserData.hasOwnProperty("organization_counts") &&
      !isEmpty(currentUserData.organization_counts)
    ) {
      let orgType: any = orgTypeForMachineName(orgTypeMachineName, orgTypes);
      let userOrgCounts: object = currentUserData.organization_counts;
      let orgTypeCount: number = get(userOrgCounts, orgType.id, 0);

      if (isNumber(orgTypeCount)) {
        return orgTypeCount;
      }
    }
    return null;
  }

  /**
   * Map of org action coverage by staff actions.
   *
   * Keys are org action machine names. Each value is an
   * array of the staff role action machine names that provide
   * equivalent access. Each individual staff role action included
   * in the array shall be sufficient to provide coverage of the
   * corresponding org role action. In other words, it's not
   * necessary for the user to have _all_ staff actions in the
   * array, just one.
   *
   * When an org action is available to any "Staff" user, the
   * value array will contain only "_STAFF_".
   *
   * Presence of actions, whether org or staff, is NOT guaranteed.
   * In particular, it's important to know that an org action with
   * no staff actions will most likely be omitted.
   *
   * @var {Object}
   */
  static organizationActionsStaffActions = {
    approve_organization_users: ["edit_all_requests"],
    edit_action_plans: ["_STAFF_"],
    edit_own_user_organization: ["edit_all_users"],
    edit_surveys: ["edit_all_surveys"],
    edit_user_organization_relationship_of_guests: ["edit_all_users"],
    edit_user_organization_relationship_of_team_members: ["edit_all_users"],
    invite_users: ["edit_all_invites"],
    remove_user_organization_relationship_of_guests: ["edit_all_users"],
    remove_user_organization_relationship_of_team_members: ["edit_all_users"],
    view_action_plans: ["_STAFF_"],
    view_assessments: ["_STAFF_"],
    view_criterion_notes: ["_STAFF_"],
    view_criterion_tasks: ["_STAFF_"],
    view_docbuilders: ["_STAFF_"],
    view_prioritization_calculators: ["_STAFF_"],
    view_responses: ["_STAFF_"],
    view_surveys: ["view_all_surveys"],
    view_team: ["_STAFF_"],
  };

  /**
   * Check if a user is directly associated with an organization.
   *
   * This checks if the user has a relationship record with an organization
   * (unlike, for example, a user that may have _access_ to a school by
   * virtue of being associated with its district).
   *
   * The relationship must also be active (`access_approved_at` populated)
   * unless the allowPending argument is true.
   *
   * Requires the organization object have at least one of two properties
   * that describe the relationship:
   *
   * - `pivot`: Object describing user/org relationship. Included when
   *   object was retrieved from a user-specific organization endpoint
   *   (i.e., api/v1/users/x/organizations)
   * - `requester_pivot`: Same as `pivot`, but is included in all
   *   organization objects retrieved from the API.
   *
   * This method will check both of those properties to see if they
   * exist and have a `user_id` property that matches userId.
   *
   * @param {int} userId
   * @param {object} org
   * @parm {boolean} allowPending
   * @returns {boolean}
   */
  static userBelongsToOrg(userId: number, org: Organization, allowPending: boolean = false): boolean {
    let res = false;

    if (!has(org, "pivot") && !has(org, "requester_pivot")) {
      console.error("userBelongsToOrg: org argument did not include a pivot or requester_pivot property");
    }

    let checkProperty = (property: string) => {
      return (
        has(org, property) &&
        // @ts-ignore
        has(org[property], "user_id") &&
        // @ts-ignore
        org[property].user_id &&
        // @ts-ignore
        parseInt(String(userId), 10) === parseInt(org[property].user_id, 10)
      );
    };

    let matchingPivotObj = null;

    if (checkProperty("pivot")) {
      // @ts-ignore
      matchingPivotObj = org.pivot;
    } else if (checkProperty("requester_pivot")) {
      matchingPivotObj = org.requester_pivot;
    }

    // Check for a winner.
    if (matchingPivotObj) {
      if (allowPending) {
        res = true;
      } else {
        // @ts-ignore
        res = !isEmpty(matchingPivotObj.access_approved_at);
      }
    }

    return res;
  }

  /**
   * Check if a user is pending approval to join an organization.
   *
   * This only returns true if user has a direct relationship
   * with the organization, and that relationship has an empty
   * `access_approved_at` value.
   *
   * This method will check for an object's requester_pivot and
   * pivot properties. The first one that includes a user_id
   * that matches the userId argument will be used.
   *
   * Users already associated with the organization will generate
   * a false return value.
   *
   * @param {number} userId
   * @param {object} org
   * @returns {boolean}
   */
  static userIsPendingApprovalForOrg(userId: number, org: Organization): boolean {
    let res = false;
    let belongs = UserUtils.userBelongsToOrg(userId, org, true);

    if (belongs) {
      let check = ["pivot", "requester_pivot"];

      forEach(check, (propName) => {
        if (
          has(org, propName) &&
          // @ts-ignore
          !isEmpty(org[propName]) &&
          // @ts-ignore
          has(org[propName], "user_id") &&
          // @ts-ignore
          parseInt(org[propName].user_id, 10) === parseInt(String(userId), 10) &&
          // @ts-ignore
          isEmpty(org[propName].access_approved_at)
        ) {
          res = true;
        }
        if (res) {
          return false; // break loop
        }
      });
    }
    return res;
  }

  /**
   * Get error text for a password reset error code.
   *
   * @param {string} errorCode
   * @returns {string}
   */
  static errorMessageForResetPassword = (errorCode: string): string => {
    switch (errorCode) {
      case "passwords.user":
        return "The user account provided appears to be invalid.";
      case "passwords.password":
        return "The password provided doesn't appear to meet our requirements. Please try another.";
      case "passwords.token":
        return "The provided token appears to be invalid or has already been used. Please request another password reset to try again.";
      default:
        return "There was an unexpected error resetting your password. Please email us at help@healthiergeneration.org for assistance.";
    }
  };

  /**
   * Low-overhead comparison of currentUser objects based on ID.
   *
   * @DEPRECATED
   *
   * Intended for use in componentDidUpdate() as a lightweight alternative
   * to comparing entire currentUser object structures to determine if a
   * currentUser has changed. Boilerplate code for safely navigating into the
   * object structure to reach the ID is handled here.
   *
   * Comparison is done based on {obj}.data.id. If a provided value lacks the
   * data, data.id, or isAuthenticated properties, or isAuthenticated is false,
   * we evaluate its ID as null.
   *
   * @param {object} cu1
   * @param {object} cu2
   * @returns {boolean}
   */
  static compareCurrentUserObjectIds(cu1: any, cu2: any) {
    let extractIdFromObj = (cu: any) => {
      if (
        cu &&
        cu.hasOwnProperty("isAuthenticated") &&
        cu.isAuthenticated &&
        cu.hasOwnProperty("data") &&
        cu.data.hasOwnProperty("id") &&
        isNumber(cu.data.id)
      ) {
        return cu.data.id;
      }
      return null;
    };

    let cuId1 = extractIdFromObj(cu1);
    let cuId2 = extractIdFromObj(cu2);

    return cuId1 === cuId2;
  }

  /**
   * Get a displayable full name string for a user object.
   *
   * @param {object} uObj
   * @param {boolean} lastNameAsInitial
   * @returns {string}
   */
  static userFullName(uObj: User, lastNameAsInitial: boolean = false): string {
    let fname = "";
    let lname = "";

    if (!isNil(uObj) && typeof uObj === "object") {
      if (!isNil(uObj.name_first) && !isEmpty(uObj.name_first)) {
        fname = uObj.name_first;
      }
      if (!isNil(uObj.name_last) && !isEmpty(uObj.name_last)) {
        lname = uObj.name_last;
        if (lastNameAsInitial) {
          lname = lname.charAt(0).toUpperCase() + ".";
        }
      }
    }
    return trim(`${fname} ${lname}`);
  }
}

export const compareCurrentUserObjectIds = UserUtils.compareCurrentUserObjectIds;
export const currentUserOrgCount = UserUtils.currentUserOrgCount;
export const currentUserOrgTypeCount = UserUtils.currentUserOrgTypeCount;
export const errorMessageForResetPassword = UserUtils.errorMessageForResetPassword;
export const userCan = UserUtils.userCan;
export const userIsPendingApprovalForOrg = UserUtils.userIsPendingApprovalForOrg;
export const userBelongsToOrg = UserUtils.userBelongsToOrg;
export const userFullName = UserUtils.userFullName;

import axios from "axios";
import { has } from "lodash";
import { removeCsrfToken, latestRequestLsKey, prevLatestRequestLsKey } from "@/common/classes/AuthUtils";
import Pusher from "pusher-js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

/**
 * Static-only helper class for API-related functionality.
 */
class ApiUtils {
  /**
   * Our primary wrapper method for making API requests.
   *
   * @returns {Promise}
   */
  static makeRequest({
    method = "POST",

    // **url:** URL the request will be sent to.
    url = null,

    // **body:** Object w/body of request, unless method is GET (then it's the
    //  URL parameters).
    body = null,

    // **headers:** Custom request headers.
    headers = {},

    // **contentType:** Sets `Content-Type` header of request.
    contentType = "application/json", // @TODO Check if okay when requesting for XLS etc

    // **throwOnFailure:** Declares types of request failures that should prompt
    //  this method to throw an error when an API request returns a failing
    //  status code. If redirectOnAuthFailure=true AND the API returns a status
    //  indicating authentication failure, this parameter has no effect.
    throwOnFailure = "all", // 'all'|'auth'|'none'|'notauth'

    // **redirectOnAuthFailure:** If API responds with a status code indicating
    //  an authentication failure (401, 419), we log the user out by sending
    //  them to the logout page.
    redirectOnAuthFailure = true,

    // **timeout:** Timeout in milliseconds (or null).
    timeout = null,
  }: {
    method?: string,
    url?: string|null,
    body?: object|null,
    headers?: any,
    contentType?: string,
    throwOnFailure?: "all"|"auth"|"none"|"notauth",
    redirectOnAuthFailure?: boolean,
    timeout?: number|null,
  }) {
    const allowedThrowOnFailureValues = ["all", "auth", "none", "notauth"];
    if (!allowedThrowOnFailureValues.includes(throwOnFailure)) {
      console.error("Invalid value passed to `throwOnFailure` in makeRequest()");
    }

    let defaultHeaders = {
      Accept: "application/json",
      "Content-Type": contentType,
    };

    const baseURL = import.meta.env.VITE_API_URL;

    let finalHeaders = {
      ...defaultHeaders,
      ...headers,
    };

    let requestConfig = {
      url: baseURL + url,
      headers: finalHeaders,
      method,
      withCredentials: true,
      withXSRFToken: true, // added at axios 1.6.2 @see https://github.com/axios/axios/pull/6046
      baseURL,
    };

    if (method === "GET") {
      // @ts-ignore
      requestConfig.params = body;
    } else {
      // @ts-ignore
      requestConfig.data = body;
    }

    if (null !== timeout) {
      // @ts-ignore
      requestConfig.timeout = timeout;
    }

    return axios
      .request(requestConfig)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        const authFailCodes = [401, 419];

        let responseStatus = null;
        if (error && has(error, "response.status")) {
          responseStatus = error.response.status;
        }

        let wasAuthFail = authFailCodes.includes(responseStatus);

        // Handle response errors where we want to redirect to login.
        if (redirectOnAuthFailure && wasAuthFail) {
          // April 19, 2021
          // --------------
          // To address what we believe to be CSRF token errors stemming
          // from an initial release of cookie-based authentication in P2,
          // which appears to have left some users with a CSRF cookie that
          // the API (Sanctum) rejects and won't reset, we're going to:
          //
          // 1) Kill the CSRF token cookie
          // 2) Redirect the browser to the logout page
          //
          // The logout redirect will be done via window.location.replace()
          // so the app is bootstrapped again. That (as opposed to a push()
          // will trigger a new CSRF token request, which should give the
          // user a fresh start.
          removeCsrfToken();

          // Path for appDest on logout, if needed.
          let currentPath = location?.pathname;

          // Redirect after ensuring we're not already on the logout path.
          if ("/app/account/logout" !== currentPath) {
            window.location.replace(`/app/account/logout?appDest=${currentPath ? encodeURIComponent(currentPath) : ""}`);
          }
          return;
        }

        // Handle other errors as requested so other code can catch it.
        // Note: If redirectOnAuthFailure=true and the API returned an
        // auth-related failure, the user will have already been redirected.
        if ("none" !== throwOnFailure) {
          if ("all" === throwOnFailure) {
            // Calling code requested we throw an error on any API fail.
            throw error;
          } else if ("auth" === throwOnFailure && wasAuthFail) {
            // Calling code requested we throw an error only if API fail
            // was related to authentication.
            throw error;
          } else if ("notauth" === throwOnFailure && !wasAuthFail) {
            // Calling code requested we throw an error only if API fail
            // was NOT related to authentication.
            throw error;
          }
        }
        return;
      })
      .finally(() => ApiUtils.updateLatestRequestTime());
  }

  /**
   * Factory for our Pusher singleton instance.
   *
   * @see currentPusherInstance()
   * @see https://pub.dev/documentation/pusher_js/latest/pusher_js/Pusher-class.html
   *
   * @returns {Pusher}
   */
  static PusherFactory = (function () {
    let instance: Pusher;

    // Uncomment line below to include debugging output in your console.
    // Pusher.logToConsole = true;

    return {
      getInstance: function () {
        // Create new instance.
        if (!instance) {
          instance = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
            authEndpoint: import.meta.env.VITE_PUSHER_AUTH_URL,
            authTransport: "jsonp",
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
            forceTLS: true,
            enableStats: false,
          });

          // Log error if not in an automated test.
          if ("test" !== import.meta.env.MODE) {
            instance.connection.bind("error", function (err: Error) {
              console.error("Pusher instance error", err);
            });
          }
        }

        return instance;
      },
    };
  })();

  /**
   * Returns singleton Pusher instance.
   *
   * Code using our Pusher socket instance should typically just call
   * this to get the instance rather than storing a reference to the
   * instance locally because this code does some preprocessing to
   * ensure the auth values are up to date.
   *
   * @returns {Pusher}
   */
  static currentPusherInstance() {
    return ApiUtils.PusherFactory.getInstance();
  }

  /**
   * Get the current websocket connection ID, if any.
   *
   * @returns {string|null}
   */
  static currentWebsocketId() {
    try {
      let sock = ApiUtils.currentPusherInstance();
      if (sock && sock.connection && sock.connection.socket_id) {
        return sock.connection.socket_id;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  /**
   * Update the local storage value for time of most recent API request.
   */
  static updateLatestRequestTime() {
    try {
      let ls = window.localStorage;
      // @ts-ignore
      ls.setItem(prevLatestRequestLsKey, ls.getItem(latestRequestLsKey));
      ls.setItem(latestRequestLsKey, dayjs().toISOString());
    } catch (e) {
      console.warn("Setting localStorage failed in updateLatestRequestTime");
    }
  }
}

export const currentPusherInstance = ApiUtils.currentPusherInstance;
export const currentWebsocketId = ApiUtils.currentWebsocketId;
export const makeRequest = ApiUtils.makeRequest;
export const updateLatestRequestTime = ApiUtils.updateLatestRequestTime;
